import { IonBadge, IonButton } from '@ionic/react';
import { red } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React from 'react';
import settings from '../../config/settings';
import GlobalService from '../../services/GlobalService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    },
    card: {
        padding: '5px !important',
    }
  }),
);

export default function OrderViewCard({order, viewOrderForm, viewOrder, approveClick, cancelOrder, newDate}:any) {
  const classes = useStyles();
  
  var oldDate:any = new Date(1970, 0, 1);
  oldDate.setSeconds(order.date.seconds);
  var diff:any = Math.abs(newDate - oldDate) / 36e5;
  
  var items = 0;
  if(order.products && order.products.length>0){
    for(var product of order.products){
      if(product){
        items = items + (product.size && product.size.quantity ? (product.quantity * product.size.quantity) : product.quantity);
      }
    }
  }
  return (
    <Card>
      <IonButton routerLink={"/order?key="+order.key} color='danger' style={{ fontSize: '12px', position: 'absolute'}}>View Order</IonButton>
    <CardMedia
        component="img"
        height="80"
        image={order.products[0].thumbnail ? order.products[0].thumbnail : order.products[0].url ? order.products[0].url : settings.imageUploadPlaceholder}
      />
      <CardContent sx={{ p: 1, pb: 5+'px !important' }}>
        <Typography  variant="subtitle1" component="div">
          <IonBadge color={order.status === 'pending' ? 'light' : order.status === 'approved' ? 'secondary' : order.status === 'delivered' || order.status === 'shipped' ? 'success' : 'danger'} style={{marginRight: '5px', marginTop: '5px', fontSize: '12px'}}>Status: {order.status}</IonBadge>
          <IonBadge  color={order.type === 'Regular' ? "primary" : "warning"} style={{marginRight: '5px', marginTop: '5px', fontSize: '12px'}}>Type: {order.type}</IonBadge>
        </Typography>
        <Typography  variant="subtitle1" component="div">
          Voucher Number: {order.voucherNumber} | <a onClick={viewOrder} style={{marginRight: '5px', marginTop: '5px', color: 'var(--ion-color-danger)' }}>View Order</a>
        </Typography>
        <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle2" component="div">
            Date: {GlobalService.getIndianDateFormatFromDate(order.date)}
        </Typography>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle2" component="div">
            Total Order Value: {order.total} &#8377; / {items} Pcs.
        </Typography>
        <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-danger)' }} variant="subtitle2" component="div" color="danger">
            User Id: {order.user.userId}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {approveClick ? <IonBadge  onClick={approveClick} color={order.supplierStatus ? 'success' : 'danger'} style={{marginRight: '5px', marginTop: '5px', fontSize: '12px'}}>{order.supplierStatus ? order.supplierStatus : 'Mark as shipped'}</IonBadge> : ''}
            {diff && cancelOrder && diff<12 && order.type=='Regular' && order.status !== 'cancelled' ? <IonBadge  onClick={cancelOrder} color={order.supplierStatus ? 'success' : 'danger'} style={{marginRight: '5px', marginTop: '5px', fontSize: '12px'}}>Cancel Order</IonBadge> : ''}
            <IonBadge  onClick={viewOrderForm} color="primary" style={{marginRight: '5px', marginTop: '5px', fontSize: '12px'}}>View Order Form</IonBadge>
        </Typography>
      </CardContent>
    </Card>
  );
}