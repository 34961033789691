import { makeStyles, TextField } from '@material-ui/core'
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

function CustomAutocompleteInput({error, errorText, onchange, required, name, label, type, autoComplete, value, readonly, onKeyPress, width, onFocus, onBlur, focused, autofocused, optionList, inputValue, onInputChange}:any) {
    const classes = useStyles();
    if(value || value==''){
      return (
        <Autocomplete
        id={name}
        freeSolo
        options={optionList.map((option:any) => option.label)}
        inputValue = {inputValue}
        onChange={onchange ? onchange : undefined}
        onInputChange={onInputChange ? onInputChange : undefined}
        renderInput={(params) => (
          <TextField {...params} 
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          name={name}
          label={label}
          type={type}
          error={error}
          helperText={error ? errorText : ''}
          autoComplete={autoComplete}
          onKeyPress = {onKeyPress ? onKeyPress : undefined}
          className= {classes.root}
          onFocus ={onFocus? onFocus : undefined}
          onBlur = {onBlur? onBlur: undefined}
          autoFocus = {autofocused? autofocused : false}
          focused = {focused? focused : false}
          size="small"
          />
        )}
      />  
      )
    }else{
        return (
            <Autocomplete
            id={name}
            freeSolo
            inputValue = {inputValue}
            onChange={onchange ? onchange : undefined}
            onInputChange={onInputChange ? onInputChange : undefined}
            options={optionList.map((option:any) => option.label)}
            renderInput={(params) => (
              <TextField {...params} 
              variant="outlined"
              margin="normal"
              required={required}
              fullWidth
              name={name}
              label={label}
              type={type}
              error={error}
              helperText={error ? errorText : ''}
              autoComplete={autoComplete}
              onKeyPress = {onKeyPress ? onKeyPress : undefined}
              className= {classes.root}
              onFocus ={onFocus? onFocus : undefined}
              onBlur = {onBlur? onBlur: undefined}
              autoFocus = {autofocused? autofocused : false}
              focused = {focused? focused : false}
              size="small"
              />
            )}
          />  
        )
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: 'var(--ion-color-secondary)',
        },
        "& .MuiInputLabel-asterisk": {
            color: 'red',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: 'var(--ion-color-secondary)'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '2px',
            borderColor: 'var(--ion-color-secondary)',
        },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: '2px',
            borderColor: 'var(--ion-color-danger)',
        },
        "& .MuiOutlinedInput-input": {
          color: 'var(--ion-color-secondary)',
        },
        "&:hover .MuiOutlinedInput-input": {
          color: 'var(--ion-color-secondary)'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: 'var(--ion-color-secondary)',
          background: 'var(--ion-color-primary-white)'
        },
        "& .MuiInputLabel-outlined": {
          color: 'var(--ion-color-secondary)'
        },
        "&:hover .MuiInputLabel-outlined": {
          color: 'var(--ion-color-secondary)'
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
          color: 'var(--ion-color-secondary)'
        },
        "& .MuiFormLabel-root.Mui-error": {
          color: 'var(--ion-color-danger)'
        },
      }
}));

export default CustomAutocompleteInput;
