import { NavContext } from '@ionic/react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, CardMedia } from '@mui/material';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext, useState } from 'react';
import CustomTextInput from '../../components/common/CustomTextInput';
import CustomLoading from '../../components/loading/CustomLoading';
import settings from '../../config/settings';
import firebaseAuth from '../../hooks/firebaseAuth';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GlobalService from '../../services/GlobalService';
import validation from '../../services/ValidationPattern';

export default function LoginForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState<Boolean>();
  const [emailLogin, setEmailLogin] = useState<Boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [otpForm, setOtpForm] = useState<boolean>(false);
  const [final, setfinal] = useState<any>();
  let desktop = width > height && height > 500;
  let landscape = height > 500;
  const { navigate } = useContext(NavContext);
  let verify:any;

  const [logindata, setLoginData] = React.useState({
    email: '',
    emailError: 'Enter correct email address',
    numberError: 'Enter correct 10 digit phone number',
    password: '',
    passwordError: 'Min 8 characters and at least 1 number',
    check_textInputChange: false,
    isValidUser: true,
    isValidPassword: true, 
    isValidEmail: true, 
    isValidNumber: true,
    secureTextEntry: true,
    loginError: '',
    otpError: 'Enter correct otp',
    isValidOtp: true,
    otp: '',
    signUp: false,
    name: '',
    number: '',
    showPassword: false,
    signuplink: "/signup",
});

const checkNumber = (val:any) =>{
    var number = val.target.value;
    if(number){
      if(isNaN(number)){
        return;
      }
    }
    logindata.number = number;
    if(validation.checkNumber(number)){
        logindata.isValidNumber = true;
        setLoginData({
            ...logindata
        });
    }else{
        logindata.isValidNumber = false;
        setLoginData({ ...logindata});
    }
    
}

const checkEmail = (val:any) =>{
  var email = val.target.value;
  logindata.email = email;
  if(validation.checkEmail(email)){
      logindata.isValidEmail = true;
      setLoginData({
          ...logindata
      });
  }else{
      logindata.isValidEmail = false;
      setLoginData({ ...logindata});
  }
}

const checkPassword = (val:any) =>{
  var password = val.target.value;
  logindata.password = password;
  if(validation.checkPassword(password)){
      logindata.isValidPassword = true;
      setLoginData({
          ...logindata
      });
  }else{
      logindata.isValidPassword = false;
      setLoginData({ ...logindata});
  }
}

const checkOtp = (val:any) =>{
  var otp = val.target.value;
  var otp = val.target.value;
    if(otp){
      if(isNaN(otp)){
        return;
      }
    }
  logindata.otp = otp;
  if(validation.checkOtp(otp)){
      logindata.isValidOtp = true;
      setLoginData({
          ...logindata
      });
      setButtonDisabled(false);
  }else{
      logindata.isValidOtp = false;
      setButtonDisabled(true);
      setLoginData({ ...logindata});
  }
  
}

const submitForm = ()=>{
    if(logindata.isValidNumber && logindata.number!==''){
        // captcha
        setLoading(true);
        setButtonDisabled(true);
        let verify = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container", {
                size: "invisible"
            }
        );
        firebaseAuth.signinusingphone("+91"+logindata.number, verify).then((result) => {
          // code sent
          if(result){
            setfinal(result);
            
            setOtpForm(true);
            setButtonDisabled(true);
            setLoading(false);
          }else{
            setButtonDisabled(false);
            setLoading(false);
          }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setLoading(false);
        GlobalService.showtoast("error","Failed to login","error",undefined,undefined);
      });
    }else{
        GlobalService.showtoast('login', settings.loginError, "error",undefined,undefined);
    }
}

async function submitEmailForm(){
  if(logindata.isValidEmail && logindata.email!=='' && logindata.isValidPassword && logindata.password!==''){
      setLoading(true);
      setButtonDisabled(true);
      var result = await firebaseAuth.signinwithemailpassword(logindata);
      if(result){
        // navigate("/home","root","replace");
        GlobalService.showtoast("successLogin","Sign in successfully","success",undefined,undefined);
        GlobalService.viewWelcome("/assets/welcome2.png","Baba bhole ki jai","Welcome to PKT BAZAAR");
        setLoading(false);
        setButtonDisabled(false);
      }else{
        GlobalService.showtoast("loginerror","Failed to login","error",undefined,undefined);
        setLoading(false);
        setButtonDisabled(false);
      }
  }else{
      GlobalService.showtoast('login', settings.loginError, "error",undefined,undefined);
  }
}

const ValidateOtp = () => {
  setLoading(true);
  if (logindata.isValidOtp || logindata.otp !== '' || final !== null){
    final.confirm(logindata.otp).then((result:any) => {
        // success
        if(result){
          // navigate("/home","root","replace")
          GlobalService.showtoast("successOtp","Sign in successfully","success",undefined,undefined);
          GlobalService.viewWelcome("/assets/welcome2.png","Baba bhole ki jai","Welcome to PKT BAZAAR");
          setLoading(false);
          setButtonDisabled(false);
        }else{
          setLoading(false);
          setButtonDisabled(false);
          GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
        }
    }).catch((err:any) => {
        GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
        setLoading(false);
    })
  }else{
    setLoading(false);
    GlobalService.showtoast("errorotp","Login Failed","error",undefined,undefined);
  }
}

  return (
    <Container component="main" maxWidth="xs">
      {loading ? <CustomLoading /> : ''}
      <CssBaseline />
      <div className={classes.paper}>
      <Grid container justify="space-evenly" style={{marginTop: landscape ? '50px' : '15px'}} >
          <Grid container spacing={3}>
              <Grid item xl={4} lg={3} md={3} sm={2} xs={1}>
              </Grid>
              <Grid item xl={4} lg={6} md={6} sm={8} xs={10}>
              <Card sx={{ minWidth: 275 }}>
              <CardMedia
                  component="img"
                  height={desktop ? '200' : '140'}
                  image="/assets/login.jpeg"
                  alt="green iguana"
                />
              {!emailLogin ? <CardContent>
                {landscape ? <Typography component="h1" variant="h5" className={classes.loginHeading} >
                  LOGIN TO PKT BAZAAR
                </Typography> : ''}
                {otpForm ? <CustomTextInput error={!logindata.isValidOtp} 
                    errorText={logindata.otpError}
                    required={true} label="OTP" value={logindata.otp} name="otp" onchange={checkOtp} /> :
                  <CustomTextInput error={!logindata.isValidNumber} 
                    errorText={logindata.numberError} required={true}
                    label="Phone Number" name="number"  value={logindata.number}  autoComplete="number" 
                    onchange={checkNumber} /> }

                  {otpForm ? '' : <div id="recaptcha-container"></div>}
                  <a onClick={()=>{setEmailLogin(true)}} target="_blank">Login using email</a>  
                  <Button
                    fullWidth
                    variant="contained"
                    disabled = {buttonDisabled}
                    className={landscape ? classes.submit : classes.submit2}
                    onClick = {otpForm ? ValidateOtp : submitForm}
                  >
                  {otpForm ? 'Submit Otp' : 'SEND OTP'}
                  </Button>
                  {otpForm ? <Button
                    fullWidth
                    variant="contained"
                    className={landscape ? classes.submit : classes.submit2}
                    onClick = {()=>{
                      setOtpForm(false);
                      setButtonDisabled(false);
                      logindata.otp = '';
                      setLoginData({ ...logindata});
                    }}
                  >
                  Back
                  </Button> : ''}
                  <Typography component="h1" variant="subtitle1" className={classes.loginHeading} >
                    <a href={settings.websiteLink} target="_blank">Terms of use. Privacy policy</a>
                  </Typography>
                  </CardContent> : <CardContent>
                <CustomTextInput error={!logindata.isValidEmail} 
                    errorText={logindata.emailError}
                    required={true} label="Email" value={logindata.email} name="email" onchange={checkEmail} /> 
                  <CustomTextInput error={!logindata.isValidPassword} 
                    errorText={logindata.passwordError} required={true} 
                    label="Password" name="password"  value={logindata.password} type="password"
                    onchange={checkPassword} /> 
                  <a onClick={()=>{setEmailLogin(false)}} target="_blank">Login using number</a>   
                  <Button
                    fullWidth
                    variant="contained"
                    disabled = {buttonDisabled}
                    className={landscape ? classes.submit : classes.submit2}
                    onClick = {submitEmailForm}
                  >
                    Submit
                  </Button>
                  <Typography component="h1" variant="subtitle1" className={classes.loginHeading} >
                    <a href={settings.websiteLink} target="_blank">Terms of use. Privacy policy</a>
                  </Typography>
                  </CardContent>}
                  </Card>
              </Grid>  
              <Grid item xl={4} lg={3} md={3} sm={2} xs={1}>
              </Grid>
          </Grid>   
      </Grid>   
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-color-secondary)',
        textAlign: 'center',
        marginBottom: '15px',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: theme.spacing(1, 0, 1),
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));