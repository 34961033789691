import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonButton, IonPage, NavContext } from '@ionic/react';
import { useContext, useState } from 'react';
import SideMenu3 from '../../components/menu/SideMenu3';
import swal from 'sweetalert';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import { useProductInit } from './ProductData';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ProductCard from './ProductCard';
import CustomFab from '../../components/fab/CustomFab';
import { Product } from './Product';
import ProductService from './ProductService';
import SideMenuUser from '../../components/menu/SideMenuUser';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { useBrandInit } from '../brand/BrandData';
import CartProductCardMob from '../store/CartProductCardMob';
import CustomButton from '../../components/common/CustomButton';
import { useFirestoreConnect, WhereOptions } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const ProductListPage: React.FC = () => {
  const classes = useStyles();
  const { auth } = UseAuthInit();
  const [files, setFiles] = useState<File[]>();
  const id = auth?.userId;
  const [productList, setProductList] = useState<Product[]>();
  let billkey = new URLSearchParams(window.location.search).get("billKey");
  const { size, orientation, width, height } = UseDeviceScreenSize();
  
  useFirestoreConnect(
    [
      {
        collection: 'product',
        doc: auth?.user?.id ? auth?.user?.id : '00000',
        storeAs: "userproducts",
      },
      {
        collection: 'brand',
        storeAs: "brands",
      },
    ]
  );

  const products = useSelector((state:any) => 
    state.firestore.data.userproducts && state.firestore.data.userproducts.products && state.firestore.data.userproducts.products.length > 0 ? state.firestore.data.userproducts.products : []
  );

  const brandData = useSelector((state:any) => 
        state.firestore.data.brands && state.firestore.data.brands.brand && state.firestore.data.brands.brand.brand ? state.firestore.data.brands.brand.brand : []
  );
  
  const removeClick = (item:Product) => {
    swal({
      title: item.name,
      text: "Are you sure that you want to delete this product ?",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (willDelete) => {
      if (willDelete && products) {
        
        var newProducts = products.filter(function( obj ) {
            return obj.key !== item.key;
        });
        
        if(item.user && item.user.id){
          let rs = await ProductService.updateProductData(newProducts, item.user.id);
          
          if(rs){
            swal("Deleted!", "Product has been deleted!", "success");
          }else{
            swal("Failed!", "Something is not correct!", "failed");
          }
        }
        
      }
    });
  }

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = products?.filter((product) => {
      return product.name?.toLowerCase().includes(lowercasedInput) || product.code?.toLowerCase().includes(lowercasedInput) || product.description?.toLowerCase().includes(lowercasedInput) || product.category1?.toLowerCase().includes(lowercasedInput) || product.category2?.toLowerCase().includes(lowercasedInput) || product.category3?.toLowerCase().includes(lowercasedInput) || product.user?.name?.toLowerCase().includes(lowercasedInput) || product.user?.firmName?.toLowerCase().includes(lowercasedInput);
    });
    setProductList(results);
  }

  return (
    <IonPage>
         <SideMenuUser title="Product" backButton={auth?.user?.role === 'Customer' ? true : false} /> 
         {brandData && Object.values(brandData).length>0 ? <div style={{marginTop: orientation === 'portrait' ? '60px' : '70px',  paddingBottom: '30px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
            { orientation === 'portrait' ? 
            <Grid container>
                {
                  productList ? productList.map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={6} >
                        <CartProductCardMob brands={Object.values(brandData)} key={item.key} type="store" product={item} removeItem={()=>{removeClick(item)}} ></CartProductCardMob>
                      </Grid>
                    )
                  }) : products && Object.values<Product>(products).length >0 ? Object.values<Product>(products).map((item, index)=>{
                    return (
                      <Grid item key={item.key} lg={3} sm={6} xs={6} >
                        <CartProductCardMob brands={Object.values(brandData)} key={item.key} type="store" product={item} removeItem={()=>{removeClick(item)}} ></CartProductCardMob>
                      </Grid>
                    )
                  }) :<IonBadge className={classes.notFound}>No records found</IonBadge>
                }
            </Grid> :
            <Grid container>
              {
                productList ? productList.map((item, index)=>{
                  return (
                    <Grid item key={item.key} lg={3} sm={6} xs={12} >
                      <ProductCard brands={Object.values(brandData)} key={item.key} product={item} removeClick={()=>{removeClick(item)}} ></ProductCard>
                    </Grid>
                  )
                }) : products && Object.values<Product>(products).length >0 ? Object.values<Product>(products).map((item, index)=>{
                  return (
                    <Grid item key={item.key} lg={3} sm={6} xs={12} >
                      <ProductCard brands={Object.values(brandData)} key={item.key} product={item} removeClick={()=>{removeClick(item)}} ></ProductCard>
                    </Grid>
                  )
                }) :<IonBadge className={classes.notFound}>No records found</IonBadge>
              }
          </Grid>
          
            }
            <IonButton routerLink='/addProduct' style={{position: 'absolute', bottom: '0px', width: '100%'}} >Add New Product</IonButton>
          </div> : ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  notFound: {
    margin: '20px',
  },
}));

export default ProductListPage;