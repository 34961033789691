import React, { useContext, useEffect, useState } from "react";
import firebase from 'firebase/app';import 'firebase/auth';
import { db } from "../../App";
import { User } from "../../pages/user/User";

interface Auth {
  loggedIn: Boolean;
  status?: string;
  role?: string;
  userId?: string;
  userData?:any;
  user?:User | undefined;
}
interface AuthInit {
  loading: boolean;
  auth?: Auth;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
  return useContext(AuthContext);
}

export function UseAuthInit(): AuthInit {
  const [authInit, setAuthInit] = useState<AuthInit>({ loading: true });
  useEffect(() => {
    const res = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
      if(firebaseUser){
        try{
          db.collection("users").doc(firebaseUser.uid)
          .onSnapshot((doc) => { 
           
              var data = doc.data();
              if(data && data.user){
                let user: User = {
                  role: data.user.role,
                  status: 'inactive',
                  name: data.user.name ? data.user.name : "",
                  email: data.user.email ? data.user.email : "",
                  number: data.user.number ? data.user.number : "",
                  gst: data.user.gst,
                  firmName: data.user.firmName ? data.user.firmName : "",
                  id: firebaseUser.uid,
                  categories: data.user.categories,
                  address: data.user.address,
                  referralCode: data.user.referralCode,
                  userId: data.user.userId,
                }
                db.collection("users").doc(firebaseUser.uid).collection("organization").doc("private")
                .onSnapshot((doc2) => {
                  const data2 = doc2.data();
                  if(data2 && data2.data){
                    user.status = data2.data.status;
                    user.role = data2.data.role;
                  }
                  const auth = firebaseUser
                  ? { loggedIn: true, status: user.status, role: user.role, userId: firebaseUser.uid, userData: firebaseUser, user: user }
                  : { loggedIn: false };
                setAuthInit({ loading: false, auth });
                });
              }else{
                const auth = firebaseUser
                    ? { loggedIn: true, userId: firebaseUser.uid, userData: firebaseUser, status: 'inactive' }
                    : { loggedIn: false };
                  setAuthInit({ loading: false, auth });
              }
          },(error) => {
            const auth = firebaseUser
              ? { loggedIn: true, userId: firebaseUser.uid, userData: firebaseUser, status: 'inactive' }
              : { loggedIn: false, status: 'inactive' };
            setAuthInit({ loading: false, auth });
            })
        }catch(err){
        
        }
      }else{
        const auth = { loggedIn: false, status: 'inactive' };
                setAuthInit({ loading: false, auth });
      }
    });
    return res;
  }, []);
  return authInit;
}