import { Grid } from '@material-ui/core';
import CategoryIcon from '@mui/icons-material/Category';

export default function BrandComponent({brand, logoSize, titleSize, subTitleSize, size1, size2, size1m, size2m, size1sm, size2sm, height, show}:any) {
    if(show){
        if(brand.url){
            return (
                <Grid container justify="space-evenly" style={{padding: '5px', height: height ? height : '40px', cursor: 'context-menu'}} >
                    <Grid container spacing={3}>  
                        <Grid item xs={size1sm ? size1sm : size1 ? size1 : 6} sm={size1sm ? size1sm : size1 ? size1 : 6} md={size1m ? size1m : size1 ? size1 : 6} lg={size1 ? size1 : 6}>
                            {brand.url ? <img src={brand.url} alt={brand.name ? brand.name : 'Image'} style={{height: logoSize ? logoSize : '30px'}}>
        
                            </img> : <CategoryIcon style={{color: 'var(--ion-color-secondary)'}} />}
                        </Grid>
                        <Grid item xs={size2sm ? size2sm : size2 ? size2 : 6} sm={size2sm ? size2sm : size2 ? size2 : 6} md={size2m ? size2m : size2 ? size2 : 6} lg={size2 ? size2 : 6}>
                            <span key={brand.name} style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: titleSize ? titleSize : '14px'}}><b>{brand.name}</b></span><br style={{content: '""', margin: '.8em', display: 'block', marginBottom: '-20px'}}></br>
                            {brand.sub ? <span key={brand.sub} style={{marginLeft: '5px', color: 'var(--ion-color-primary)', fontSize: subTitleSize ? subTitleSize : '12px'}}>{brand.sub}</span> : ''}
                        </Grid>
                    </Grid>
                </Grid>
            );
        }else{
            return (
                <Grid container justify="space-evenly" style={{padding: '5px', height: height ? height : '40px', cursor: 'context-menu'}} >
                    <Grid container spacing={3}>  
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <span key={brand.name} style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: titleSize ? titleSize : '14px'}}><b>{brand.name}</b></span><br style={{content: '""', margin: '.4em', display: 'block'}}></br>
                            {brand.sub ? <span key={brand.sub} style={{marginLeft: '5px', color: 'var(--ion-color-primary)', fontSize: subTitleSize ? subTitleSize : '12px'}}>{brand.sub}</span> : ''}
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }else{
        return (
            <Grid container justify="space-evenly" style={{padding: '5px', height: height ? height : '40px', cursor: 'context-menu'}} >
                <Grid container spacing={3}>  
                    <Grid item xs={size1sm ? size1sm : size1 ? size1 : 6} sm={size1sm ? size1sm : size1 ? size1 : 6} md={size1m ? size1m : size1 ? size1 : 6} lg={size1 ? size1 : 6}>
                        {brand.url ? <img src={brand.url} alt={brand.name ? brand.name : 'Image'} style={{height: logoSize ? logoSize : '30px'}}>
    
                        </img> : <CategoryIcon style={{color: 'var(--ion-color-secondary)'}} />}
                    </Grid>
                    <Grid item xs={size2sm ? size2sm : size2 ? size2 : 6} sm={size2sm ? size2sm : size2 ? size2 : 6} md={size2m ? size2m : size2 ? size2 : 6} lg={size2 ? size2 : 6}>
                        <span key={brand.name} style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: titleSize ? titleSize : '14px'}}><b>{brand.name}</b></span><br style={{content: '""', margin: '.4em', display: 'block'}}></br>
                        {brand.sub ? <span key={brand.sub} style={{marginLeft: '5px', color: 'var(--ion-color-primary)', fontSize: subTitleSize ? subTitleSize : '12px'}}>{brand.sub}</span> : ''}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
  }