import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { animated, useSpring } from "react-spring";
import BrandComponent from "../../components/common/BrandComponent";
import AnimationService from "../../services/AnimationService";



const BrandCard = ({item, duration}:any) =>{
    const headerStyle = useSpring(
        {
            from: { x: -500 },
            to: { x: 0 },
            delay: (500+duration),
        }
    )
    return (
        <animated.div style={{...headerStyle, width: '98%'}} >
        <Card style={{width: '100%', margin: '5px'}}>
            <CardActionArea>
            <CardContent>
            <BrandComponent titleSize="24px" subTitleSize="18px" logoSize="50px" height="50px" show={false} size1={2} size2={10} size1m={3} size2m={9} size1sm={4} size2sm={8} brand={item} />
            </CardContent>
            </CardActionArea>
        </Card>
        </animated.div>
    )
}

export default BrandCard;
