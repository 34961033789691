import { Capacitor } from "@capacitor/core";
import UserService from "../pages/user/UserService";
import { PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { UserToken } from "../pages/user/UserToken";
import GlobalService from "./GlobalService";
import NotificationService from "./NotificationService";
import { db, firebase } from "../App";

function GetRegisterToken(){
    
} 

export async function register(uid:string, role: string) {
    if(Capacitor.isNative){
        
        PushNotifications.register();

        PushNotifications.createChannel({
            description: 'General Notifications',
            id: 'testchannel1',
            importance: 5,
            lights: true,
            name: 'user notification channel',
            sound: 'sound.mp3',
            vibration: true,
            visibility: 1
        }).then(()=>{
            
        }).catch(error =>{
            console.error('push channel error: ', error);
        });

        PushNotifications.createChannel({
            description: 'Order Notifications',
            id: 'testchannel2',
            importance: 5,
            lights: true,
            name: 'vendor notification channel',
            sound: 'alarm.mp3',
            vibration: true,
            visibility: 1,
        }).then(()=>{
            
        }).catch(error =>{
            console.error('push channel error: ', error);
        });
                PushNotifications.checkPermissions().then((res) => {
                    if (res.receive !== 'granted') {
                    PushNotifications.requestPermissions().then((res) => {
                        if (res.receive === 'denied') {
                        // showToast('Push Notification permission denied');
                        }
                        else {
                    //   showToast('Push Notification permission granted');
                            // On success, we should be able to receive notifications
                        PushNotifications.addListener('registration',
                        async (tok: Token) => {
                            db.collection("tokens").doc(uid).get().then(async (doc:any) =>{
                                var token:any;
                                if(doc.data() && doc.data().token){
                                    token = doc.data().token;
                                    token.token = tok.value;
                                    token.date = await GlobalService.getLiveDate();
                                    await db.collection("tokens").doc(uid).set({
                                        token
                                    })
                                    NotificationService.sendNotificationForSubscribe();
                                }else{
                                    var userToken:UserToken = {token: tok.value, uid: uid, date: await GlobalService.getLiveDate(), role: role}
                                    await UserService.addUserToken(userToken);
                                    NotificationService.sendNotificationForSubscribe();
                                }
                            });
                        }
                    )
                    // Some issue with our setup and push will not work
                    PushNotifications.addListener('registrationError',
                        (error: any) => {
                            alert('Error on registration: ' + JSON.stringify(error));
                        }
                    );

                    // Show us the notification payload if the app is open on our device
                    // PushNotifications.addListener('pushNotificationReceived',
                    //     async (notification: PushNotificationSchema) => {
                    //         console.log('Notification received!', notification);
                    //         var lastNotifications:any = await LocalStorageService.getStorage("unotifications");
                    //         console.log(lastNotifications);
                    //         var noti:any = notification;
                    //         noti.date = GlobalService.getIndianDateFormatFromDateObject(new Date());
                    //         lastNotifications.push(noti);
                    //         console.log(lastNotifications);
                    //         await LocalStorageService.setStorage({key: "unotifications", value: JSON.stringify(lastNotifications)})
                    //         GlobalService.shownotification(notification.id,notification.title ? notification.title :'New Notification',"info",undefined,undefined,()=>{console.log("navigate")});
                    //         // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
                    //     }
                    // );

                    // Method called when tapping on a notification
                    PushNotifications.addListener('pushNotificationActionPerformed',
                        (notification: ActionPerformed) => {
                            console.log(notification.notification.data.id)
                            if(notification.notification && notification.notification.data && notification.notification.data.id){
                                window.location = notification.notification.data.id;
                            }
                            // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
                        }
                    );
                }
              });
            }
            else {
                // On success, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    async (tok: any) => {
                        db.collection("tokens").doc(uid).get().then(async (doc:any) =>{
                            var token:any;
                            if(doc.data() && doc.data().token){
                                token = doc.data().token;
                                token.token = tok.value;
                                token.date = await GlobalService.getLiveDate();
                                await db.collection("tokens").doc(uid).set({
                                    token
                                })
                                NotificationService.sendNotificationForSubscribe();
                            }else{
                                var userToken:UserToken = {token: tok.value, uid: uid, date: await GlobalService.getLiveDate(), role: role}
                                await UserService.addUserToken(userToken);
                                NotificationService.sendNotificationForSubscribe();
                            }
                        });
                    }
                ).then((res)=>{
                    console.log(res)
                });

                // Some issue with our setup and push will not work
                PushNotifications.addListener('registrationError',
                    (error: any) => {
                        alert('Error on registration: ' + JSON.stringify(error));
                    }
                );

                // Show us the notification payload if the app is open on our device
                // PushNotifications.addListener('pushNotificationReceived',
                //     async (notification: PushNotificationSchema) => {
                //         console.log('Notification received!', notification);
                //             var lastNotifications:any = await LocalStorageService.getStorage("unotifications");
                //             console.log(lastNotifications);
                //             var noti:any = notification;
                //             noti.date = GlobalService.getIndianDateFormatFromDateObject(new Date());
                //             lastNotifications.push(noti);
                //             console.log(lastNotifications);
                //             await LocalStorageService.setStorage({key: "unotifications", value: JSON.stringify(lastNotifications)})
                //             GlobalService.shownotification(notification.id,notification.title ? notification.title :'New Notification',"info",undefined,undefined,()=>{console.log("navigate")});
                //         // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
                //     }
                // );

                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    (notification: ActionPerformed) => {
                        console.log("here");
                        console.log(notification.notification.data.id)
                        if(notification.notification && notification.notification.data && notification.notification.data.id){
                            window.location = notification.notification.data.id;
                        }
                        // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
                    }
                );
            }
          });
    }else{
        firebase.messaging().requestPermission().then(()=>{
            return firebase.messaging().getToken();
          }).then(async (desktopToken)=>{
            var token:any;
            db.collection("tokens").doc(uid).get().then(async (doc:any) =>{
                if(doc.data() && doc.data().token){
                    token = doc.data().token;
                    token.desktop = desktopToken;
                    token.date = await GlobalService.getLiveDate();
                    await db.collection("tokens").doc(uid).set({
                        token
                    })
                    NotificationService.sendNotificationForSubscribe();
                }else{
                    var userToken:UserToken = {desktop: desktopToken, uid: uid, date: await GlobalService.getLiveDate(), role: role}
                    await UserService.addUserToken(userToken);
                    NotificationService.sendNotificationForSubscribe();
                }
            })
            
          }).catch(err => console.log(err))
    }
}

export default { 
    GetRegisterToken,
    register,
}