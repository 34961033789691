import { IonBadge, NavContext } from '@ionic/react';
import { Badge, Button, Grid, ListItemText, Popover } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import HomeIcon from '@mui/icons-material/Home';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { ListItemButton } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, WhereOptions } from 'react-redux-firebase';
import { useSpring } from 'react-spring';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { Product } from '../../pages/product/Product';
import ProductService from '../../pages/product/ProductService';
import { useUserCartInit } from '../../pages/user/UserCartData';
import AnimationService from '../../services/AnimationService';
import GlobalService from '../../services/GlobalService';
import BrandComponent from '../common/BrandComponent';
import CustomAutocompleteInput from '../common/CustomAutoComplete';
import LogoutButton from '../common/LogoutButton';
import NoficitationComponent from './NotificationComponent';
import './SideMenu3.css';
import SideMenuItem from './SideMenuItem';

const drawerWidth = 240;

export interface SelectOption {
  label: string,
  value: Product | undefined,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    sidebarText: {
      // borderBottom: '2px solid white',
      padding: '5px',
      margin: '5px',
    },
    topName:{
      fontSize: '18px',
      marginLeft: '5px',
    },
    appBar: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    categoryName : {
        marginLeft: '20px',
        marginRight: '20px',
        fontSize: '14px',
        cursor: 'context-menu',
    },
    categoryIcon: {
        marginLeft: '15px',
        marginRight: '15px',
    },
    categoryIconMob: {
      marginLeft: '5px',
  },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: 'var(--ion-color-primary)',  
      color: 'var(--ion-color-primary-contrast)',   
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 0,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    cartBadge: {
      position: 'absolute',
      right: '40px',
    },
    cartBadgeMob: {
      position: 'absolute',
      right: '20px',
    },
    mobileIcons:{
      color: 'var(--ion-color-text)'
    },
  }),
);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function SideMenuUser({title, placeholder, backButton}:any) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { size, orientation, width, height } = UseDeviceScreenSize();
  const [searchShow, setSearchShow] = React.useState<boolean>(false);
  const { cart } = useUserCartInit();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorE2, setAnchorE2] = React.useState<HTMLButtonElement | null>(null);
  const openStore = Boolean(anchorEl);
  const openUser = Boolean(anchorE2);
  const storeId = openStore ? 'store-popover' : undefined;
  const userId = openUser ? 'user-popover' : undefined;
  const [ products, setProducts ] = useState<Product[]>();
  const [productList, setProductList] = React.useState<SelectOption[]>();

  const wp:WhereOptions = ["status","==","active"];

  useFirestoreConnect(
    [
      {
        collection: 'product',
        storeAs: "usersproducts",
        where: wp,
      },
      {
          collection: 'category',
          storeAs: "categories",
      },
      {
          collection: 'brand',
          storeAs: "brands",
      }
    ]
  );

  const usersData = useSelector((state:any) => 
    state.firestore.data.usersproducts
  );
  
  const brandData = useSelector((state:any) => 
    state.firestore.data.brands && state.firestore.data.brands.brand && state.firestore.data.brands.brand.brand ? state.firestore.data.brands.brand.brand : []
  );

  const categoryData = useSelector((state:any) => 
    state.firestore.data.categories && state.firestore.data.categories.category && state.firestore.data.categories.category.category ? state.firestore.data.categories.category.category : []
  );

  
  if ((!productList || productList.length < 1) && usersData && Object.values(usersData).length>0) {
    var options:any = [];
    var list:Product[] = [];
    for(var obj of Object.values(usersData)){
      var ps:any = obj;
      if(ps && ps.status && ps.status === 'active' && ps.products && ps.products.length>0){
        for(var product of ps.products){
          list.push(product);
        }
      }
    }
    if(list.length>0){
      setProducts(list);
      list.map((item, index) => {
        var label = item.name+' | '+item.brand;
        // label = item.contact ? label + " | " + item.contact : label;
        // label = item.email ? label + " | " + item.email : label;
        var value = item;
        var option = { value, label };
        if (item.status) {
          options?.push(option);
        }
      });
      if (options && options?.length > 0) {
        setProductList(options)
      }
    }
  }

  const [searchBarData, setSearchBarData] = React.useState<string>();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  function handleChange(panel: string){
      setExpanded(panel && panel!=expanded ? panel : false);
    };

  const [subExpanded, setSubExpanded] = React.useState<string | false>(false);

  function subHandleChange(panel: string){
      setSubExpanded(panel && panel!=subExpanded ? panel : false);
    };  

  const [mainExpanded, setMainExpanded] = React.useState<string | false>(false);

  function mainHandleChange(panel: string){
      setMainExpanded(panel && panel!=mainExpanded ? panel : false);
    };     

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const defaultOptions:SelectOption[] = [{value: undefined, label: 'Search here..'}];
  const promiseOptions = async (inputValue: string) => {
    var prods = await ProductService.searchProduct(inputValue, 0, 0);
    var list:SelectOption[] = [];
    for(var p of prods){
      list.push({label: p.name +' | '+p.brand , value: p});
    }
    return list;
  };

  const tagItems = categoryData && Object.values(categoryData)?.map((item, index) =>{
    return (
        <SideMenuItem 
          handleChange = {handleChange}
          expended = {expanded}
          id = {'panel'+index}
          key = {'cat'+index}
          data = {item}
        />
    );   
  });

  const { navigate } = useContext(NavContext);

  const { loading, auth } = UseAuthInit();
  
  const CategoryTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 820,
       width: 420,
       maxHeight: 600,
       overflow: 'scroll',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const BrandTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 820,
       width: 260,
       maxHeight: 600,
       overflow: 'scroll',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const ProfileTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 820,
       width: 120,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const ShopTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 820,
       width: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleClickStore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseStore = () => {
    setAnchorEl(null);
  };

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorE2(null);
  };

  const getSearchBarData = (e: any, value: any) => {
    if(value){
      setSearchBarData(value);
      var list = value.split("|");
      if(list && list[1] && list.length>0){
        navigate("/store?brand="+list[1].trim(),"forward","push");
      }
    }
  }

  const setSearchBarDataInput = (event:any) => {
    if (event && event.target) {
      const { name, value } = event.target;
      setSearchBarData(value);
    }
  }

  const searchBarNavigate = (event:any) => {
    if(event && event.key && event.key === 'Enter'){
      var tag:any = document.getElementById("searchBarInput");
      if(tag && tag.value){
        navigate("/store?search="+tag.value,"forward","push");
      }
    }
  }
  
const headerStyle = useSpring(
  AnimationService.getHeaderAnimation()
)

  return (
    // <animated.div style={{...headerStyle}} >
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        
        color="primary"
      >
        { size=='lg' || size=='xl' ? <Toolbar>
          <div style={{width: '100px'}}>
              <a onClick={()=>{navigate("/store","forward","push")}}><img src={settings.logo} height="50" style={{}} /></a>
          </div>  
          <div style={{width: '100px', flexGrow: 6}} >
                {categoryData && Object.values(categoryData).length>0 ?
                    Object.values(categoryData)?.map((item:any, index1:number) =>{
                    if(item && item.subCategories && item.subCategories.length>0){
                        return (
                            <CategoryTooltip
                              key={'tool'+index1}
                                title={
                                <React.Fragment>
                                   <div  key={item.name}>
                                   <Grid container >
                                       {item.subCategories?.map((subcat, index2) =>{
                                          return (
                                            <Grid item key={subcat.name} xl={6} lg={6} sm={6}  style={{ cursor: 'context-menu'}}>
                                            <h6><b><a  style={{color: 'var(--ion-color-primary-shade)', fontSize: '13px'}} onClick={()=>{
                                                          var subCategory = encodeURI(subcat.name).replace("&", "11nd");
                                                          navigate("/store?subCategory="+subCategory,"forward","push");}}>{subcat.name}</a></b></h6>
                                                {subcat.sub2Categories?.map((sub2cat, index3) =>{
                                                    return (
                                                        <p  key={sub2cat.name} onClick={()=>{
                                                          var mainCategory = encodeURI(sub2cat.name).replace("&", "11nd");
                                                          navigate("/store?mainCategory="+mainCategory,"forward","push");}} style={{marginLeft: '5px', cursor: 'context-menu', fontSize: '12px'}}>{sub2cat.name}</p>
                                                    )
                                                    })
                                                }
                                            </Grid>
                                          )
                                        })
                                       }
                                    </Grid>
                                   </div>
                                </React.Fragment>
                                }
                            >
                               <a key={item.name} style={{color: 'var(--ion-color-white)',}} className={classes.categoryName} onClick={()=>{
                                                          var subCategory = encodeURI(item.name).replace("&", "11nd");
                                                          navigate("/store?parentCategory="+subCategory,"forward","push");}}>
                                    {item.name}
                                </a>
                            </CategoryTooltip>
                        );     
                    }else{
                        return (
                            <a  key={item.name} className={classes.categoryName} onClick={()=>{
                              var subCategory = encodeURI(item.name).replace("&", "11nd");
                              navigate("/store?parentCategory="+subCategory,"forward","push");}}>
                                    {item.name}
                                </a>
                        )
                    }
                }) : ''            
                }
                <BrandTooltip
                  key="brandtop"
                    title={
                    <React.Fragment>
                        <Grid container >
                              <Grid item xl={12} lg={12} sm={12}  style={{ cursor: 'context-menu'}}>
                                { brandData ? Object.values(brandData).map((brand:any, index3:number) =>{
                                      return (
                                          <div key={"brandsList"+index3} onClick={()=>{
                                            var mainbrand = encodeURI(brand.name).replace("&", "11nd");
                                            navigate("/store?brand="+mainbrand,"forward","push");}} >
                                            <BrandComponent brand={brand} size1={3} size2={9}/>
                                          </div>
                                      )
                                      })
                                 : '' }
                              </Grid>
                            
                        </Grid>
                    </React.Fragment>
                    }
                >
                    <span key="brand" className={classes.categoryName}>
                        Brands
                    </span>
                </BrandTooltip> 
            </div>
           <div style={{flexGrow: 2}} >
              {products && products.length>0 ? <CustomAutocompleteInput id="search-bar-dropdown" onKeyPress={searchBarNavigate} onInputChange={setSearchBarDataInput} onchange={getSearchBarData} inputValue={searchBarData ? searchBarData : ''} optionList={productList ? productList : []} name="searchBarInput" label="Search" /> : ''}
            </div>
            <div style={{flexGrow: 2, display: 'flex', justifyContent: 'flex-end'}} >
            <NoficitationComponent />
                <span onClick={()=>{navigate("/home","back","pop");}} ><HomeIcon  fontSize="large" className={classes.categoryIcon} /></span>    
                <ProfileTooltip 
                    title={
                    <React.Fragment>
                        <Grid container style={{ cursor: 'context-menu'}} >
                            <Grid item key="profile" xl={12} lg={12} sm={12} style={{borderBottom: '1px solid gray'}} >
                             <h6><a onClick={()=>{
                                navigate("/profile","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Profile</a></h6>
                              {auth?.user?.role === 'Customer' ? <h6><a onClick={()=>{
                                navigate("/orders","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Orders</a></h6> : 
                              <div>
                                 <h6><a onClick={()=>{
                                navigate("/product","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Products</a></h6>
                                <h6><a onClick={()=>{
                                navigate("/orderSupplier","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Orders</a></h6>
                             
                              </div>
                              }
                              <h6><a target="_blank" href="https://pktbazaar.com/#contact" style={{color: 'var(--ion-color-secondary)'}}>Help</a></h6>
                            </Grid>
                            <Grid item key="product" xl={12} lg={12} sm={12} >
                              <LogoutButton />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    }
                >
                    <PersonIcon fontSize="large" className={classes.categoryIcon} />
                </ProfileTooltip>
                {auth?.user?.role === 'Customer' ? 
                <ShopTooltip
                    title={
                    <React.Fragment>
                        <Grid container style={{ cursor: 'context-menu', maxHeight: '400px'}} >
                            <Grid item key="st2" xl={12} lg={12} sm={12} style={{borderBottom: '1px solid gray', maxHeight: '200px', overflowY: 'scroll'}} >
                              {
                                cart && cart.products && cart.products.length > 0 ? cart.products.map((product, index) => {
                                  return (
                                    <Grid key={'mp'+index} container style={{ cursor: 'context-menu'}} >
                                      <Grid item key={'k2'+index} xl={9} lg={10} sm={8} >
                                        <h6>{product.name} {product.size ? ' | '+product.size.name : ''} {product.color ? ' | '+product.color.name : ''} {product.pattern ? ' | '+product.pattern : ''}</h6>
                                      </Grid>
                                      <Grid item key={'k3'+index} xl={3} lg={2} sm={4} >
                                        <h6>{product.quantity}</h6>
                                      </Grid>
                                    </Grid>
                                  );
                                }) : 'Empty Cart'
                              }
                            </Grid>
                            {cart && cart.products && cart.products.length > 0 ? <Grid item key="vc" xl={12} lg={12} sm={12} >
                              <Button onClick={()=>{
                                 navigate("/cart","forward","push");
                              }} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px', width: '100%'}} >View Cart</Button>
                            </Grid> : ''}
                            <Grid item key={'sp'} xl={12} lg={12} sm={12} >
                              <Button onClick={()=>{
                                 navigate("/store","forward","push");
                              }} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px', width: '100%'}} >Shop Now</Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    }
                >
                   <span> 
                    {auth?.user?.role === 'Customer' ? <Badge badgeContent={cart?.products ? cart.products.length : 0} color="secondary"><LocalMallIcon fontSize="large" style={{color: 'var(--ion-color-white)'}}/></Badge> : ''}</span>
                </ShopTooltip> : ''}
            </div>   
        </Toolbar> : 
        <Toolbar>
           {!backButton ? <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
                [classes.hide]: open,
            })}
            >
            <div><MenuIcon /> <img src={settings.logo} width="50"></img></div> 
            </IconButton> : 
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={()=>{GlobalService.backNavigation()}}
              edge="start"
              className={clsx(classes.menuButton, {
                  [classes.hide]: open,
              })}
              >
              <div><ArrowBackIcon /> <img src={settings.logo} width="40"></img></div> 
            </IconButton>
            }
            {/* {Capacitor.isNative ? <IonFab onClick={()=>{ window.history.back()}} vertical="bottom" edge horizontal="start" slot="fixed" style={{position: 'fixed', bottom: '5px'}}>
              <IonFabButton>
                <IonIcon icon={arrowBackOutline} />
              </IonFabButton>
            </IonFab> : ''} */}

            {searchShow ?
               <Grid container >
                  <Grid item lg={8} md={9} sm={8} xs={8}>
                  {products && products.length>0 ? <CustomAutocompleteInput id="search-bar-dropdown" onKeyPress={searchBarNavigate} onInputChange={setSearchBarDataInput} onchange={getSearchBarData} inputValue={searchBarData ? searchBarData : ''} optionList={productList ? productList : []} name="searchBarInput" label="Search" /> : ''}
                  </Grid>
                  <Grid item key="4" lg={4} md={3} sm={4} xs={4} style={{textAlign: 'right',}} >
                    <ArrowBackIosNewIcon fontSize="large" className={classes.categoryIcon} onClick={()=>{setSearchShow(false)}} />
                </Grid>  
              </Grid>
            : 
            open ? '' : <Grid container >
              <Grid item key="4" lg={12} md={12} sm={12} xs={12} style={{textAlign: 'right',}} >
                  <Button className={classes.mobileIcons}><SearchIcon fontSize="large" className={orientation === 'portrait' ? classes.categoryIconMob : classes.categoryIcon } onClick={()=>{setSearchShow(true)}} /></Button>
                  {/* <Button className={classes.mobileIcons} aria-describedby={userId} onClick={handleClickUser}><PersonIcon fontSize="large" className={orientation === 'portrait' ? classes.categoryIconMob : classes.categoryIcon} /></Button> */}
                  <NoficitationComponent />
                  {auth?.user?.role === 'Customer' ? 
                  <Button className={classes.mobileIcons} aria-describedby={storeId} onClick={()=>{
                      navigate("/cart","forward","push");
                  }}>
                    <Badge badgeContent={cart?.products ? cart.products.length : 0} color="secondary"><LocalMallIcon fontSize="large" style={{color: 'var(--ion-color-white)'}}/></Badge>
                    
                    </Button> : ''}
                  
                  <Popover
                    id={userId}
                    open={openUser}
                    anchorEl={anchorE2}
                    onClose={handleCloseUser}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Grid container style={{ cursor: 'context-menu', padding: '10px'}} >
                            <Grid item key="profile" xl={12} lg={12} sm={12} >
                             <h6><a onClick={()=>{
                                navigate("/profile","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Profile</a></h6>
                              {auth?.user?.role === 'Customer' ? <h6><a onClick={()=>{
                                navigate("/orders","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Orders</a></h6> : 
                              <div>
                                 <h6><a onClick={()=>{
                                navigate("/product","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Products</a></h6>
                                <h6><a onClick={()=>{
                                navigate("/orderSupplier","forward","push");
                              }} style={{color: 'var(--ion-color-secondary)'}}>Orders</a></h6>
                             
                              </div>
                              }
                              <h6><a target="_blank" href="https://pktbazaar.com/#contact" style={{color: 'var(--ion-color-secondary)'}}>Help</a></h6>
                              <LogoutButton />
                            </Grid>
                        </Grid>
                  </Popover>
              </Grid>  
            </Grid>
            }
        </Toolbar>
        }
      </AppBar>
      {size!=='lg' && size!=='xl' ? <Drawer 
        onMouseOver = {handleDrawerOpen}
        onMouseLeave = {handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <LogoutButton />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <div>
        <div  className={classes.sidebarText}>
        <ListItemButton component="a" onClick={()=>{navigate("/home","back","pop");setOpen(false)}}>
          <ListItemText primary="Home" />
        </ListItemButton>
        </div>
        <div  className={classes.sidebarText}>
        <ListItemButton component="a" onClick={()=>{navigate("/profile","forward","push");setOpen(false)}}>
          <ListItemText primary="Profile" />
        </ListItemButton>
        </div>
        {auth?.user?.role === 'Customer' ? <div  className={classes.sidebarText}>
        <ListItemButton component="a" onClick={()=>{navigate("/categories","forward","push");setOpen(false)}}>
          <ListItemText primary="Shop By Categories" />
        </ListItemButton>
        </div> : '' }
        {auth?.user?.role === 'Customer' ?<div  className={classes.sidebarText}>
        <ListItemButton component="a" onClick={()=>{navigate("/brands","forward","push");setOpen(false)}}>
          <ListItemText primary="Shop By Brands" />
        </ListItemButton>
        </div> : '' }
        <div  className={classes.sidebarText}>
        <ListItemButton  component="a" onClick={()=>{navigate(auth?.user?.role === 'Customer' ? "/orders" : "/orderSupplier","forward","push");setOpen(false)}}>
          <ListItemText primary="Orders" />
        </ListItemButton>
        </div>
        {auth?.user?.role === 'Supplier' ? <div  className={classes.sidebarText}>
        <ListItemButton  component="a" onClick={()=>{navigate("/product","forward","push");setOpen(false)}}>
          <ListItemText primary="Products" />
        </ListItemButton>
        </div>: ''}
    </div>
        <div className="menu_footer">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
          </IconButton>
        </Toolbar>
        </div>
      </Drawer> : ''}
      <div>
        
      </div>
    </div>
    // </animated.div>
  );
}