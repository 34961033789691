import { IonRouterLink, IonSlide, IonSlides, NavContext } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { GetBrandSlideOptions } from '../../services/SlideOptions';


const BrandSlideComponent = () => {
    const brandData = useSelector((state:any) => 
        state.firestore.data.brands && state.firestore.data.brands.brand && state.firestore.data.brands.brand.brand ? state.firestore.data.brands.brand.brand : []
    );
    const { size, orientation, width, height } = UseDeviceScreenSize();
    useFirestoreConnect(
        [
          {
              collection: 'brand',
              storeAs: "brands",
          },
        ]
      );
    return (
        <div>
            {
                brandData && Object.values(brandData).length>0 ? 
                <IonSlides pager={true} options={GetBrandSlideOptions()}>
                    {Object.values(brandData).map((item:any) =>{
                          if(item.url){
                            return (
                              <IonSlide key={item.name+item.sub} style={{height: size === 'xl' ? '140px' : size === 'lg' ? '120px' : size === 'md' ? '100px' : size === 'sm' ? '80px' : '80px'}}>
                                <IonRouterLink routerLink={"/store?brand="+item.name}>
                                <img style={{height: size === 'xl' ? '100px' : size === 'lg' ? '80px' : size === 'md' ? '60px' : size === 'sm' ? '40px' : '40px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}} src={item.url}></img>
                                </IonRouterLink>
                              </IonSlide>
                            )
                          }
                    })}
                </IonSlides> : ''
            }
        </div>
    );
};

export default BrandSlideComponent;