import { IonBadge, IonItem, IonList, NavContext } from "@ionic/react";
import { Card, CardActionArea, CardContent, Grid, Popover } from "@material-ui/core";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Button } from "@mui/material";
import { height, minWidth } from "@mui/system";
import React, { useContext, useState } from "react";
import settings from "../../config/settings";
import GlobalService from "../../services/GlobalService";
import LocalStorageService from "../../services/LocalStorageService";
import Badge from '@mui/material/Badge';
import { Capacitor } from "@capacitor/core";
import firebase from 'firebase/app';
import "firebase/messaging";
import { PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import CustomButton from "../common/CustomButton";

const NoficitationComponent = () => {

    const [notifications, setNotifications] = useState<[]>();
    const [anchorE2, setAnchorE2] = React.useState<HTMLButtonElement | null>(null);
    const openNotification = Boolean(anchorE2);
    const notificationId = openNotification ? 'notification-popover' : undefined;
    const [push, setPush] = useState<boolean>(true);

    const handleClickNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorE2(event.currentTarget);
      };
    
      const handleCloseNotification = () => {
        setAnchorE2(null);
      };

      const parseDate = (date: string) => {
        var d = date.split(", ")[0];
        var t = date.split(", ")[1];
        var da:any = d.split("/");
        var ta:any = t.split(" ");
        var hour = ta[1] === "AM" ? ta[0].split(":")[0] : parseInt(ta[0].split(":")[0])+12;
        var newDate = new Date(da[2], parseInt(da[1])-1, da[0], hour, ta[0].split(":")[1]);
          return newDate.getTime();
      }

    if(!notifications){
        var lastNotifications:any = LocalStorageService.getStorage("unotifications");
        lastNotifications.then((res)=>{
            console.log(res);
            if(res && res.length>50){
                var newList = res.reverse();
                setNotifications(newList.slice(0,50));
            }else{
                setNotifications(res.reverse());
            }
        })
    }  

    if(!Capacitor.isNative){
        firebase.messaging().onMessage(async (notification:any) => {
            console.log('Notification received!', notification);
            var lastNotifications:any = await LocalStorageService.getStorage("unotifications");
            var noti:any = notification;
            noti.date = GlobalService.getIndianDateFormatFromDateObject(new Date());
            noti.click_action = notification.data.id;
            lastNotifications.push(noti);
            setNotifications(lastNotifications.reverse());
            await LocalStorageService.setStorage({key: "unotifications", value: JSON.stringify(lastNotifications)})
            // GlobalService.shownotification(notification.notification.tag,notification.notification.title,"info",undefined,undefined,()=>{console.log("navigate")});
          });
    }else{
        if(push){
            setPush(false);
            PushNotifications.addListener('pushNotificationReceived',
                async (notification: PushNotificationSchema) => {
                    console.log('Notification received!', notification);
                    var lastNotifications:any = await LocalStorageService.getStorage("unotifications");
                    var noti:any = notification;
                    noti.date = GlobalService.getIndianDateFormatFromDateObject(new Date());
                    console.log(noti)
                    lastNotifications.push(noti);
                    console.log(lastNotifications)
                    setNotifications(lastNotifications.reverse());
                    await LocalStorageService.setStorage({key: "unotifications", value: JSON.stringify(lastNotifications)})
                    // GlobalService.shownotification(notification.id,notification.title ? notification.title :'New Notification',"info",undefined,undefined,()=>{console.log("navigate")});
                    // setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
                }
            );
        }
    }

    async function clearNotification(){
        await LocalStorageService.removeStorage("unotifications"); 
        setNotifications([]);
    }

    return (
        <>
        {notifications && notifications.length>0 ? <Button style={{color: 'var(--ion-color-white)', padding: '0px'}} aria-describedby={notificationId} onClick={handleClickNotification}><Badge badgeContent={notifications.length} color="secondary"><NotificationsActiveIcon fontSize="large" style={{color: 'var(--ion-color-white)'}} /></Badge></Button> : <Button style={{color: 'var(--ion-color-white)', padding: '2px'}} aria-describedby={notificationId} onClick={handleClickNotification}><NotificationsActiveIcon style={{fontSize: '34px'}} /></Button>}
        <Popover
            id={notificationId}
            open={openNotification}
            anchorEl={anchorE2}
            onClose={handleCloseNotification}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
            <Grid container style={{ cursor: 'context-menu', padding: '10px', minWidth: '400px', maxHeight: '400px'}} >
                    <Grid item xl={12} lg={12} sm={12} >
                        <IonList>
                        {notifications && notifications.length>0 ? 
                            <div><CustomButton onClick={clearNotification} backgroundColor="secondary" title="Clear All Notifications" width="100%" /></div>
                            : ''}
                        {notifications && notifications.length>0 ? 
                            notifications.map((notification:any,index:number)=>{
                                return (
                                    <IonItem key={"notify"+index} routerLink={notification.data && notification.data.id ? notification.data.id : notification.click_action}>
                                    <Card style={{width: '100%', margin: '5px'}}>
                                        <CardActionArea>
                                        <CardContent>
                                        <Grid container justify="space-evenly" style={{padding: '5px', height: '40px', cursor: 'context-menu'}} >
                                            <Grid container spacing={3}>  
                                                <Grid item xs={4} sm={4} md={3} lg={2}>
                                                    <img style={{width: '80px'}} src={notification.icon ? notification.icon : notification.image ? notification.image : notification.data && notification.data.image ? notification.data.image : settings.imagePlaceholder}></img>
                                                </Grid>
                                                <Grid item xs={8} sm={8} md={9} lg={10}>
                                                    <span style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: '14px'}}><b>{notification.title}</b></span><br style={{content: '""', margin: '.8em', display: 'block', marginBottom: '-5px'}}></br>
                                                    {notification.body ? <span style={{marginLeft: '5px', color: 'var(--ion-color-secondary)', fontSize: '12px'}}>{notification.body}</span> : ''}<br></br>
                                                    <span style={{marginLeft: '5px', color: 'var(--ion-color-primary)', fontSize: '12px'}}>{notification.date}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    </IonItem>
                                )
                            })
                            : <p>No notifications</p>}
                           
                            </IonList>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}

export default NoficitationComponent;