import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ValidationPattern from '../../services/ValidationPattern';
import CustomTextInput from '../../components/common/CustomTextInput';
import { Link } from 'react-router-dom';
import validation from '../../services/ValidationPattern';
import firebaseAuth from '../../hooks/firebaseAuth';
import { IonBadge, IonItem, IonLabel, IonListHeader, IonRadio, IonRadioGroup, IonSelect, IonSelectOption, NavContext } from '@ionic/react';
import GlobalService from '../../services/GlobalService';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import CustomLoading from '../../components/loading/CustomLoading';
import firebase from 'firebase/app'; import 'firebase/auth';
import { db } from '../../App';
import { useCategoryInit } from '../category/CategoryData';
import Select from 'react-select';
import { Category } from '../../interface/Category';
import swal from 'sweetalert';
import LogoutButton from '../../components/common/LogoutButton';
import { useBrandInit } from '../brand/BrandData';
import { Country, State, City }  from 'country-state-city'; 
import { User } from '../user/User';
import ApprovalService from '../approval/ApprovalService';
import FirestoreService from '../../services/FirestoreService';
import NativeApi from '../../services/NativeApi';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CategoryIcon from '@mui/icons-material/Category';
import UserCategoryCard from '../user/UserCategoryCard';

export interface SelectOption {
  label: string,
  value: any,
} 

export default function SignupForm() {
  const classes = useStyles();
  let { height, width } = useWindowDimensions();
  let landscape = height > 500;
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const [loading, setLoading] = useState<Boolean>();
  const { categories } = useCategoryInit();
  const [category, setCategory] = useState<SelectOption[]>([]);
  const [subCategory, setSubCategory] = useState<SelectOption[]>([]);
  const [mainCategory, setMainCategory] = useState<SelectOption[]>([]);
  const [categoryValue, setCategoryValue] = useState<string>();
  const [subCategoryValue, setSubCategoryValue] = useState<string>();
  const [mainCategoryValue, setMainCategoryValue] = useState<string>();
  const [brandValue, setBrandValue] = useState<string>();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const { brands } = useBrandInit();
  const [brand, setBrand] = useState<SelectOption[]>([]);

  const [countries, setCountries] = useState<SelectOption[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [cities, setCities] = useState<SelectOption[]>([]);

  const [logindata, setLoginData] = React.useState({
    email: '',
    emailError: 'Enter correct email address',
    password: '',
    passwordError: 'Min 8 characters and at least 1 number',
    check_textInputChange: false,
    isValidUser: true,
    isValidPassword: true, 
    isValidEmail: true, 
    secureTextEntry: true,
    loginError: '',
    signUp: false,
    name: '',
    nameError: 'Number and special character are not allowed',
    firmError: 'Number and special character are not allowed',
    isValidName: true,
    gst: '',
    isValidGst: true,
    gstError: 'Please enter correct GST number',
    number: '',
    numberError: 'Please enter correct 10 digit number',
    isValidNumber:true,
    showPassword: false,
    firmName: '',
    isValidFirmname: true,
    accountType: '',
    customerType: '',
    address: {country: {isoCode: 'IN', name: 'India'}, state: {isoCode: '', name: ''}, city: '', pin: '', local: ''},
    isValidPin: true,
    pinError: 'Please enter correct pin',
    referralCode: '',
    userId: '',
    status: '',
  });

useEffect(() => {
  const res = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
  if(firebaseUser){  
    logindata.number = firebaseUser.phoneNumber;
    const subscriber = db.collection("users").doc(firebaseUser.uid)
    .onSnapshot((doc) => {
      const res = doc.data();
        if(res){
          const data = res.user;
          logindata.name = data.name ? data.name : '';
          logindata.firmName = data.firmName ? data.firmName : '';
          logindata.gst = data.gst ? data.gst : '';
          logindata.email = data.email ? data.email : '';
          logindata.accountType = data.role ? data.role : '';
          logindata.customerType = data.customerType ? data.customerType : '';
          logindata.referralCode = data.referralCode ? data.referralCode : '';
          logindata.userId = data.userId ? data.userId : '';
          logindata.number = data.number ? data.number : '';
          logindata.status = data.status ? data.status : '';
          if(data.address){
            logindata.address = data.address;
          }
          if(data.categories && data.categories.length>0){
            setCategoryList(data.categories);
          }
        }
    });
  }
});
  // Unsubscribe from events when no longer in use
  return res;
}, []);

const checkEmail = (val:any) =>{
    var email = val.target.value;
    logindata.email = email;
    if(validation.checkEmail(email)){
        logindata.isValidEmail = true;
        logindata.email = email;
        setLoginData({
            ...logindata
        });
    }else{
        logindata.isValidEmail = false;
        setLoginData({ ...logindata});
    }
    
}

const checkName = (val:any) =>{
    var name = val.target.value;
    logindata.name = name ? GlobalService.capitalizeCamelCase(name) : name;
    if(validation.checkName(name)){
        logindata.isValidName = true;
        setLoginData({ ...logindata});
    }else{
        logindata.isValidName = false;
        setLoginData({ ...logindata});
    }
}

const checkFirmname = (val:any) =>{
  var name = val.target.value;
  logindata.firmName = name ? GlobalService.capitalizeCamelCase(name) : name;
  if(validation.checkName(name)){
      logindata.isValidFirmname = true;
      setLoginData({ ...logindata});
  }else{
      logindata.isValidFirmname = false;
      setLoginData({ ...logindata});
  }
}

const checkGst = (val:any) =>{
  var gst = val.target.value.toUpperCase();
  logindata.gst = gst;
  if(validation.checkGst(gst) ){
      logindata.isValidGst = true;
      setLoginData({ ...logindata});
  }else{
      logindata.isValidGst = false;
      setLoginData({ ...logindata});
  }
}

const checkPin = (val:any) =>{
  var pin = val.target.value;
  logindata.address.pin = pin;
  if(validation.checkPin(pin) ){
      logindata.isValidPin = true;
      setLoginData({ ...logindata});
  }else{
      logindata.isValidPin = false;
      setLoginData({ ...logindata});
  }
}

const setAddress = (val:any) =>{
  var addr = val.target.value;
  logindata.address.local = addr ? GlobalService.capitalizeCamelCase(addr) : addr;
  setLoginData({ ...logindata});
}

async function submitForm() {
  if(logindata.isValidName && logindata.name!=='' && logindata.isValidGst && logindata.gst!=='' && logindata.accountType && logindata.isValidFirmname && logindata.firmName && logindata.address.city && logindata.address.pin && logindata.address.local){
   
    if(logindata.accountType === 'Supplier' && (!categoryList || categoryList.length<1)){
      GlobalService.showtoast('failed', "Please select at least one category", "error",undefined,undefined);
      return;
    }
    if(logindata.accountType === 'Customer' && !logindata.customerType){
      GlobalService.showtoast('failed', "Please select customer account type", "error",undefined,undefined);
      document.getElementById("customer-account-type")?.click();
      return;
    }
      var user:User = {name: logindata.name, gst: logindata.gst, role: logindata.accountType === 'Customer' ? 'Customer' : 'Supplier'}
      user.categories = categoryList;
      user.address = logindata.address;
      if(logindata.number){
          user.number = logindata.number;
      }
      if(logindata.status){
        user.status = logindata.status; 
      }
      if(logindata.email && logindata.isValidEmail && logindata.email!==''){
        user.email = logindata.email;
      }
      if(logindata.firmName && logindata.isValidFirmname && logindata.firmName!==''){
        user.firmName = logindata.firmName;
      }
      if(logindata.customerType){
        user.customerType = logindata.customerType;
      }
      if(logindata.referralCode){
        user.referralCode = logindata.referralCode;
      }else{
        user.referralCode = GlobalService.randomString(6);
      }
      if(logindata.userId){
        user.userId = logindata.userId;
      }else{
        var ui = "";
        var rd = GlobalService.randomDigit(11,99);
        
        if(logindata.firmName.replaceAll(/\s+/g, '').length>5){
          ui = logindata.firmName.replaceAll(/\s+/g, '').substring(0,4);
          ui = ui + rd;
        }else{
          ui = GlobalService.randomString(4);
          ui = ui + rd;
        }
        user.userId = ui;
      }
      setLoading(true);
      const res = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
        if(firebaseUser && firebaseUser.uid){
          user.id = firebaseUser.uid;
          const res = await ApprovalService.addApprovalData(user, firebaseUser.uid);
          setLoading(false);
          if(res){
            GlobalService.showtoast('success', "Data submitted successfully", "success",undefined,undefined);
          }
        }else{
          setLoading(false);
        }
      });
  }else{
      GlobalService.showtoast('failed', "Enter valid details", "error",undefined,undefined);
  }
}

const setSelected = (val: any) => {
  logindata.accountType = val;
  setLoginData({...logindata});
}

const setCustomerType = (val: any) => {
  logindata.customerType = val;
  setLoginData({...logindata});
}

const loadCounty = () =>{
  var cs = Country.getAllCountries();
  var list:SelectOption[] = [];
  for(var obj of cs){
    list.push({label: obj.name, value: obj});
  }
  setCountries(list);
}

const loadState = () => {
  if(logindata.address.country.isoCode){
    var s = State.getStatesOfCountry(logindata.address.country.isoCode);
    var list:SelectOption[] = [];
    for(var obj of s){
      list.push({label: obj.name, value: obj});
    }
    setStates(list);
  }
}

const loadCity = () => {
  if(logindata.address.state.isoCode && logindata.address.country.isoCode){
    var c = City.getCitiesOfState(logindata.address.country.isoCode, logindata.address.state.isoCode);
    var list:SelectOption[] = [];
    for(var obj of c){
      list.push({label: obj.name, value: obj});
    }
    setCities(list);
  }
}

const setCountryOption = (opt:any) =>{
  logindata.address.country.isoCode = opt.value.isoCode;
  logindata.address.country.name = opt.value.name;
  logindata.address.state.isoCode = '';
  logindata.address.state.name = '';
  setLoginData({...logindata});
}

const setStateOption = (opt:any) =>{
  logindata.address.state.isoCode = opt.value.isoCode;
  logindata.address.state.name = opt.value.name;
  logindata.address.city = '';
  setLoginData({...logindata});
}

const setCityOption = (opt:any) =>{
  logindata.address.city = opt.value.name;
  setLoginData({...logindata});
}

const loadCategory = () =>{
  if(categories){
    var list:SelectOption[] = [];
    for(var cat of categories){
      list.push({label: cat.name, value: cat});
    }
    setCategory(list);
  }
}

const loadBrand = () =>{
  if(brands){
    var list:SelectOption[] = [];
    for(var brd of brands){
      list.push({label: brd.name, value: brd});
    }
    setBrand(list);
  }
}

const unloadBrand = () =>{
  setBrand([]);
}

const setBrandOption = (opt:any) =>{
  setBrandValue(opt.label);
}

const setPrimaryCategory = (opt:any) =>{
  setCategoryValue(opt.label);
  setSubCategoryValue('');
  setMainCategoryValue('');
  var list:SelectOption[] = [];
  if(opt.value && opt.value.subCategories && opt.value.subCategories.length>0){
    for(var cat of opt.value.subCategories){
      list.push({label: cat.name, value: cat.sub2Categories});
    }
  }else{
    // setTimeout(function () {
    //   setUserCategory(opt.label, undefined, undefined, undefined);
    // }, 200);
  }
  setSubCategory(list);
  setMainCategory([]);
}

const setSecondaryCategory = (opt:any) =>{
  setSubCategoryValue(opt.label);
  setMainCategoryValue('');
  var list:SelectOption[] = [];
  if(opt.value && opt.value.length > 0){
    for(var cat of opt.value){
      list.push({label: cat.name, value: cat.name});
    }
  }else{
    // setTimeout(function () {
    //   setUserCategory(categoryValue , opt.label, undefined, undefined);
    // }, 200);
  }
  setMainCategory(list);
}

const setThirdCategory = (opt:any) =>{
  setMainCategoryValue(opt.label);
  // setTimeout(function () {
  //   setUserCategory(categoryValue, subCategoryValue, opt.label, undefined);
  // }, 200);
}

const setUserCategory = () =>{
  var cat:Category = { primary: categoryValue ? categoryValue : '' };
  if(brandValue){
    cat.brand = brandValue;
    var brand:any = brands?.find((e) => e.name === brandValue)
    if(brand.url){
      cat.brandLogo = brand.url;
    }
  }
  if(categoryValue){
    cat.primary = categoryValue;
  }
  if(subCategoryValue){
    cat.sub = subCategoryValue;
  }
  if(mainCategoryValue){
    cat.main = mainCategoryValue;
  }
  if(JSON.stringify(categoryList).includes(JSON.stringify(cat))){
    GlobalService.showtoast(categoryValue, "Category already exist", "error", undefined, undefined);
  }else if(categoryValue && subCategoryValue){
    var t1 = brandValue ? brandValue+' -> ' : '';
    var t2 = mainCategoryValue ? mainCategoryValue : subCategoryValue ? subCategoryValue : categoryValue ? categoryValue : '';
    swal({
      title: t1+t2,
      text: "Click confirm to add this category",
      icon: "warning",
      dangerMode: true,
      buttons: ["cancel","confirm"],
    })
    .then(async (confirm) => {
      if (confirm) {
        const list:Category[] = categoryList ? Object.assign([], categoryList) : [];
        list.push(cat);
        setCategoryList(list);
        setCategoryValue('');
        setMainCategoryValue('');
        setSubCategoryValue('');
        setBrandValue('');
      }
    });
  }else{
    GlobalService.showtoast("Failed", "Primary and Sub Categories are mandatory.", "error", undefined, undefined);
  }
}

const removeCategory = (item:Category) =>{
  
  var t1 = item.brand ? item.brand+' -> ' : '';
  var t2 = item.main ? item.main : item.sub ? item.sub : item.primary ? item.primary : '';
  swal({
    title: t1+t2,
    text: "Are you sure want to remove this category",
    icon: "warning",
    dangerMode: true,
    buttons: ["cancel","confirm"],
  })
  .then(async (confirm) => {
    if (confirm && categoryList) {
      var newString = "";
      if(JSON.stringify(categoryList).includes(","+JSON.stringify(item))){
        newString = JSON.stringify(categoryList).replace(","+JSON.stringify(item), "");
      }else if(JSON.stringify(categoryList).includes(JSON.stringify(item)+",")){
        newString = JSON.stringify(categoryList).replace(JSON.stringify(item)+",", "");
      }else{
        newString = JSON.stringify(categoryList).replace(JSON.stringify(item), "");
      }
      
      if(newString){
        var obj = JSON.parse(newString);
        
        setCategoryList(obj);
      }
    }
  });
}

const uploadImage = (item: Category) => {
  var imageurl = NativeApi.takePicture(0, 0, 0);
  imageurl.then((img) => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
      var name:any = GlobalService.getCurrentDate().getTime();
      var format = ".jpeg";
      var str = img.split("base64")[0];
      if(str.includes("png")){
        format = ".png";
      };
      var path = firebaseUser.uid + "/brand/logo/";
      var filaName:any = GlobalService.getCurrentDate().getTime();
      var res = await FirestoreService.uploadPicture(img, path+filaName+format);
      if (res) {  
        setLoading(false);
        GlobalService.showtoast('image', 'Image uploaded', 'success', undefined, undefined);
          // set logo url to brand object from array
          if (categoryList) {
              var newString = "";
              if(JSON.stringify(categoryList).includes(","+JSON.stringify(item))){
                  newString = JSON.stringify(categoryList).replace(","+JSON.stringify(item), "");
              }else if(JSON.stringify(categoryList).includes(JSON.stringify(item)+",")){
                  newString = JSON.stringify(categoryList).replace(JSON.stringify(item)+",", "");
              }else{
                  newString = JSON.stringify(categoryList).replace(JSON.stringify(item), "");
              }
              
              if(newString){
                  var obj:Category[] = JSON.parse(newString);
                  
                  var cat:Category = { primary: item.primary ? item.primary : '' };
                  if(item.brand){
                      cat.brand = item.brand;
                  }
                  if(item.sub){
                      cat.sub = item.sub;
                  }
                  if(item.main){
                      cat.main = item.main;
                  }
                  cat.brandLogo = settings.firestoreUrlPrefix+firebaseUser.uid+"%2Fbrand%2Flogo%2F"+filaName+format+settings.firestoreUrlSuffix;
                  obj.push(cat)
                  setCategoryList(obj);
                  // setTimeout(function () {
                  //     submitForm();
                  // }, 1000);
              }
          }
      }else {
        setLoading(false);
      }
    });
  })
}


  return (
    <Container component="main" maxWidth="xs" style={{overflow: 'auto', paddingBottom: '100px'}}>
      <CssBaseline />
      {loading ? <CustomLoading /> : ''}
      <div className={classes.paper} style={{marginTop: landscape ? '10px' : '0px'}}>
        <form className={classes.form}  noValidate>
        <Grid container justify="space-evenly" >    
            <Grid container spacing={3}>
               <Grid item lg={12} md={12} sm={12} xs={12}>
                  <IonBadge style={{marginBottom: '20px', fontSize: '16px'}}>Referral Id: {logindata.referralCode}</IonBadge>
                </Grid> 
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidName} 
                    errorText={logindata.nameError} required={true} 
                    label="Full Name" name="name" autoComplete="name" value={logindata.name}
                    onchange={checkName} />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidFirmname && logindata.firmName!==''} required={true} 
                    errorText={logindata.firmError} 
                    label="Firm Name" name="firmName" autoComplete="firmName" value={logindata.firmName} 
                    onchange={checkFirmname} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput required={true} 
                    label="Number" name="number" value={logindata.number} disabled = {true}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidEmail && logindata.email!==''} 
                    errorText={logindata.emailError}
                    label="Email" name="email" type="email" autoComplete="email" value={logindata.email} 
                    onchange={checkEmail} />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <CustomTextInput error={!logindata.isValidGst} 
                    errorText={logindata.gstError} required={true} 
                    label="GST No." name="gst" autoComplete="gst" value={logindata.gst} 
                    onchange={checkGst} />
                </Grid>
                <Grid item lg={logindata.accountType === 'Customer' ?  6 : 12} md={logindata.accountType === 'Customer' ?  6 : 12} sm={12} xs={12}>
                  <IonLabel>Select Account Type: </IonLabel>
                  <IonSelect value={logindata.accountType} placeholder="Select Account Type" onIonChange={e => setSelected(e.detail.value)}>
                    <IonSelectOption value="Customer">Customer</IonSelectOption>
                    <IonSelectOption value="Supplier">Supplier</IonSelectOption>
                  </IonSelect>
                </Grid> 
                {logindata.accountType === 'Supplier' ? <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <IonLabel>Select Brand and Category</IonLabel>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Select options={brand} value={{label: brandValue ? brandValue : 'Select Brand', value: brandValue ? brandValue : ''}} onFocus={loadBrand} onChange={setBrandOption} ></Select> 
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Select options={category} value={{label: categoryValue ? categoryValue : 'Select Primary Category', value: categoryValue ? categoryValue : ''}} onFocus={loadCategory} onChange={setPrimaryCategory} isDisabled={!brandValue} ></Select> 
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Select options={subCategory} value={{label: subCategoryValue ? subCategoryValue : 'Select Sub Category', value: subCategoryValue ? subCategoryValue : ''}} isDisabled={!categoryValue} onChange={setSecondaryCategory} ></Select> 
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={12}>
                  <Select options={mainCategory} value={{label: mainCategoryValue ? mainCategoryValue : 'Select Main Category', value: mainCategoryValue ? mainCategoryValue : ''}} isDisabled={!subCategoryValue} onChange={setThirdCategory} ></Select> 
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                fullWidth
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={setUserCategory}
                >Add Category
                </Button>
                </Grid>
                  {
                    categoryList ? categoryList.map((item, index) =>{
                      return (
                        <Grid item lg={3} md={4} sm={6} xs={12} key={'brands'+index}>
                          <UserCategoryCard item={item} removeItem={()=>{removeCategory(item)}}/>
                        </Grid>
                      )
                    }) : ''
                  }
                </Grid> : 
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <IonLabel>Select Customer Type: </IonLabel>
                  <IonSelect value={logindata.customerType} placeholder="Select Customer Type" onIonChange={e => setCustomerType(e.detail.value)}>
                    <IonSelectOption value="Retailer">Retailer</IonSelectOption>
                    <IonSelectOption value="Commission Agent">Commission Agent</IonSelectOption>
                    {/* <IonSelectOption value="Whole Seller">Whole Seller</IonSelectOption> */}
                  </IonSelect>
                </Grid> 
                }
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Select options={countries} value={{label: logindata.address.country.name ? logindata.address.country.name : 'Select Country', value: logindata.address.country.name ? logindata.address.country : ''}} onFocus={loadCounty} onChange={setCountryOption} ></Select> 
                </Grid> 
                <Grid item lg={4} md={4} sm={6} xs={12}>
                <Select options={states} value={{label: logindata.address.state.name ? logindata.address.state.name : 'Select State', value: logindata.address.state.name ? logindata.address.state : ''}} onFocus={loadState} onChange={setStateOption} ></Select> 
                </Grid> 
                <Grid item lg={4} md={4} sm={6} xs={12}>
                <Select options={cities} value={{label: logindata.address.city ? logindata.address.city : 'Select City', value: logindata.address.city ? logindata.address.city : ''}} onFocus={loadCity} onChange={setCityOption} ></Select> 
                </Grid> 
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <CustomTextInput required={true} 
                    label="Address" name="local" value={logindata.address.local} 
                    onchange={setAddress} />
                </Grid> 
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <CustomTextInput error={!logindata.isValidPin} 
                    errorText={logindata.pinError} required={true}  type="number"
                    label="Pincode" name="pin" value={logindata.address.pin} 
                    onchange={checkPin} />
                </Grid> 
            </Grid>
            <Grid item xs={12}>
            <Button
                fullWidth
                disabled={logindata.accountType ? false : true}
                variant="contained"
                className={landscape ? classes.submit : classes.submit2}
                onClick={submitForm}
            >
               {auth?.user?.name? 'Update': 'Add'} 
            </Button>
            </Grid>
        </Grid>
        </form>
      </div>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
    loginHeading: {
        color: 'var(--ion-text-color)',
        marginBottom: '10px',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      margin: theme.spacing(3, 0, 2),
      fontWeight: 'bold',
    },
    submit2: {
        color: 'var(--ion-text-color-highlight)',
        backgroundColor: 'var(--ion-color-primary)',
        fontWeight: 'bold',
        margin: 0,
    },
    link: {
        color: 'var(--ion-color-primary)',
    },
  }));