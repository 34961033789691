import { IonPage, NavContext } from '@ionic/react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import swal from '@sweetalert/with-react';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext, useState } from 'react';
import CustomLoading from '../../components/loading/CustomLoading';
import SideMenuUser from '../../components/menu/SideMenuUser';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { ImageInterface } from '../../interface/ImageInterface';
import GlobalService from '../../services/GlobalService';
import NotificationService from '../../services/NotificationService';
import { useBrandInit } from '../brand/BrandData';
import { Order } from '../order/Order';
import OrderService from '../order/OrderService';
import { Product } from '../product/Product';
import { UserCart } from '../user/UserCart';
import { useUserCartInit } from '../user/UserCartData';
import UserService from '../user/UserService';
import CartProductCard from './CartProductCard';
import CartProductCardMob from './CartProductCardMob';

const CartView: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const [loading, setLoading] = useState<boolean>();
  let { cart } = useUserCartInit();
  const {brands} = useBrandInit();
  const { size, orientation, width, height } = UseDeviceScreenSize();

  async function placeOrderProcess(type:string){
    const res = firebase.auth().onAuthStateChanged(async (firebaseUser:any) => {
      if(cart && cart.products && cart.products.length>0){
        var order:Order = {products: cart.products, total: cart.total, supplier: cart.supplier};
        order.type = type;
        // set user 
        order.user = auth?.user;
        
        if(!order.user?.email){
          if(order && order.user)
          order.user.email = "";
        }
        if(!order.user?.number){
          if(order && order.user)
          order.user.number = "";
        }
        if(!order.user?.firmName){
          if(order && order.user)
          order.user.firmName = "";
        }
        order.category = {brand: cart.brand, sub: cart.subCategory}
        setLoading(true);
        var voucharNo = await OrderService.getVoucherNumber(firebaseUser.uid);
        order.voucherNumber = voucharNo;
        
        if(order.voucherNumber){
          var res = await OrderService.addOrderData(order);
          setLoading(false);
          if(res){
            GlobalService.showtoast("order","Order Placed Successfully", "success", undefined, undefined);
            NotificationService.sendNotificationForOrderPlaced(order.key? order.key : '');
            navigate("/orders","forward","push");
            var newCart:UserCart = {products: []};
            if(auth?.user?.id){
              UserService.addUserCart(auth?.user?.id, newCart);
            }
          }
        }else{
          setLoading(false);
        }
      }
   });
  }

  async function placeOrder(){
    swal({
      title: "Select Order type",
      text: "Delivery within 4 to 5 days for urgent order and within 1 week for regular order. Regular order can be cancelled within 12 hours by customer. And urgent order cannot be cancelled by customer once placed.",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        urgent: true,
        regular: true,
      },
      dangerMode: true,
    })
    .then((value) => {
      switch (value) {
     
        case "regular":
          placeOrderProcess("Regular")
          break;
     
        case "urgent":
          placeOrderProcess("Urgent")
          break;
     
        default:
          
      }
    });
  }

  const quantityUpdate = (product: Product, event: any) =>{
    
    product.quantity = event.target.value;
    
    if(cart && cart.products && cart.products.length>0){
      if(cart.products.some(e => (e.key === product.key && (!product.size || e.size === product.size) && (!product.color || e.color?.name === product.color.name) && (!product.pattern || e.pattern === product.pattern)))){
        // Same product 
        var newProds = cart.products.filter(function( obj ) {
            return (obj.key !== product.key || ((!product.size || obj.size !== product.size) || (!obj.color || obj.color.name !== product.color?.name) || ( obj.pattern !== product.pattern)) );
        });
        newProds.push(product);
        newProds = newProds.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
        var total = 0;
        for(var p of newProds){
          if(p && p.wsp && p.quantity){
            var val = p.size && p.size.quantity && p.size.quantity > 1 ? (parseInt(p.size.quantity+'')*parseInt(p.wsp+'')*parseInt(p.quantity+'')) : (parseInt(p.wsp+'')*parseInt(p.quantity+''));
            total = parseInt(total+'') + parseInt(val+'');
          }
        }
        var carts:UserCart = {products: newProds, total: GlobalService.convert2digitdecimaldouble(total)};
        setLoading(true);
        firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
            var res = await UserService.addUserCart(firebaseUser.uid,carts)
            setLoading(false);
            if(res){
              GlobalService.showtoast(product.key,"Product quantity updated","info",undefined,undefined);
            }
        });  
      }
    }
  }

  
  const removeItem = (product: Product) =>{
    
    if(cart && cart.products && cart.products.length>0){
      if(cart.products.some(e => (e.key === product.key && (!product.size || e.size === product.size) && (!product.color || e.color?.name === product.color.name) && (!product.pattern || e.pattern === product.pattern)))){
        // Same product 
        var newProds = cart.products.filter(function( obj ) {
          if(obj.key !== product.key){
            return true;
          }else{
            if(product.size){
              if(obj.size !== product.size){
                return true;
              }
            }
            if(product.color){
              
              if(obj.color && obj.color.name){
                if(obj.color.name !== product.color?.name){
                  return true;
                }
              }else{
                return true;
              }
            }else{
              if(obj.color && obj.color.name){
                return true;
              }
            }
            return false;
          }
        });
        newProds = newProds.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
        var total = 0;
        for(var p of newProds){
          if(p && p.wsp && p.quantity){
            var val = p.size && p.size.quantity && p.size.quantity > 1 ? (parseInt(p.size.quantity+'')*parseInt(p.wsp+'')*parseInt(p.quantity+'')) : (parseInt(p.wsp+'')*parseInt(p.quantity+''));
            total = parseInt(total+'') + parseInt(val+'');
          }
        }
        var carts:UserCart = {products: newProds, total: GlobalService.convert2digitdecimaldouble(total)};
        setLoading(true);
        firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
            var res = await UserService.addUserCart(firebaseUser.uid,carts)
            setLoading(false);
            if(res){
              GlobalService.showtoast(product.key,"Product removed successfully","warning",undefined,undefined);
            }
        });  
      }
    }
  }

  return (
    <IonPage>
      {loading ? <CustomLoading /> : ''}
         <SideMenuUser title="Product" /> 
         {brands && brands.length>0 ? <div style={{marginTop: '60px',  paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
            {cart && cart.products && cart.products.length>0 ? 
            <Grid container >
                <Grid item lg={10} md={12} sm={12} xs={12} >
                  <Grid container >
                   { cart.products.map((item, index)=>{
                        return (
                          <Grid item key={item.key+''+index} lg={12} md={12} sm={6} xs={6} >
                              {orientation=='portrait' ? <CartProductCardMob brands={brands} key={item.key+'a'+index} product={item} quantityUpdate={quantityUpdate} removeItem={removeItem}></CartProductCardMob> : 
                               <CartProductCard brands={brands} key={item.key+'a'+index} product={item} quantityUpdate={quantityUpdate} removeItem={removeItem}></CartProductCard>  
                              }
                          </Grid>
                      )
                    }) 
                    }
                     </Grid>
                </Grid>
                <Grid item lg={2} md={12} sm={12} xs={12} >
                      <Card sx={{ width: '100%' }}>
                        <CardContent>
                            <h6>Total Approx Amount: <b>&#8377; {cart.total}</b> <br /> + GST Applicable</h6>
                            <Button onClick={placeOrder} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px', width: '100%'}} >Place Order</Button>
                        </CardContent>
                    </Card> 
                </Grid>    
            </Grid> : 
              <div className={classes.center}>
                <Button onClick={()=>{
                  navigate("/store","forward","push");
                }} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px',}} >Shop Now</Button>
              </div>}
          </div> : ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
center: {
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px',
  marginTop: '80px',
  textAlign: 'center'
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default CartView;