import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { bagAdd, extensionPuzzle, gridOutline, home, personCircle } from 'ionicons/icons';
import { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AppStyle.css';
import CustomLoading from './components/loading/CustomLoading';
import { UseAuthInit } from "./hooks/firebase/Auth";
import Approval from './pages/approval/Approval';
import BrandListPage from './pages/brand/BrandListPage';
import CategoryListPage from './pages/category/CategoryListPage';
import HomePage from './pages/home/HomePage';
import Login from './pages/login/Login';
import NotFound from './pages/not-found/NotFound';
import OrderListPage from './pages/order/OrderListPage';
import OrderListPageSupplier from './pages/order/OrderListPageSupplier';
import OrderPage from './pages/order/OrderPage';
import OrderPdf from './pages/order/OrderPdf';
import ProductListPage from './pages/product/ProductListPage';
import ProductPage from './pages/product/ProductPage';
import ProfilePage from './pages/profile/ProfilePage';
import Signup from './pages/signup/Signup';
import CartView from './pages/store/CartView';
import StoreListPage from './pages/store/StoreListPage';
import StoreProductPage from './pages/store/StoreProductPage';
import CheckAppVersion from './services/AppVersion';
import HardwareBackButton from './services/HardwareBackButton';
/* Theme variables */
import './theme/variables.css';
// import initReactFastclick from 'react-fastclick';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import StoreListPageDesktop from './pages/store/StoreListPageDesktop';
import UpdatePage from './pages/update/UpdatePage';


export const FirebaseConfig = {
	apiKey: "AIzaSyA09qtk3TfWjlxOUFq5A7vJRvsxdwsgm1o",
	databaseURL: 'https://eco-app-3ee66.firebaseio.com',
	authDomain: "eco-app-3ee66.firebaseapp.com",
	projectId: "eco-app-3ee66",
	storageBucket: "eco-app-3ee66.appspot.com",
	messagingSenderId: "291124281839",
	appId: "1:291124281839:web:f16485f9a3b9d9e57e761a",
	measurementId: "G-6NP5HL0K6E"
  };
  const firebaseApp = firebase.initializeApp(FirebaseConfig);
  const db = firebaseApp.firestore();
  const fireStorage = firebase.storage();
  let orientation = window.screen.width > window.screen.height ? 'landscape' : 'portrait'; 
  export { db };
export { fireStorage };
export { firebase };

const App: React.FC = () => {
	useEffect(() => {
		// initReactFastclick();
		if(Capacitor.isNative){
			HardwareBackButton();
			CheckAppVersion();
		}
	}, []);
	
	const { loading, auth } = UseAuthInit();
	if (loading) {
		return <CustomLoading />;
	}else{
		SplashScreen.hide();
	}

	return (
		<IonApp>
			<ToastContainer transition={Slide} limit={5} />
			<IonReactRouter>
      		<IonRouterOutlet>
			<Route path="/" exact={true}>
				<Redirect to="/home" />
			</Route>
			<Route path="/login" exact>
				{auth?.loggedIn ? auth?.status === 'active' ?  <Redirect to="/home" /> : <Redirect to="/approval" /> : <Login />}
			</Route>
			<Route exact path="/signIn">
				<Login />
			</Route>	
			<Route path="/signup" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <Redirect to="/home" /> : <Redirect to="/approval" /> : <Signup />}
			</Route>
			{auth?.loggedIn && auth?.status === 'active' && orientation === 'portrait' ? 
			<Route path={auth?.user?.role === 'Supplier' ? ["/home", "/product", "/orderSupplier"] : ["/home", "/categories", "/brands"] }>
				{auth?.loggedIn ? auth?.status === 'active' ? 
					<IonTabs>
					<IonRouterOutlet animated={false}>
					<Route path="/home" exact={true}><HomePage /></Route>
					{auth?.user?.role === 'Customer' ? <Route path="/categories" exact={true}><CategoryListPage /></Route> : <Route path="/product" exact={true} ><ProductListPage /></Route>}
					{auth?.user?.role === 'Customer' ? <Route path="/brands" exact={true}><BrandListPage /></Route> : <Route path="/orderSupplier" exact={true} ><OrderListPageSupplier /></Route>}
					<Route path="/" render={() => <Redirect to="/home" />} exact={true} />
					</IonRouterOutlet>
					<IonTabBar slot="bottom">
					<IonTabButton tab="tab1" href="/home">
						<IonIcon icon={home} />
						<IonLabel>Home</IonLabel>
					</IonTabButton>
					<IonTabButton tab="tab2" href={auth?.user?.role === 'Customer' ? "/categories" : "/product"}>
						<IonIcon icon={gridOutline} />
						<IonLabel>{auth?.user?.role === 'Customer' ? "Category" : "Products"}</IonLabel>
					</IonTabButton>
					<IonTabButton tab="tab3" href={auth?.user?.role === 'Customer' ? "/brands" : "/orderSupplier"}>
						<IonIcon icon={extensionPuzzle} />
						<IonLabel>{auth?.user?.role === 'Customer' ? "Brand" : "Orders"}</IonLabel>
					</IonTabButton>
					<IonTabButton tab="tab4" href="/store">
						<IonIcon icon={bagAdd} />
						<IonLabel>{auth?.user?.role === 'Customer' ? 'Shop Now' : 'View List'}</IonLabel>
					</IonTabButton>
					<IonTabButton tab="tab5" href="#">
						<IonIcon />
						<IonLabel></IonLabel>
					</IonTabButton>
					</IonTabBar>
				</IonTabs>
				: <Redirect to="/approval" /> : <Redirect to="/login" />}
			</Route> 
			: 
			<Route path="/home" exact>
				{auth?.loggedIn ? auth?.status === 'active' ? <HomePage /> : <Redirect to="/approval" /> : <Redirect to="/login" />}
			</Route>
			}
			<Route path="/approval" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <Redirect to="/home" /> : <Approval /> : <Redirect to="/login" />}
			</Route>
			<Route path="/addProduct" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <ProductPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			{orientation === 'portrait' ? <Route path="/storeView" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <StoreListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route> : <Route path="/store" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <StoreListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>}
			<Route path="/storeProduct"  exact={true}>
				{auth?.loggedIn ? auth?.status === 'active' ? <StoreProductPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			<Route path="/cart" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <CartView /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			{	orientation === 'portrait' ?
				<Route path="/store" exact>
				{auth?.loggedIn ? auth?.status === 'active' ? <StoreListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
				</Route> 
				: 
				<Route path="/store" exact>
				{auth?.loggedIn ? auth?.status === 'active' ? <StoreListPageDesktop /> : <Redirect to="/approval" />: <Redirect to="/login" />}
				</Route>
			}
			<Route path="/profile" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <ProfilePage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			{
			orientation === 'landscape' && auth?.user?.role === 'Supplier' ? 
				<Route path="/product" exact> 
				{auth?.loggedIn ? auth?.status === 'active' ? <ProductListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />} 
				</Route> 
				: 	
				<Route path="/categories" exact> 
				{auth?.loggedIn ? auth?.status === 'active' ? <CategoryListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
				</Route>
			}
			{
				orientation === 'landscape' && auth?.user?.role === 'Supplier' ? 
				<Route path="/orderSupplier" exact>
				{auth?.loggedIn ? auth?.status === 'active' ? <OrderListPageSupplier /> : <Redirect to="/approval" />: <Redirect to="/login" />}
				</Route>
				:
				<Route path="/brands" exact>
				{auth?.loggedIn ? auth?.status === 'active' ? <BrandListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
				</Route>
			}
			<Route path="/order" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <OrderPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			<Route path="/orders" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <OrderListPage /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			<Route path="/orderPdf" exact>
			{auth?.loggedIn ? auth?.status === 'active' ? <OrderPdf /> : <Redirect to="/approval" />: <Redirect to="/login" />}
			</Route>
			<Route path="/update" exact>
				<UpdatePage />
			</Route>
			<Route path="/notFound" exact>
				<NotFound /> 
			</Route>
			<Redirect to="/notFound" />
		</IonRouterOutlet>
        </IonReactRouter>
		</IonApp>
	);
}

export default App;
