import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Color } from './Color';

interface Colors{
    colors?:Color[];
}

export function useColorInit():Colors {
  const [colors, setColors] = useState<Colors>({colors: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("color").doc("color")
        .onSnapshot((querySnapshot) => {
            var data:Color[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                data = obj && obj.color ? obj.color : [];
            }
            var us:Colors = {colors: data};
            setColors(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return colors;
}
