import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import NotificationService from '../../services/NotificationService';
import { User } from '../user/User';
import { PreRegistered } from './PreRegistered';

function GetApproval(){
    
}

export async function addApprovalData(user:User, uid: string) {
        var response = false;
        var notify = false;
        if(!user.status){
            user.status = 'inactive';
            notify = true;
        }
        await db.collection("users").doc(uid).set({ 
            user
        })
        .then(() => {
            if(notify){
                NotificationService.sendNotificationForNewAccount();
            }
            response = true;
        })
        .catch((error:any) => {
            CommonErrorHandlar.catcherror(error);
        });
        return await response;
}

export async function addPreRegisteredData(preRegistered:PreRegistered, uid: string) {
    var response = false;
    await db.collection("preregistered").doc(uid).set({ 
        preRegistered
    })
    .then(() => {
        
        response = true;
    })
    .catch((error:any) => {
        CommonErrorHandlar.catcherror(error);
    });
    return await response;
}

export async function removePreRegisteredData(uid:any) {
    var response = false;
    await db.collection("preregistered").doc(uid).delete()
    .then(()=>{
        response = true;
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return response;
}

export default { 
    GetApproval,
    addApprovalData,
    addPreRegisteredData,
    removePreRegisteredData,
}