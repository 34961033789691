import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import { Product } from "../product/Product";
import settings from '../../config/settings';
import GlobalService from '../../services/GlobalService';

function GetProduct(){
    
}

export async function addProductData(product:Product, uid:any) { 
        let timestamp:any = GlobalService.getCurrentDate().getTime();
        var response = false;
        product.updatetimestamp = await GlobalService.getLiveDate();
        if(!product.timestamp){
            product.timestamp = await GlobalService.getLiveDate();
        }
        if(product.user && !product.user.email){
            product.user.email = '';
        }if(product.user && !product.user.number){
            product.user.number = '';
        }if(product.user && !product.user.firmName){
            product.user.firmName = '';
        }
        var products:Product[] = await getUserProducts(uid);
        if(!products){
            CommonErrorHandlar.catcherror("Could not load data.");
            return;
        }
        if(!product.key){
            product.key = "k"+timestamp; 
            products?.push(product);
        }else{
            products = products.filter(function( obj ) {
                return obj.key !== product.key;
            });
            products.push(product);
        }
        if(products.length>settings.maxProductPerUser){
            CommonErrorHandlar.catcherror("Could not add more products");
            return;
        }
        await db.collection("product").doc(uid).set({
            products,
            status: 'active'
        })
        .then(() => {
            
            response = true;
        })
        .catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
        return response;
}

export async function updateProductData(products:Product[], uid:string) { 
    var response = false;
    await db.collection("product").doc(uid).set({
        products,
        status: 'active'
    })
    .then(() => {
        
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export function getProductUrl(product:Product){
    if(product.image){
        var url = settings.firestoreUrlPrefix+product.image.replaceAll("/","%2F")+settings.firestoreUrlSuffix;
        return url;
    }else{
        return '';
    }
    
}

export async function getUserProducts(uid:string){
    var products: Product[] = [];
    
    await db.collection("product").doc(uid)
    .get()
    .then((doc) =>{
        var p:any = doc.data();
        if(p && p.products &&  p.products.length > 0){
            for(var prod of p.products){
                products.push(prod);
            }
        }
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
        return undefined;
    });
    return products;
}

async function getAllProducts(){
    var data:Product[] = [];
    let productsRef = db.collection("product").where("status","==","active")
    let allProducts = await productsRef.get()
        .then(snapshot => {
        snapshot.forEach(doc => {
            var prodObj:Product[] = doc.data().products;
            if(prodObj && prodObj.length>0){
                for(var i=0; i < prodObj.length; i++){
                    if(prodObj[i].status){
                        prodObj[i].id = i+1;
                        data.push(prodObj[i]);
                    }
                }
            }
        });
    })
    return data;
}


export async function searchProduct(text:string, start:number, limit:number){
    var products: Product[] = [];
    await db.collection("product").where("status","==","active")
    .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var prodObj:Product[] = doc.data().products;
          if(prodObj && prodObj.length>0){
            for(var i=0; i < prodObj.length; i++){
              prodObj[i].id = i+1;
              if(prodObj[i].brand?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].category1?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].category2?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].category3?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].name?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].code?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || prodObj[i].name?.toLocaleLowerCase().includes(text.toLocaleLowerCase())){
                products.push(prodObj[i]);
              }
            }
          }
        });
    });
    return products;
}

export async function newsearchProduct(){
    var products = db.collectionGroup('products');
    products.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data());
        });
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return;
}


export default { 
    GetProduct,
    addProductData,
    getProductUrl,
    getUserProducts,
    updateProductData,
    searchProduct,
    getAllProducts,
    newsearchProduct,
}