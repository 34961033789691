import firebase from 'firebase/app';import 'firebase/auth';
import { resolve } from 'path';
import { useEffect, useState } from 'react';
import CommonErrorHandlar from '../services/CommonErrorHandlar';

function CheckAuthentication(){
    const [initializing, setInitializing] = useState(true);
    const [user, setUser] = useState();

    const onAuthStateChanged = (user:any) => {
        setUser(user);
        if (initializing) setInitializing(false);
        
      }
    
    useEffect(() => {
        const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
      }, []);
  
      if (initializing) return null;  
}


async function signinwithemailpassword(logindata:any) {
    try{
        const response = await firebase.auth().signInWithEmailAndPassword(logindata.email, logindata.password);
        return await response;
    }catch(error:any){
        if(error && error.code && error.code === 'auth/user-not-found'){
            try{
                const response = await firebase.auth().createUserWithEmailAndPassword(logindata.email, logindata.password);
                return await response;
            }catch(error){
                CommonErrorHandlar.catcherror(error);
            }
        }else{
            CommonErrorHandlar.catcherror(error);
        }
        return;
    }
}

async function createuserwithemailpassword(logindata:any) {
    try{
        const response = await firebase.auth().createUserWithEmailAndPassword(logindata.email, logindata.password);
        return await response;
    }catch(error){
        CommonErrorHandlar.catcherror(error);
    }
}

async function passwordRest(email:string) {
    try{
        const response = await firebase.auth().sendPasswordResetEmail(email);
        return await response;
    }catch(error){
        CommonErrorHandlar.catcherror(error);
    }
}

async function signinusingphone(number:string, verifier:firebase.auth.ApplicationVerifier) {
    try{
        const response = await firebase.auth().signInWithPhoneNumber(number,verifier);
        return await response;
    }catch(error){
        CommonErrorHandlar.catcherror(error);
    }
}

export default {
    CheckAuthentication,
    signinwithemailpassword,
    createuserwithemailpassword,
    passwordRest,
    signinusingphone,
}