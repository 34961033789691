import { Button, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, IonToggle, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Order } from './Order';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import { useOrderUserInit } from './OrderDataUser';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import swal from 'sweetalert';
import OrderService from './OrderService';
import GlobalService from '../../services/GlobalService';
import SideMenuUser from '../../components/menu/SideMenuUser';
import Tooltip from '@mui/material/Tooltip';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import OrderViewCard from './OrderViewCard';
import CustomLoading from '../../components/loading/CustomLoading';
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import settings from '../../config/settings';
import { Capacitor } from '@capacitor/core';

const OrderListPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { orders } = useOrderUserInit();
  const [orderList, setOrderList] = useState<Order[]>();
  const { size, orientation, width, height } = UseDeviceScreenSize();
  let landscape = height > 500;
  const[loading, setLoading] = useState<boolean>(false);
  const[newDate, setNewDate] = useState<any>();
  useEffect(()=>{
    if(!newDate){
      GlobalService.getLiveDate().then((res)=>{
        if(res){
          setNewDate(res);
          // 
        }
      })
    }
  })
  
  async function cancelOrder(item:Order){
    if(item.status === 'pending' || item.status === 'approved'){
      var oldDate:any = new Date(1970, 0, 1);
      var itemDate:any = item.date;
      oldDate.setSeconds(itemDate.seconds+19800);
      var newDate:any = await GlobalService.getLiveDate();
      
      
      var diff:any = Math.abs(newDate - oldDate) / 36e5;
      
      if(diff<12){
        swal({
          title: "Order Id - "+item.voucherNumber,
          text: "Are you sure that you want to cancel this order ?",
          icon: "warning",
          dangerMode: true,
          buttons: ["No","Yes"],
        })
        .then(willDelete => {
          if (willDelete) {
            item.status = 'cancelled';
            let rs = OrderService.addOrderData(item);
            rs.then((response)=>{
              if(response){
                // Update inventory for each product
              swal(item.status ? item.status : 'Status', "Order has been "+item.status+"!", "success");
              }else{
                swal("Failed!", "Something is not correct!", "failed");
              }
            });
            
          }
        });
      }else{
        GlobalService.showtoast(item.voucherNumber,"Expired","error",undefined,undefined);
      }
    }
  }

  const viewOrder = (key:any) => {
    navigate("/order?key="+key,"forward","push");
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
        field: 'timestamp',
        headerName: 'Date',
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          if(params.row.date){
            return <Tooltip title={GlobalService.getIndianDateFormatFromDate(params.row.date)}><p>{GlobalService.getIndianDateFormatFromDate(params.row.date)}</p></Tooltip>
          }
        },
    },
    {
        field: 'total',
        headerName: 'Amount',
        width: 120,
    }, 
    
    {
      field: 'voucherNumber',
      headerName: 'Order Id',
      width: 120,
  }, 
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
    },  
    {
        field: 'type',
        headerName: 'Type',
        width: 120,
    },  
    {
        field: 'view',
        headerName: 'View',
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
            return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>{viewOrder(params.row.key)}}>View</Button>
        },
    },
    {
      field: 'cancel',
      headerName: 'Cancel', 
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        var oldDate:any = new Date(1970, 0, 1);
        oldDate.setSeconds(params.row.date.seconds);
        var diff:any = Math.abs(newDate - oldDate) / 36e5;
        if(params.row.type === 'Regular' && (params.row.status === 'pending' || params.row.status === 'approved')){
          return <Button style={{margin: '0px', padding: '2px',}} disabled={diff<12 ? false : true} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>{cancelOrder(params.row)}}>cancel</Button>;
        }else{
          return 'NA';
        }
      }
    },
    
    {
      field: 'pdf',
      headerName: 'Order Form', 
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
         //  navigate("/orderPdf?key="+params.row.key,"forward","push");
           OrderService.printPdf(params.row);
        };
        return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={onClick}>Order Form</Button>;
      }
    },
  ]

  const searchevent = (e:any) => { 
    const lowercasedInput = e.detail.value.toLowerCase();
    const results = orders?.filter((order) => {
      return order?.status?.toLowerCase().includes(lowercasedInput);
      // Use the toLowerCase() method to make it case-insensitive
    });
    setOrderList(results);
  }

  return (
    <IonPage>
      {orders || orderList ? '' : <CustomLoading />}
      {loading ? <CustomLoading /> : ''}
         <SideMenuUser title="Orders" searchevent={searchevent}  backButton={true}  /> 
          {newDate ? <div className={classes.main} >
            {orientation === 'portrait' ? <Grid container justify="flex-start">
                  {orderList && orderList.length>0 ? orderList.map((item,index)=>{
                    return (
                      <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={"ordercard"+index}>
                        <OrderViewCard order={item} viewOrder={()=>{viewOrder(item.key)}} newDate={newDate} cancelOrder={()=>{cancelOrder(item)}} viewOrderForm={()=>{OrderService.printPdf(item)}} />
                      </Grid>
                    )
                  }) : orders && orders.length > 0 ? 
                  orders.map((item,index)=>{
                    return (
                      <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={"ordercard"+index}>
                        <OrderViewCard order={item} viewOrder={()=>{viewOrder(item.key)}} newDate={newDate} cancelOrder={()=>{cancelOrder(item)}} viewOrderForm={()=>{OrderService.printPdf(item)}} />
                      </Grid>
                    )
                  })
                  : <IonBadge color="primary">No records available</IonBadge>}
            </Grid> : <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {orderList && orderList.length>0 ? <DataGrid
                    rows={orderList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> : orders && orders.length > 0 ? 
                  <DataGrid
                    rows={orders}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>}
          </div> : ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: { 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default OrderListPage;