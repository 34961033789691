import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import GlobalService from '../../services/GlobalService';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import BrandComponent from '../../components/common/BrandComponent';
import { IonBadge, IonCard } from '@ionic/react';
import CustomButton from '../../components/common/CustomButton';
import { Size } from '../size/Size';
import settings from '../../config/settings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    },
    card: {
        padding: '5px !important',
    }
  }),
);

export default function CartProductCardMob({product, quantityUpdate, removeItem, brands, imageView, type, viewClick}:any) {
  const classes = useStyles();
  const [ saveableCanvas, setSaveableCanvas ] = useState<any>();
  var brand:any = brands.find((e) => e.name === product.brand)
  var quantity:number = 0;
  return (
    <div>
      {removeItem ? 
        <IonBadge  style={{ fontSize:'10px', marginLeft: '10px'}} onClick={()=>{removeItem(product)}} title="Remove" color="primary">Remove Product</IonBadge>
        : ''}
    <IonCard routerLink={"/storeProduct?key="+product.key+"&id="+product.user?.id} style={{marginTop: '0px'}}>
     <CardMedia
        component="img"
        style={{filter: product.status ? 'grayscale(0%)' : 'grayscale(100%)'}}
        height={type === 'store' ? "80" : "60"}
        image={product.thumbnail ? product.thumbnail : product.url ? product.url : settings.imageUploadPlaceholder}
        onClick={type === 'store' ? viewClick : ()=>{GlobalService.viewCanvas(product, 300, 300)}}
      />
      <CardContent onClick={viewClick} sx={{ p: 1, pb: 5+'px !important' }}>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  height: "35px" }} variant="subtitle1" component="div">
            {brand && brand.name ? <BrandComponent titleSize="14px" subTitleSize="12px" logoSize="30px" height="35px" show={true} size1={2} size2={10} size1m={3} size2m={9} size1sm={4} size2sm={8} key={'brand'+product.key} brand={brand} /> : 
            <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
        </Typography>
        <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle2" component="div">
            {product.name} {product.code ? ' | '+product.code : ''}
        </Typography>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle2" component="div">
            {product.category2}
        </Typography>
        {product.quantity ? <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-danger)' }} variant="subtitle2" component="div" color="danger">
            {product.size && product.size.quantity && product.size.quantity>1  ? (product.quantity * product.size.quantity) : product.quantity}X&#8377;{product.wsp}=&#8377;{product.size && product.size.quantity ? product.quantity*product.size.quantity*product.wsp : product.quantity*product.wsp}
        </Typography> : ''}
        <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '2px'}}  variant="body2" color="text.secondary">
            {product.size ?
                <IonBadge color="secondary" style={{marginRight: '5px', fontSize: '9px'}} key={product.size.name}>Size: {product.size.name} | {product.size.quantity} Pcs.</IonBadge>
            : ''}
        </Typography>
        <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '5px'}}  variant="body2" color="text.secondary">
            {product.color ? 
            
                <IonBadge color="secondary" style={{marginRight: '5px', backgroundColor: product.color.code, color: GlobalService.wc_hex_is_light(product.color.code) ? '#000000' : '#ffffff', fontSize: '9px'}} >Color: {product.color.id}</IonBadge>
            
            : ''}
        </Typography>
        {type === 'store' ? <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minHeight: '20px', }}  variant="body2" color="text.secondary">
            {product.sizes && product.sizes.length > 0 ? product.sizes.map((item:Size,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item.name}>{item.name} | {item.quantity} Pcs.</IonBadge>
              )
            }): ''}
          </Typography> : ''}
      </CardContent>
    </IonCard>
    </div>
  );
}