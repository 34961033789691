import { Capacitor } from '@capacitor/core';
import { IonBadge, IonButton, IonLabel, IonPage, NavContext } from '@ionic/react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import firebase from "firebase/app";
import "firebase/auth";
import React, { useContext, useEffect, useState } from 'react';
import CanvasDraw from "react-canvas-draw"; 
import Viewer from 'react-viewer';
import swal from 'sweetalert';
import BrandComponent from '../../components/common/BrandComponent';
import CustomButton from '../../components/common/CustomButton';
import CustomLoading from '../../components/loading/CustomLoading';
import SideMenuUser from '../../components/menu/SideMenuUser';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { ImageInterface } from '../../interface/ImageInterface';
import GlobalService from '../../services/GlobalService';
import { Brand } from '../brand/Brand';
import { useBrandInit } from '../brand/BrandData';
import { Color } from '../color/Color';
import { Product } from '../product/Product';
import ProductService from '../product/ProductService';
import { Size } from '../size/Size';
import { UserCart } from '../user/UserCart';
import { useUserCartInit } from '../user/UserCartData';
import UserService from '../user/UserService';

const ProductPage: React.FC = () => {
  const classes = useStyles();
  let key = new URLSearchParams(window.location.search).get("key");
  let uid = new URLSearchParams(window.location.search).get("id");
  const { size, orientation, width, height } = UseDeviceScreenSize();
  const [ product, setProduct ] = useState<Product>();
  const [ saveableCanvas, setSaveableCanvas ] = useState<any>();
  const { cart } = useUserCartInit();
  const { auth } = UseAuthInit();
  const [loading, setLoading] = useState<boolean>();
  const [image, setImageData] = React.useState<ImageInterface>({url: ''});
  const [imageView, setImageView] = useState<boolean>(false);
  const [brand, setBrand] = useState<Brand>();
  const {brands} = useBrandInit();
  if(!brand && brands && product){
    var br:any = {name: ''};
    if(brands.find((e) => e.name === product.brand)){
      br = brands.find((e) => e.name === product.brand);
    }
    setBrand(br);
  }
  let imageWidth = orientation && orientation === 'portrait' ? (width-20) : 400;
  
  useEffect(() => {
    if(uid && key){
      setLoading(true)
      var res = ProductService.getUserProducts(uid);  
      res.then((products:Product[]) =>{
        
        if(products && products.length>0){
          var prod = products.find(function( obj ) {
              return obj.key === key;
          });
          if(prod){
            setProduct(prod);
            if(prod.url){
              let img = new Image(); 
              img.src = prod.url;
              img.onload = function (event:any) {
                    let  loadedImage:any = event.currentTarget;
                    if(loadedImage){
                      let width = loadedImage.width;
                      let height = loadedImage.height;
                      var r = height/width;
                      var i:ImageInterface = {url: prod && prod.url ? prod.url : '', width: imageWidth, height: r*imageWidth};
                      i.image = loadedImage;
                      setImageData(i);
                      setLoading(false)
                      var canvas:any = document.getElementById("myCanvas");
                      if(canvas)
                      {
                        var c:any = canvas;
                        var ctx = c.getContext('2d');
                        ctx.drawImage(i.image, 0, 0, i.width ? i.width : '300', i.height ? i.height : '300');
                      }
                    }
              } 
            }
          }
          // add product view
          if(prod){
            UserService.addProductView(prod);
          }
        }
      })
    }
    // Unsubscribe from events when no longer in use
    return;
  }, []);

  const addToCart = async () => {
    var random:any = await GlobalService.getLiveDate();
    random = random.getMilliseconds();
    if(product){
      if(product.sizes && product.sizes.length>0){
        if(!product.size){
          GlobalService.showtoast(product.key+random, "Please select size","error",undefined,undefined);
          return;
        }
      }
      if(product.colors && product.colors.length>0){
        if(!product.color){
          GlobalService.showtoast(product.key+random, "Please select color","error",undefined,undefined);
          return;
        }
      }
      // if(product.patterns && product.patterns.length>0){
      //   if(!product.pattern){
      //     GlobalService.showtoast(product.key, "Please select pattern","error",undefined,undefined);
      //     return;
      //   }
      // }
      
      if(cart && cart.products && cart.products.length>0){
        if(product.user?.id === cart.supplier?.id && product.brand === cart.brand && product.category2 === cart.subCategory){
          // check if product already added
          
          if(cart.products.some(e => (e.key === product.key && (!product.size || e.size?.name === product.size?.name) && (!product.color || e.color?.name === product.color.name) && (!product.pattern || e.pattern === product.pattern)))){
            // Same product  
            var newProds = cart.products.filter(function( obj ) {
              
              if(!product.size && !product.color && !product.pattern){
                return (obj.key !== product.key);
              }else{
                
                if(obj.key !== product.key){
                  return true;
                }else{
                  if(product.size){
                    if(obj.size?.name !== product.size.name){
                      return true;
                    }
                  }
                  if(product.color){
                    
                    if(obj.color && obj.color.name){
                      if(obj.color.name !== product.color?.name){
                        return true;
                      }
                    }else{
                      return true;
                    }
                  }else{
                    if(obj.color && obj.color.name){
                      return true;
                    }
                  }
                  if(obj && obj.quantity){
                    product.quantity = obj.quantity + 1;
                  }
                  return false;
                }
              }
            });
            newProds.push(product);
            newProds = newProds.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
            var total = 0;
            for(var p of newProds){
              if(p && p.wsp && p.quantity){
                var val = p.size && p.size.quantity && p.size.quantity > 1 ? (parseInt(p.size.quantity+'')*parseInt(p.wsp+'')*parseInt(p.quantity+'')) : (parseInt(p.wsp+'')*parseInt(p.quantity+''));
                total = parseInt(total+'') + parseInt(val+'');
              }
            }
            var carts:UserCart = {products: newProds, total: total, supplier: product.user, brand: product.brand, subCategory: product.category2};
            setLoading(true);
            firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
                var res = await UserService.addUserCart(firebaseUser.uid,carts)
                setLoading(false);
                if(res){
                  GlobalService.showtoast(product.key+random,"Product quantity updated to "+product.quantity,"info",undefined,undefined);
                }
            });  
            
          }else{
            var prods:Product[] = cart.products;
            product.quantity = 1;
            prods.push(product);
            prods = prods.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
            var total = 0;
            for(var p of prods){
              if(p && p.wsp && p.quantity){
                var val = p.size && p.size.quantity && p.size.quantity > 1 ? (parseInt(p.size.quantity+'')*parseInt(p.wsp+'')*parseInt(p.quantity+'')) : (parseInt(p.wsp+'')*parseInt(p.quantity+''));
                total = parseInt(total+'') + parseInt(val+'');
              }
            }
              var carts:UserCart = {products: prods, total: total, supplier: product.user, brand: product.brand, subCategory: product.category2};
              setLoading(true);  
            firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
                var res = await UserService.addUserCart(firebaseUser.uid,carts)
                setLoading(false);
                if(res){
                  GlobalService.showtoast(product.key+random,"Product added to cart","success",undefined,undefined);
                }
            });  
          }
        }else{
          // alert
          var title = "";
          if(product.brand !=cart.brand){
             title = "You have items from another brand ("+cart.brand+") in your cart";
          }else if(product.category2 !== cart.subCategory){
             title = "You have items from another category ("+cart.subCategory+") in your cart";
          }else{
            title = "You have items from another supplier in your cart";
          }
          swal({
            title: title,
            text: "Do you want to check out or empty your cart ?",
            icon: "warning",
            dangerMode: true,
            buttons: ["cancel","empty cart"],
          })
          .then(willDelete => {
            if (willDelete) {
              var newCart:UserCart = {products: []};
              if(auth?.user?.id){
                UserService.addUserCart(auth?.user?.id, newCart);
              }
            }
          })
        }
      }else{
        var prods:Product[] = [];
        product.quantity = 1;
        prods.push(product);
        var val = product.size && product.size.quantity && product.size.quantity > 1 ? (parseInt(product.size.quantity+'')*parseInt(product.wsp+'')*parseInt(product.quantity+'')) : (parseInt(product.wsp+'')*parseInt(product.quantity+''));
        var total = parseInt(val+'');
        var carts:UserCart = {products: prods, total: total, supplier: product.user, brand: product.brand, subCategory: product.category2};
        setLoading(true);
        firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
            var res = await UserService.addUserCart(firebaseUser.uid,carts)
            setLoading(false);
            if(res){
              GlobalService.showtoast(product.key+random,"Product added to cart","success",undefined,undefined);
            }
        });  
      }
    }
  }
  
  return (
    <IonPage>
        {loading ? <CustomLoading /> : ''}
        {product && product.url ? <Viewer images={[{src: product.url, alt: product.name}]} onClose={()=>{setImageView(false)}} visible={imageView} /> : ''}
         <SideMenuUser title={product && product.name ? product.name : 'Not Found'} backButton={true} /> 
         <div id="storePageContainer" style={{marginTop: '60px',  paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
         { product ? <Grid container>
              { orientation === 'portrait' ? <Grid container>
                {image.url ? <Grid item lg={6} md={6} sm={12} xs={12} id="canvas_view">
                  <canvas id="myCanvas" className={classes.canvasDraw} width={image.width ? image.width : '300'} height={image.height ? image.height : '300'}></canvas>
                    {!Capacitor.isNative ? <IonBadge color="secondary" className={classes.viewBtn} onClick={()=>{setImageView(true)}}>View</IonBadge> : 
                    <IonBadge color="secondary" className={classes.viewBtn} onClick={()=>{GlobalService.viewImage(product.url ? product.url : '', product.name, product.name)}}>View</IonBadge>
                    }
                    <IonBadge  className={classes.scrollBtn} onClick={()=>{GlobalService.scrollToDivId("scrollView")}} >&#8595;</IonBadge>
                    {/* <IonButton  className={classes.undoBtn} onClick={()=>{saveableCanvas.undo();}}>Undo</IonButton>
                    <IonButton  className={classes.undoBtn} onClick={()=>{saveableCanvas.clear(); setBgColor(undefined)}}>Clear</IonButton> */}
                </Grid> : ''}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CardContent sx={{ p: 1, pb: 5+'px !important' }}>
                {product.sizes && product.sizes.length > 0 ? <div>
                      <div>
                      {product.colors && product.colors.length > 0 ? product.colors.map((item:Color,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.color = item;
                            var canvas:any = document.getElementById("myCanvas");
                            if(canvas && item.poseX && item.poseY)
                            {
                              var c:any = canvas;
                              var ctx = c.getContext('2d');
                              ctx.drawImage(image.image, 0, 0, image.width ? image.width : '300', image.height ? image.height : '300');
                              var ratioX = 1;
                              var ratioY = 1;
                              if(image.width && item.imageWidth && image.height && item.imageHeight){
                                ratioX = image.width/item.imageWidth;
                                ratioY = image.height/item.imageHeight;
                                
                                
                              }
                              var centerX = item.poseX ? item.poseX*ratioX : 0;
                              var centerY = item.poseY ? item.poseY*ratioY : 0;
                              
                              var radius = 90;
                              //draw tick
                              if(imageWidth<400){
                                ctx.beginPath();
                                ctx.moveTo(centerX,centerY);
                                ctx.lineTo(centerX+20,centerY-15);
                                ctx.moveTo(centerX+1,centerY+2);
                                ctx.lineTo(centerX-10,centerY-10);
                                ctx.lineWidth = 5;
                              }else{
                                  ctx.beginPath();
                                  ctx.moveTo(centerX,centerY);
                                  ctx.lineTo(centerX+40,centerY-30);
                                  ctx.moveTo(centerX+3,centerY+4);
                                  ctx.lineTo(centerX-20,centerY-20);
                                  ctx.lineWidth = 10;
                              }
                              if(item.code){
                                ctx.strokeStyle = GlobalService.wc_hex_is_light(item.code) ? '#4A6572' : '#F9AA33';
                              }
                              ctx.stroke();    
                            }
                            setProduct({...product});
                          }} className={orientation === 'portrait' ? classes.tabColor : classes.circleColor} style={{border: product.color && product.color.name === item.name ? '2px solid var(--ion-color-primary)' : '2px solid var(--ion-color-secondary)', marginRight: '5px', background: item.code, color: item.code && GlobalService.wc_hex_is_light(item.code) ? '#000000' : '#ffffff'}} key={item.id}>{item.code === product.color?.code ? <CheckIcon /> : item.id}</Button>
                        )
                      }): ''}
                      </div>
                      {product.sizes && product.sizes.length > 0 ? product.sizes.map((item:Size,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.size = item;
                            setProduct({...product});
                          }} color="secondary" className={classes.rectangleSizeMob} style={{marginRight: '5px', border: product.size && product.size.name === item.name ? '2px solid var(--ion-color-primary)' : '2px solid gray',  color: product.size && product.size.name === item.name ? 'var(--ion-color-primary)' : 'gray'}} key={item.name}>{item.name} | {item.quantity} Pcs</Button>
                        )
                      }): ''}
                      </div> : ''}
                      <div style={{marginBottom: '10px'}}>
                     {auth?.user?.role === 'Customer' ? <CustomButton width="100%" onClick={addToCart} title="ADD TO ORDER" /> : ''}
                    </div>
                  {brands && brands.length > 0 ? <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
                    {brand && brand.name ? <BrandComponent titleSize="28px" subTitleSize="21px" logoSize="70px" height="80px" show={true} size1={2} size2={10} size1m={3} size2m={9} size1sm={4} size2sm={8} key={'brand'+product.key} brand={brand} /> : 
                    <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
                  </Typography> : ''}
                    <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} gutterBottom variant="h6" component="div">
                        {product.name} {product.code ? ' | '+product.code : ''}
                    </Typography>
                    <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1">
                        {product.category1} {product.category2 ? ' -> '+product.category2 : ''} 
                    </Typography>
                    {auth?.user?.role === 'Customer' ?<Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle1" color="danger">
                      MRP: &#8377; {product.mrp} | WSP: &#8377; {product.wsp}
                    </Typography> : ''}
                    {auth?.user?.role === 'Customer' ?<Typography variant="subtitle1" color="text.secondary" id="productDescription">
                      {product.description ? product.description : ''}
                    </Typography> : '' }
                      {product.patterns && product.patterns.length > 0 ? <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
                      {product.patterns && product.patterns.length > 0 ? product.patterns.map((item:string,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.pattern = item;
                            setProduct({...product});
                          }} className={classes.pattern} style={{marginRight: '5px', backgroundColor: product.pattern && product.pattern === item ? 'var(--ion-color-secondary)' : 'gray'}} key={item}>{item}</Button>
                        )
                      }): ''}
                    </Typography> : ''}
                    <div id="scrollView"></div>
                    {/* <div style={{width: '250px', marginTop: '20px'}}>
                      <CustomTextInput value={product.quantity} type="number" label="Quantity" onchange={(e)=>{
                        if(e.target.value && e.target.value < 0){
                          product.quantity = 0;
                        }else{
                          product.quantity = e.target.value;
                        }
                        setProduct({...product})}} />
                    </div> */}
                  </CardContent>
                </Grid>
              </Grid> : 
                <Grid container>
                {image.url ? <Grid item lg={6} md={6} sm={12} xs={12} id="canvas_view">
                <canvas id="myCanvas" className={classes.canvasDraw} width={image.width ? image.width : '300'} height={image.height ? image.height : '300'}></canvas>
                  <br></br>
                    <IonButton  className={classes.undoBtn} onClick={()=>{setImageView(true)}}>View</IonButton>
                    {/* <IonButton  className={classes.undoBtn} onClick={()=>{saveableCanvas.undo();}}>Undo</IonButton>
                    <IonButton  className={classes.undoBtn} onClick={()=>{saveableCanvas.clear(); setBgColor(undefined)}}>Clear</IonButton> */}
                </Grid> : ''}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                <CardContent>
                  {brands && brands.length > 0 ? <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
                    {brand && brand.name ? <BrandComponent titleSize="28px" subTitleSize="21px" logoSize="70px" height="80px" show={true} size1={2} size2={10} size1m={3} size2m={9} size1sm={4} size2sm={8} key={'brand'+product.key} brand={brand} /> : 
                    <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
                  </Typography> : ''}
                    <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} gutterBottom variant="h5" component="div">
                        {product.name} {product.code ? ' | '+product.code : ''}
                    </Typography>
                    <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="h5">
                        {product.category1} {product.category2 ? ' -> '+product.category2 : ''} {product.category3 ? ' -> '+product.category3 : ''}
                    </Typography>
                    {auth?.user?.role === 'Customer' ?<Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h6" color="danger">
                      MRP: &#8377; {product.mrp} | WSP: &#8377; {product.wsp}
                    </Typography> : ''}
                    {auth?.user?.role === 'Customer' ?<Typography variant="subtitle1" color="text.secondary">
                      {product.description ? product.description : ''}
                    </Typography> : ''}
                    {product.sizes && product.sizes.length > 0 ? <Typography style={{textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
                      {product.sizes && product.sizes.length > 0 ? product.sizes.map((item:Size,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.size = item;
                            setProduct({...product});
                          }} color="secondary" className={classes.rectangleSize} style={{marginRight: '5px', border: product.size && product.size.name === item.name ? '2px solid var(--ion-color-primary)' : '2px solid gray',  color: product.size && product.size.name === item.name ? 'var(--ion-color-primary)' : 'gray'}} key={item.name}>{item.name} | {item.quantity} Pcs</Button>
                        )
                      }): ''}
                      </Typography> : ''}
                      <Typography style={{textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.primary">
                      {product.colors && product.colors.length > 0 ? product.colors.map((item:Color,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.color = item;
                            var canvas:any = document.getElementById("myCanvas");
                            if(canvas && item.poseX && item.poseY)
                            {
                              var c:any = canvas;
                              var ctx = c.getContext('2d');
                              ctx.drawImage(image.image, 0, 0, image.width ? image.width : '300', image.height ? image.height : '300');
                              var ratioX = 1;
                              var ratioY = 1;
                              if(image.width && item.imageWidth && image.height && item.imageHeight){
                                ratioX = image.width/item.imageWidth;
                                ratioY = image.height/item.imageHeight;
                                
                                
                              }
                              var centerX = item.poseX ? item.poseX*ratioX : 0;
                              var centerY = item.poseY ? item.poseY*ratioY : 0;
                              
                              var radius = 90;
                              //draw tick
                              if(imageWidth<400){
                                ctx.beginPath();
                                ctx.moveTo(centerX,centerY);
                                ctx.lineTo(centerX+20,centerY-15);
                                ctx.moveTo(centerX+1,centerY+2);
                                ctx.lineTo(centerX-10,centerY-10);
                                ctx.lineWidth = 5;
                              }else{
                                  ctx.beginPath();
                                  ctx.moveTo(centerX,centerY);
                                  ctx.lineTo(centerX+40,centerY-30);
                                  ctx.moveTo(centerX+3,centerY+4);
                                  ctx.lineTo(centerX-20,centerY-20);
                                  ctx.lineWidth = 10;
                              }
                              if(item.code){
                                ctx.strokeStyle = GlobalService.wc_hex_is_light(item.code) ? '#4A6572' : '#F9AA33';
                              }
                              ctx.stroke();    
                            }
                            setProduct({...product});
                          }} className={classes.circleColor} style={{border: product.color && product.color.name === item.name ? '2px solid var(--ion-color-primary)' : '2px solid var(--ion-color-secondary)', marginRight: '5px', background: item.code, color: item.code && GlobalService.wc_hex_is_light(item.code) ? '#000000' : '#ffffff'}} key={item.id}>{item.code === product.color?.code ? <CheckIcon /> : item.id}</Button>
                        )
                      }): ''}
                      </Typography>
                      {product.patterns && product.patterns.length > 0 ? <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
                      {product.patterns && product.patterns.length > 0 ? product.patterns.map((item:string,index:number)=>{
                        return (
                          <Button onClick={()=>{
                            product.pattern = item;
                            setProduct({...product});
                          }} className={classes.pattern} style={{marginRight: '5px', backgroundColor: product.pattern && product.pattern === item ? 'var(--ion-color-secondary)' : 'gray'}} key={item}>{item}</Button>
                        )
                      }): ''}
                    </Typography> : ''}
                    {/* <div style={{width: '250px', marginTop: '20px'}}>
                      <CustomTextInput value={product.quantity} type="number" label="Quantity" onchange={(e)=>{
                        if(e.target.value && e.target.value < 0){
                          product.quantity = 0;
                        }else{
                          product.quantity = e.target.value;
                        }
                        setProduct({...product})}} />
                    </div> */}
                    <div>
                     {auth?.user?.role === 'Customer' ? <CustomButton onClick={addToCart} title="ADD TO ORDER" /> : ''}
                    </div>
                  </CardContent>
                </Grid>
              </Grid>
              }
          </Grid> : <IonLabel>Product Not Found</IonLabel>}
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  large: {
    width: 'auto',
    height: '400px',
    maxHeight: '500px',
    maxWidth: '600px',
    objectFit: 'scale-down',
  },
  circleSize: {
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    padding: '16px',
    background: '#fff',
    textAlign: 'center',
    marginBottom: '10px',
    font: '18px Arial, sans-serif',
  },
  rectangleSize: {
    height: '40px',
    padding: '16px',
    background: '#fff',
    textAlign: 'center',
    marginBottom: '10px',
    font: '14px Arial, sans-serif',
  },
  rectangleSizeMob: {
    height: '40px',
    padding: '4px',
    background: '#fff',
    textAlign: 'center',
    marginBottom: '10px',
    font: '14px Arial, sans-serif',
  },
  circleColor: {
    borderRadius: '50%',
    marginBottom: '10px',
    width: '60px',
    height: '60px',
    padding: '16px',
    textAlign: 'center',
    font: '18px Arial, sans-serif',
  },
  tabColor: {
    marginBottom: '10px',
    width: '40px',
    minWidth: '20px',
    height: '40px',
    padding: '8px',
    textAlign: 'center',
    font: '12px Arial, sans-serif',
  },
  undoBtn: {
    backgroundColor: 'var(--ion-color-primary)',
    color: 'var(--ion-color-primary-white)',
    margin: '10px',
    borderRadius: '10px',
  },
  viewBtn: {
    background: 'var(--ion-color-secondary) !important',
    color: 'var(--ion-color-primary-white)',
    position: 'absolute',
    top: '60px',
    left: '0px',
    borderRadius: '10px',
    margin: '10px',
    zIndex: 100,
  },
  scrollBtn: {
    background: 'var(--ion-color-secondary)',
    color: 'var(--ion-color-primary-white)',
    position: 'absolute',
    top: '60px',
    right: '10px',
    margin: '10px',
    borderRadius: '10px',
    fontSize: '16px',
    zIndex: 100,
  },
  pattern: {
    marginTop: '10px',
    marginBottom: '20px',
    marginRight: '10px',
    color: 'var(--ion-color-primary-white)',
    padding: '10px',
  },
  canvasDraw: {
    boxShadow:
        "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
      , 
        position: 'relative',
  }
}));

export default ProductPage;