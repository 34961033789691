import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';import 'firebase/auth';
import { Order } from './Order';
import { db } from '../../App';

interface Orders{
    orders?:Order[];
}

export function useOrderUserInit():Orders {
  const [orders, setOrders] = useState<Orders>({orders: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
    const subscriber = db.collection("orders").where("order.user.id","==",uid)
      .onSnapshot((querySnapshot) => {
          const data:Order[] = [];
          var i = 1;
          querySnapshot.forEach((doc) => {
              var o = doc.data().order;
              o.id = i;
              i++;
              data.push(o);
          });
          var sorted = data.slice(0);
          sorted.sort(function(a,b) {
                var x = a.date;
                var y = b.date;
                if(x && y){
                    return x > y ? -1 : x < y ? 1 : 0;
                }else{
                    return 0;
                }
            });
          const obj = { orders: sorted };
          setOrders(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
  }, []);

  return orders;
}
