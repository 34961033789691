import { Camera, CameraResultType } from '@capacitor/camera';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import {
    Capacitor, Plugins
  } from "@capacitor/core";
const { NativeMarket } = Plugins;

function GetNativeApi(){
    
}

export async function takePicture(width:number, height:number, quality:number) {
    const image = await Camera.getPhoto({
        quality: quality>0 ? quality : 100,
		// width: width>0 ? width : 1200,
		// height: height>0 ? height : 1200,
		preserveAspectRatio: true,
		correctOrientation: true,
		resultType: CameraResultType.Base64
      });
      var imageUrl = 'data:image/jpeg;base64,' + image.base64String;
      return await imageUrl;
}

export async function viewImage(url: string, title:string, share: boolean){
    return await PhotoViewer.show(url, title, {share: share});
}

export async function getAppVersion(){
    return await AppVersion.getVersionNumber();
}

export async function openPlayStore(){
    NativeMarket.openStoreListing({
        appId: 'com.pktbazaar',
      });
}

export default { 
    GetNativeApi,
    takePicture,
    getAppVersion,
    openPlayStore,
}