
export default { 
    getHeaderAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
            delay: 500,
        };
        return styles;
    },
    getHeaderCategoryAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
        };
        return styles;
    },
    getBannerAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
            delay: 200,
        };
        return styles;
    },
    getProductCardAnimation(){
        const styles = {
            from: { opacity: 0 },
            to: { opacity: 1 },
            delay: 500,
        };
        return styles;
    },
    getOrderAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
            delay: 200,
        };
        return styles;
    },
    getCategoryAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
            delay: 200,
        };
        return styles;
    },
    getBrandAnimation(){
        const styles = {
            from: { y: -100 },
            to: { y: 0 },
        };
        return styles;
    },
    
    getStoreFilterAnimation(){
        const styles = {
            from: { x: -100 },
            to: { x: 0 },
            delay: 500,
        };
        return styles;
    },

    getBrandFilterAnimation(){
        const styles = {
            from: { x: -100 },
            to: { x: 0 },
            delay: 500,
        };
        return styles;
    },
}