import { IonCard } from '@ionic/react';
import Grid from '@material-ui/core/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React from 'react';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';


const ProductCard = ({products}:any) => {
    var pSize:string = "";
    if(!pSize){
        const { size } = UseDeviceScreenSize();
        pSize = size;
    }
    return (
        <div>
                { products && products.length>3 && pSize ? 
                    <Grid container>
                         {
                          products.slice(0, pSize === 'xl' ? 4 : pSize === 'lg' ? 3 : pSize === 'md' ? 3 : pSize === 'sm' ? 2 : 2).map((item) =>{
                             if(item && item.url){
                                 return (
                                     <Grid key={item.key} item lg={3} md={4} sm={6} xs={6} >
                                       {/* <IonCard routerLink={"/storeProduct?key="+item.key+"&id="+item?.user?.id} style={{marginLeft: 'auto', marginRight:'auto', boxShadow: '0 6px 12px 0 rgba(0,0,0,0.2)', height: pSize === 'xl' ? '280px' : pSize === 'lg' ? '250px' : pSize === 'md' ? '250px' : pSize === 'sm' ? '200px' : '150px', width: pSize === 'xl' ? '280px' : pSize === 'lg' ? '250px' : pSize === 'md' ? '250px' : pSize === 'sm' ? '200px' : '150px'}}> */}
                                       <IonCard routerLink={"/store?brand="+item.brand} style={{marginLeft: 'auto', marginRight:'auto', boxShadow: '0 6px 12px 0 rgba(0,0,0,0.2)', height: pSize === 'xl' ? '280px' : pSize === 'lg' ? '250px' : pSize === 'md' ? '250px' : pSize === 'sm' ? '200px' : '150px', width: pSize === 'xl' ? '280px' : pSize === 'lg' ? '250px' : pSize === 'md' ? '250px' : pSize === 'sm' ? '200px' : '150px'}}>
                                           <CardMedia
                                             component="img"
                                             image={item.thumbnail ? item.thumbnail : item.url}
                                             alt={item.name}
                                             style={{height: pSize === 'xl' ? '240px' : pSize === 'lg' ? '210px' : pSize === 'md' ? '200px' : pSize === 'sm' ? '160px' : '115px', width: '100%'}}
                                           />
                                           <CardContent sx={{ p: 1, pb: 5+'px !important' }}>
                                             <Typography gutterBottom variant="subtitle2" component="div">
                                               Article No. {item.code}
                                             </Typography>
                                           </CardContent>
                                       </IonCard>
                                     </Grid>
                                 )
                             }
                           }) 
                         }
                    </Grid> : ''
                }
        </div>
    );
};

export default ProductCard;