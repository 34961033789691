import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import GlobalService from '../../services/GlobalService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IonBadge, IonLabel } from '@ionic/react';
import { Grid } from '@material-ui/core';
import CustomButton from '../../components/common/CustomButton'
import CanvasDraw from "react-canvas-draw";
import BrandComponent from '../../components/common/BrandComponent';
import settings from '../../config/settings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    }
  }),
);

export default function CartProductCard({product, quantityUpdate, removeItem, brands, imageView}:any) {
  const classes = useStyles();
  const [ saveableCanvas, setSaveableCanvas ] = useState<any>();
  var brand:any = brands.find((e) => e.name === product.brand)
  var quantity:number = 0;
  return (
    <div className={classes.root}>
      <Card sx={{ width: '100%' }}>
       <CardContent>
            <Grid container spacing={1}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                <a onClick={()=>{GlobalService.viewCanvas(product, 600, 600)}}>
                  <img src={product.thumbnail ? product.thumbnail : product.url ? product.url : settings.imageUploadPlaceholder} style={{width: '200px', height: '200px', objectFit: 'cover'}}></img>
                </a>
                </Grid>
                <Grid item lg={7} md={5} sm={6} xs={12}>
                    <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  height: "50px" }} variant="h5">
                      {brand && brand.name ? <BrandComponent titleSize="21px" subTitleSize="16px" logoSize="50px" height="50px" show={true} size1={2} size2={10} size1m={3} size2m={9} size1sm={4} size2sm={8} key={'brand'+product.key} brand={brand} /> : 
                      <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
                    </Typography>
                    <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} gutterBottom variant="h5" component="div">
                        {product.name} {product.code ? ' | '+product.code : ''}
                    </Typography>
                    <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="h6">
                        {product.category1} {product.category2 ? ' -> '+product.category2 : ''} {product.category3 ? ' -> '+product.category3 : ''}
                    </Typography>
                    <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h6" color="danger">
                      MRP: &#8377; {product.mrp} | WSP: &#8377; {product.wsp}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {product.description ? product.description : ''}
                    </Typography>
                    <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
                        {product.size ?
                          <IonBadge color="secondary" style={{marginRight: '5px', fontSize: '18px'}} key={product.size.name}>Size: {product.size.name} | {product.size.quantity} Pcs.</IonBadge>
                      : ''}
                        {product.color ? 
                        
                            <IonBadge color="secondary" style={{marginRight: '5px', backgroundColor: product.color.code, color: GlobalService.wc_hex_is_light(product.color.code) ? '#000000' : '#ffffff', fontSize: '18px'}} >Color: {product.color.id}</IonBadge>
                        
                        : ''}
                        {product.patterns && product.patterns.length > 0 ? 
                    
                            <IonBadge color="secondary" style={{marginRight: '5px', fontSize: '18px'}}>{product.pattern}</IonBadge>
                        
                        : ''}
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} sm={12} xs={12}>
                    {removeItem ? <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '10px', textAlign: 'right' }}>
                        <CustomButton onClick={()=>{removeItem(product)}} icon="close" title="" color="light" iconSize={12} />
                    </div> : ''}
                    {/* <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '10px', maxWidth: '90px', textAlign: 'right' }}>
                        <CustomTextInput onchange={(event)=>{quantityUpdate(product, event)}} type="number" value={product.quantity} label="Quantity" name="Quantity" />
                    </div>     */}
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '10px', textAlign: 'right' }}>
                        <IonBadge color="warning" style={{fontSize: '16px'}}>Approx Qty: {product.size && product.size.quantity && product.size.quantity > 1 ? product.quantity+' Set | '+(product.quantity * product.size.quantity)+' Pcs.' : product.quantity+' Pcs.'} </IonBadge>
                    </div> 
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '10px', textAlign: 'right' }}>
                        <IonBadge color="warning" style={{fontSize: '16px'}}>{product.size && product.size.quantity && product.size.quantity>1  ? (product.quantity * product.size.quantity)+' Pcs.': product.quantity+' Pcs.'} X &#8377; {product.wsp} = &#8377; {product.size && product.size.quantity ? product.quantity*product.size.quantity*product.wsp : product.quantity*product.wsp}</IonBadge>
                    </div> 
                    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: '10px', textAlign: 'right' }}>
                        <IonBadge color="warning" style={{fontSize: '16px'}}>(Approx Amount + GST)</IonBadge>
                    </div>     
                    
                </Grid>
            </Grid> 
        </CardContent>
      </Card>
    </div>
  );
}