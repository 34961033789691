import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    topHeader: {
        marginTop: '60px',  
        paddingBottom: '20px' , 
        height: '100%', 
        overflow: 'scroll', 
        width: '-webkit-fill-available'
    },
    centerText:{
        textAlign: 'center',
    }
  }));