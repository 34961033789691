import GlobalService from "../services/GlobalService";

export default {
    catcherror(error:any){
        
        let type = typeof error;
        switch(type){
            case "string" : {
                GlobalService.showtoast(error,error,"error",5000,undefined);
                break;
            }
            case "object" : {
                
                if(error.message){
                    if(error.code){
                        switch(error.code){
                            case "auth/user-not-found":{
                                GlobalService.showtoast("usernotfound","Email is not registred with us. Please sign up for new account","error",5000,undefined);
                                break;
                            }
                            case "auth/wrong-password":{
                                GlobalService.showtoast("userpasswordnotcorrect","Email or password is incorrect.","error",5000,undefined);
                                break;
                            }
                            case "permission-denied":{
                                GlobalService.showtoast("userpermissiondenied","You are not permitted to use this operation.","error",5000,undefined);
                                break;
                            }
                            case 'storage/object-not-found':{
                                break;
                            }
                            case 'storage/unauthorized':{
                                GlobalService.showtoast("userpermissiondenied","You are not permitted to use this operation.","error",5000,undefined);
                                break;
                            }    
                            default: {
                                GlobalService.showtoast(error.message,error.message,"error",5000,undefined);
                            }
                        }
                    }else{
                        GlobalService.showtoast(error.message,error.message,"error",5000,undefined);
                    }
                }else{
                    GlobalService.showtoast(String(error),String(error),"error",5000,undefined);
                }
                break;
            }
            case "number" : {

                break;
            }
            case "boolean" : {

                break;
            }
        }
    },
}