import { IonBadge, IonButton, IonPage } from '@ionic/react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, WhereOptions } from 'react-redux-firebase';
import swal from 'sweetalert';
import CustomLoading from '../../components/loading/CustomLoading';
import SideMenuUser from '../../components/menu/SideMenuUser';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import GlobalService from '../../services/GlobalService';
import { Order } from './Order';
import { useOrderSupplierInit } from './OrderDataSupplier';
import OrderService from './OrderService';
import OrderViewCard from './OrderViewCard';

interface OrderType{
  key: string,
  order: Order,
}

const OrderListPageSupplier: React.FC = () => {
  const classes = useStyles();
  const [orderList, setOrderList] = useState<Order[]>();
  const { auth } = UseAuthInit();
  const { size, orientation, width, height } = UseDeviceScreenSize();
  let landscape = height > 500;
  const[loading, setLoading] = useState<boolean>(false);

  const wp1:WhereOptions = ["order.supplier.id","==",auth?.user?.id ? auth?.user?.id : '0000'];
  const wp2:WhereOptions = ["order.status","in",["approved", "packed", "shipped", "delivered", "returned"]];
  useFirestoreConnect(
    [
      {
        collection: 'orders',
        storeAs: "supplierOrders",
        where: [wp1, wp2],
      },
    ]
  );

  const orders:Order[] = useSelector((state:any) => {
      var orderObj:any = state.firestore.data.supplierOrders;
      if(orderObj){
        var list:Order[] = [];
        for(var order of Object.values(orderObj)){
          var ord:any = order;
          list.push(ord.order)
        }
        return list;
      }else{
        return [];
      }
    }
  );


  const approveClick = (item:Order) => {
    if(!item.supplierStatus){
        swal({
            title: item.voucherNumber ? 'Voucher Number: '+item.voucherNumber : 'Alert',
            text: "Are you sure that you have shipped this order ?",
            icon: "warning",
            dangerMode: true,
            buttons: ["cancel","confirm"],
          })
          .then(willDelete => {
            if (willDelete) {
              item.supplierStatus = "shipped";
              let rs = OrderService.addOrderData(item);
              rs.then((response)=>{
                
                if(response){
                  // Update inventory for each product
                 swal('Shipped', "Order has been Shipped", "success");
                }else{
                  swal("Failed!", "Something is not correct!", "failed");
                }
              });
              
            }
          });
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
        field: 'timestamp',
        headerName: 'Date',
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          if(params.row.date){
            return <Tooltip title={GlobalService.getIndianDateFormatFromDate(params.row.date)}><p>{GlobalService.getIndianDateFormatFromDate(params.row.date)}</p></Tooltip>
          }
      },
    },
    {
      field: 'voucherNumber',
      headerName: 'Order Id',
      width: 150,
  }, 
    {
        field: 'total',
        headerName: 'Amount',
        width: 150,
    }, 
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
    },  
    {
      field: 'customer',
      headerName: 'Customer',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip title={params.row.user && params.row.user.userId ? params.row.user.userId : ''} ><p>{params.row.user && params.row.user.userId ? params.row.user.userId : ''}</p></Tooltip>
    },
  },  
    {
        field: 'view',
        headerName: 'View',
        width: 150,
        renderCell: (params: GridRenderCellParams) => {
            return <IonButton style={{margin: '0px', padding: '2px',}} routerLink={"/order?key="+params.row.key} >View</IonButton>
        },
    },
    {
        field: 'shipped',
        headerName: 'Shipped',
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
            return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={()=>{approveClick(params.row)}}>{params.row.supplierStatus ? params.row.supplierStatus : 'Mark As Shipped'}</Button>
        },
    },
    {
      field: 'pdf',
      headerName: 'Order Form', 
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          //  navigate("/orderPdf?key="+params.row.key,"forward","push");
          OrderService.printPdf(params.row);
        };
        return <Button style={{margin: '0px', padding: '2px',}} variant="contained" className={landscape ? classes.submit : classes.submit2} onClick={onClick}>Order Form</Button>;
      }
    },
  ]

  return (
    <IonPage>
      {orders || orderList ? '' : <CustomLoading />}
      {loading ? <CustomLoading /> : ''}
         <SideMenuUser title="Orders" backButton={auth?.user?.role === 'Customer' ? true : false} /> 
          <div className={classes.main} >
            {orientation === 'portrait' ? <Grid container justify="flex-start">
                  {orderList && orderList.length>0 ? orderList.map((item,index)=>{
                    return (
                      <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={"ordercard"+index}>
                        <OrderViewCard order={item} approveClick={()=>{approveClick(item)}} viewOrderForm={()=>{OrderService.printPdf(item)}} />
                      </Grid>
                    )
                  }) : orders && orders.length > 0 ? 
                    orders.map((item,index)=>{
                    return (
                      <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={"ordercard"+index}>
                        <OrderViewCard order={item} approveClick={()=>{approveClick(item)}} viewOrderForm={()=>{OrderService.printPdf(item)}} />
                      </Grid>
                    )
                  })
                  : <IonBadge color="primary">No records available</IonBadge>}
            </Grid> : 
            <Grid container justify="space-evenly">
            <div style={{ height: height * 88 / 100, width: '100%' }}>
                  {orderList && orderList.length>0 ? <DataGrid
                    rows={orderList}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  /> : orders && orders.length > 0 ? 
                  <DataGrid
                    rows={orders}
                    columns={columns}
                    pageSize={100}
                    density = 'compact'
                    rowsPerPageOptions={[50]}
                    disableSelectionOnClick
                  />
                  : <IonBadge color="primary">No records available</IonBadge>}
                </div>
            </Grid>
            }
          </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: { 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  submit: {
    color: 'var(--ion-text-color-highlight)',
    backgroundColor: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  submit2: {
      color: 'var(--ion-text-color-highlight)',
      backgroundColor: 'var(--ion-color-primary)',
      fontWeight: 'bold',
  },
}));

export default OrderListPageSupplier;