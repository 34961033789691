import { db } from "../App";
import NativeApi from "./NativeApi";
import swal from 'sweetalert';
import {
    Capacitor, Plugins
  } from "@capacitor/core";
const { NativeMarket } = Plugins;

interface AppVersion{
    id: number,
    type: string,
}

const CheckAppVersion = () => {
    setTimeout(async ()=>{
        var appVersion = await GetAppVersion();
        NativeApi.getAppVersion().then((ver)=>{
          if(appVersion.id > parseFloat(ver)){
            swal({
              title: "Update Available",
              text: "New update available ?",
              icon: "warning",
              dangerMode: true,
              buttons: [appVersion.type === "f" ? "Exit App" : "Cancel","Update"],
            })
            .then((willDelete:any) => {
              if (willDelete) {
                NativeMarket.openStoreListing({
                  appId: 'com.pktbazaar',
                });
              }else if(appVersion.type === "f"){
                Plugins.App.exitApp();
              }
            });
          }
        });
      },5000)
}

async function GetAppVersion() { 
    var data:AppVersion = {id: 0, type: 'r'};
    await db.collection("version").doc("version")
    .get().then((querySnapshot) => {
        if(querySnapshot.exists){
            var obj = querySnapshot.data();
            if(obj && obj.id && obj.type){
                data.id = obj.id;
                data.type = obj.type;
            }
        }
    });
    return data;
}

export default CheckAppVersion;