import { IonItem, IonList, IonPage } from '@ionic/react';
import { Card, CardActionArea, CardContent, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSpring } from 'react-spring';
import BrandComponent from '../../components/common/BrandComponent';
import SideMenuUser from '../../components/menu/SideMenuUser';
import AnimationService from '../../services/AnimationService';
import BrandCard from './BrandCard';

const BrandListPage: React.FC = () => {
  const brandData = useSelector((state:any) => 
        state.firestore.data.brands && state.firestore.data.brands.brand && state.firestore.data.brands.brand.brand ? state.firestore.data.brands.brand.brand : []
  );
  useFirestoreConnect(
    [
      {
          collection: 'brand',
          storeAs: "brands",
      },
    ]
  );
  
  // const headerStyle = useSpring(
  //   AnimationService.getBrandFilterAnimation()
  // )
  
  return (
    <IonPage>
         <SideMenuUser title="Brands" /> 
            <Grid container justify="space-evenly" style={{overflow: 'auto', marginTop: '60px'}}>
            <IonList>
                {brandData && Object.values(brandData).length>0 ? Object.values(brandData).map((item:any, index:number)=>{
                  return (
                    <IonItem key={"brands"+item.name} routerLink={"/store?brand="+encodeURI(item.name).replace("&", "11nd")}>
                      <BrandCard item={item} duration={index*100} />
                    </IonItem>
                  )
                }) : ''}
                </IonList>
            </Grid>  
    </IonPage>
) ;
};

export default BrandListPage;