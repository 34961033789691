import React from 'react'
import PropTypes from 'prop-types'
import './LoadingStyle.css'
import settings from '../../config/settings'

function CustomLoading() {
    return (
        <div id="overlay" style={{zIndex: 10000}}>
            {/* <div className="spin"></div> */}
            <div id="bgimg">
                <img src={settings.loadingIcon} width={250} />
            </div>
        </div>
    )
}

CustomLoading.defaultProps = {

}

export default CustomLoading

