import { IonBadge, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, NavContext } from '@ionic/react';
import firebase from 'firebase/app';
import 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, WhereOptions } from 'react-redux-firebase';
import { animated, useSpring } from 'react-spring';
import ProductCard from '../../components/home/ProductCard';
import SideMenuUser from '../../components/menu/SideMenuUser';
import BannerSlideComponent from '../../components/slide/BannerSlideComponent';
import BrandSlideComponent from '../../components/slide/BrandSlideComponent';
import CategorySlideComponent from '../../components/slide/CategorySlideComponent';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import AnimationService from '../../services/AnimationService';
import GlobalService from '../../services/GlobalService';
import RegisterToken from '../../services/RegisterToken';
import { Product } from '../product/Product';

interface UserProducts{
  id?: number,
  products?:Product[];
}

const HomePage: React.FC = (props) => {
  const [data, setData] = useState<UserProducts[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  // const { navigate } = useContext(NavContext);
  const { size, orientation, width, height } = UseDeviceScreenSize();
  const { auth } = UseAuthInit();

  const wp:WhereOptions = ["status","==","active"];

  useFirestoreConnect(
    [
      {
        collection: 'product',
        storeAs: "usersproducts",
        where: wp,
      },
    ]
  );
  
  const usersData = useSelector((state:any) => 
    state.firestore.data.usersproducts
  );

  const getProducts = (start:number, end:number) =>{
    if(Object.values(usersData) && Object.values(usersData).length > start){
      var list:UserProducts[] = Object.values(usersData);
      if(list.length>end){
        return list.slice(start,end);
      }else{
        return list.slice(start, (list.length));
      }
    }else{
      return [];
    }
  }

  const pushData = () => {
    const max = data.length + settings.maxHomeScreenUser;
    const min = max - settings.maxHomeScreenUser;
    const newData:UserProducts[] = getProducts(min,max);
    
    setData(data.concat(newData));
  }
  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      ev.target.complete();
      if (data.length === Object.values(usersData)?.length) {
        setInfiniteDisabled(true);
      }
    }, 500);
  }  
  
  if(data.length<1 && usersData && Object.values(usersData) && Object.values(usersData).length> 0){
    pushData();
  }

const handleClick = (item:any)=>{
  // navigate("/store","forward","push");
  // navigate("/storeProduct?key="+item.key+"&id="+item.user?.id,"forward","push");
}

useEffect(()=>{
  var res = firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
    RegisterToken.register(firebaseUser.uid, auth?.user?.role ? auth?.user?.role : 'Customer');
  })
  return res;
});

const headerStyle = useSpring(
  AnimationService.getHeaderAnimation()
)

  return (
    <IonPage>
    {/* <animated.div style={{...headerStyle}} > */}
      <SideMenuUser title="Home" />
    {/* </animated.div> */}
     <div style={{marginTop: '60px',  paddingBottom: '20px', height: '100%', width: '-webkit-fill-available'}} >
          <IonContent>
             {orientation === 'portrait' ? <CategorySlideComponent /> : ''}
             <BannerSlideComponent />
             <BrandSlideComponent />
             {
               data && data.length>0 ? data.map((user:any) =>{
                 var exist:boolean = false;
                 if(user.products && user.products.length>0 && auth && auth.user && auth.user.role === 'Supplier' && auth.user.categories && auth.user.categories.length > 0){
                   for(var category of auth.user.categories){
                     if(user.products[0].category2 === category.sub){
                       exist = true;
                       break;
                     }
                   }
                 }
                 if(!exist && user && user.products && user.products.length>3){
                     return (
                       <div key={'prodsList'+user.products[0].key}>
                          <IonBadge color="primary" style={{fontSize: size === 'xl' ? '16px' : size === 'lg' ? '14px' : size === 'md' ? '12px' : size === 'sm' ? '10px' : '10px', margin: '10px'}}>{user.products && user.products.length>0 ? user.products[0].brand : ''}</IonBadge> 
                          <ProductCard products={user.products}/>
                       </div>
                     )
                 }
               }) : ''
             }
             <div id="bottomHome">
             <IonInfiniteScroll
              onIonInfinite={loadData}
              threshold="100px"
              disabled={isInfiniteDisabled}
            >
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
            </div>
         </IonContent>
     </div>
</IonPage>
) ;
};
 
export default React.memo(HomePage);