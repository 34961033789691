import firebase from 'firebase/app'; import 'firebase/auth';
import { peopleCircle } from 'ionicons/icons';
import { db } from '../../App';
import CommonErrorHandlar from "../../services/CommonErrorHandlar";
import GlobalService from '../../services/GlobalService';
import { Product } from '../product/Product';
import { ProductCount } from './ProductCount';
import { User } from './User';
import { UserCart } from './UserCart';
import { UserToken } from './UserToken';

function GetUser(){
    
}

export async function getUserCart(uid:string){
    var cart: UserCart = {};
    
    await db.collection("carts").doc(uid)
    .get()
    .then((doc) =>{
        var p:any = doc.data();
        if(p && p.cart){
            cart = p.cart;
        }
    }).catch((err)=>{
        CommonErrorHandlar.catcherror(err);
    });
    return cart;
}

export async function addUserCart(uid:string, cart:UserCart){
    var response: boolean = false;
    await db.collection("carts").doc(uid)
    .set({
        cart
    })
    .then(() => {
        
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}

export async function getProductView(uid:string){
    var products: ProductCount[] = [];
    
    await db.collection("productview").doc(uid)
    .get()
    .then((doc) =>{
        var p:any = doc.data();
        
        if(p && p.products){
            products = p.products;
        }
    }).catch((err)=>{
       CommonErrorHandlar.catcherror(err);
    });
    return products;
}

export function addProductView(product:Product){
    firebase.auth().onAuthStateChanged(async (firebaseUser: any) => {
    var products:ProductCount[] = await getProductView(firebaseUser.uid);
    var exist:boolean = false;
    if(products.length > 0){
        for(var i = 0; i<products.length; i++){
            if(products[i].key === product.key){
                products[i].count = products[i].count + 1;
                products[i].lastView = await GlobalService.getLiveDate();
                exist = true;
            }
        }
        if(!exist){
            var pc:ProductCount = {key: product.key, name: product.name, category: product.categoryFullName ? product.categoryFullName : '', count: 1, lastView: await GlobalService.getLiveDate()}
            products.push(pc);
        }
    }else{
        var pc:ProductCount = {key: product.key, name: product.name, category: product.categoryFullName ? product.categoryFullName : '', count: 1, lastView: await GlobalService.getLiveDate()}
        products.push(pc);
    }
    await db.collection("productview").doc(firebaseUser.uid)
    .set({
        products
    })
    .then(() => {
        
    })
    .catch((error) => {
       
    });
    });
}

export async function getUserAuth(){
    const res = firebase.auth().onAuthStateChanged((firebaseUser:any) => {
        
        if(firebaseUser){
            db.collection("users").doc(firebaseUser.uid)
            .onSnapshot(async (doc) => { 
              
                var data = await doc.data();
                if(data && data.user){
                  let user: User = {
                    role: data.user.role,
                    status: 'inactive',
                    name: data.user.name ? data.user.name : "",
                    email: data.user.email ? data.user.email : "",
                    number: data.user.number ? data.user.number : "",
                    gst: data.user.gst,
                    firmName: data.user.firmName ? data.user.firmName : "",
                    id: firebaseUser.uid,
                    categories: data.user.categories,
                    address: data.user.address,
                  }
                  
                  db.collection("users").doc(firebaseUser.uid).collection("organization").doc("private")
                  .onSnapshot(async (doc2) => {
                    
                    const data2 = await doc2.data();
                    if(data2 && data2.data){
                      user.status = data2.data.status;
                      user.role = data2.data.role;
                    }
                    const auth = firebaseUser
                    ? { loggedIn: true, status: user.status, role: user.role, userId: firebaseUser.uid, userData: firebaseUser, user: user }
                    : { loggedIn: false };
                    return auth;
                  });
                }
            })
        }else{
            return null;
        }
      });
}

export async function addUserToken(token: UserToken){
    var response: boolean = false;
    await db.collection("tokens").doc(token.uid)
    .set({
        token
    })
    .then(() => {
        
        response = true;
    })
    .catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return response;
}


export default { 
    getUserCart,
    addUserCart,
    addProductView,
    addUserToken,
}