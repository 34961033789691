import React from 'react'
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core';

function CustomFab({backgroundColor, color, onclick, bottom, right, left, top , icon, variant, label, style}:any) {
    const classes = useStyles();
    if(style){
        return (
            <Fab
                mainButtonStyles={{backgroundColor: backgroundColor, color: color}}
                style={style}
                icon={icon}
                event={'click'}
                alwaysShowTitle={true}
                onClick={onclick}
                aria-label={label}
                >
            </Fab>
        )
    }if(left){
        return (
            <Fab
                mainButtonStyles={{backgroundColor: backgroundColor, color: color}}
                style={{bottom: bottom, left: left, }}
                icon={icon}
                event={'click'}
                alwaysShowTitle={true}
                onClick={onclick}
                aria-label={label}
                >
            </Fab>
        )
    }else{
        return (
            <Fab
                mainButtonStyles={{backgroundColor: backgroundColor, color: color}}
                style={{bottom: bottom, right: right, }}
                icon={icon}
                event={'click'}
                alwaysShowTitle={true}
                onClick={onclick}
                aria-label={label}
                >
            </Fab>
        )
    }
    
}

CustomFab.defaultProps = {
    backgroundColor: 'var(--ion-color-primary)',
    color: 'var(--ion-color-primary-contrast)',
    bottom: 50,
    right: 0,
    left: 0,
    icon: <AddCircleIcon />,
}

const useStyles = makeStyles((theme) => ({
    fabStyles: {
        color: 'var(--ion-text-color)',
    },
}));

export default CustomFab
