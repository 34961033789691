import {
    Capacitor, Plugins
  } from "@capacitor/core";
  import '@fontsource/roboto';
  import swal from 'sweetalert';

const HardwareBackButton = () =>{
    if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e) => {
           if (window.location.pathname === "/") {
              // Show A Confirm Box For User to exit app or not
              swal({
                title: "Exit",
                text: "Are you sure that you want to exit ?",
                icon: "warning",
                dangerMode: true,
                buttons: ["cancel","confirm"],
              })
              .then(willDelete => {
                if (willDelete) {
                  Plugins.App.exitApp();
                }
              });
           } else if (window.location.pathname === "/home" || window.location.pathname === "/login"  || window.location.pathname === "/approval" || window.location.pathname === "/signIn") {
              // Show A Confirm Box For User to exit app or not
              swal({
                title: "Exit",
                text: "Are you sure that you want to exit ?",
                icon: "warning",
                dangerMode: true,
                buttons: ["cancel","confirm"],
              })
              .then(willDelete => {
                if (willDelete) {
                  Plugins.App.exitApp();
                }
              });
           }
        });
     }
}

export default HardwareBackButton;