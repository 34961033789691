import settings from "../config/settings";
import GlobalService from "./GlobalService";

export default {
    sendNotificationForSubscribe(){
        var postData:any = {};
        postData.function = "subscribe";
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
         
        })
    },
    sendNotificationForOrderPlaced(orderId:string){
        var postData:any = {};
        postData.function = "orderPlaced";
        postData.orderId = orderId;
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
          
        })
    },
    sendNotificationForProduct(productId:string){
        var postData:any = {};
        postData.function = "product";
        postData.productId = productId;
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
           
        })
    },
    sendNotificationForNewAccount(){
        console.log("noti");
        var postData:any = {};
        postData.function = "userAdd";
        GlobalService.sendPostRequestWithTokenJson(settings.serverUrl,postData).then((res)=>{
           console.log(res)
        })
    },
}