import UseDeviceScreenSize from "../hooks/useDeviceScreenSize";

function SlideOptions(){
    
}

export function GetBrandSlideOptions() {
    // const { size, orientation, width, height } = UseDeviceScreenSize();
    const slideOptsBrands = {
        initialSlide: 1,
        speed: 600,
        loop: false,
        slidesPerView: 5,
        autoplay: { delay: 7000,},
      };
      return slideOptsBrands;
}

export function GetBannerSlideOptions(){
    // const { size, orientation, width, height } = UseDeviceScreenSize();
    const slideOpts = {
        initialSlide: 1,
        speed: 400,
        loop: false,
        autoplay: { delay: 3000,},
      };
    return slideOpts;
}

export function GetCategorySlideOptions(){
    // const { size, orientation, width, height } = UseDeviceScreenSize();
    const slideOpts = {
        initialSlide: 1,
        speed: 600,
        loop: false,
        slidesPerView: 4,
        autoplay: { delay: 6000,},
      };
    return slideOpts;
}

export function GetScreen(){
    const { size, orientation, width, height } = UseDeviceScreenSize();
    const screenProps = {
        size: size,
        orientation: orientation,
        width: width,
        height: height
    }
    return screenProps;
}

export default {
    GetBrandSlideOptions,
    GetBannerSlideOptions,
    GetCategorySlideOptions,
    GetScreen,
}