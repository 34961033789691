import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Category } from './Category';
import { PrimaryCategory } from './PrimaryCategory';

interface Categories{
    categories?:PrimaryCategory[];
}

export function useCategoryInit():Categories {
  const [categories, setCategories] = useState<Categories>({categories: []}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  

    const subscriber = db.collection("category").doc("category")
        .get().then((querySnapshot) => {
            var data:PrimaryCategory[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                if(obj && obj.category){
                var cts:PrimaryCategory[] = obj.category;    
                var sorted = cts.slice(0);
                    sorted.sort(function(a,b) {
                        var x = a.name;
                        var y = b.name;
                        if(x && y){
                            return x < y ? -1 : x > y ? 1 : 0;
                        }else{
                            return 0;
                        }
                    });
                data = sorted;
                }else{
                    data = [];
                }
            }
            const ct = { categories: data };
            setCategories({categories: []});
            setTimeout(function () {
                setCategories(ct);
            }, 200);
        });
    
    // const subscriber = db.collection("category").get()
    // .then((querySnapshot) => {
    //     var data:PrimaryCategory[] = [];
    //     querySnapshot.forEach((doc) => {
    //         var data:PrimaryCategory[] = doc.data().category;
    //     });
    //     var sorted = data.slice(0);
    //     sorted.sort(function(a,b) {
    //         var x = a.name;
    //         var y = b.name;
    //         if(x && y){
    //             return x < y ? -1 : x > y ? 1 : 0;
    //         }else{
    //             return 0;
    //         }
    //     });
    //     const obj = { categories: data };
    //     setCategories({categories: []});
    //     setTimeout(function () {
    //         setCategories(obj);
    //     }, 200);
    // });

    // Unsubscribe from events when no longer in use
    return;
  }, []);

  return categories;
}
