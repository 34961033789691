import React, { useContext, useEffect, useState } from 'react';
import '../../config/InvoiceStyle.css';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { useOrderKeyInit } from './OrderDataById';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import settings from '../../config/settings';
import LanguageIcon from '@mui/icons-material/Language';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GlobalService from '../../services/GlobalService';

export default function OrderPdfForm() {
    const { auth } = UseAuthInit();
    let key = new URLSearchParams(window.location.search).get("key");
    const { size, orientation, width, height } = UseDeviceScreenSize();
    const[totalQuantity, setTotalQuantity] = useState<number>();
    const { order } = useOrderKeyInit(key ? key : '');  
    if(order && order.products && order.products.length>0 && !totalQuantity){
        var quantity = 0;
        for(var item of order.products){
            var qty:number = item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity ? item.quantity : 0;
            quantity = quantity + qty;
        }
        setTotalQuantity(quantity);
    }
  
  return (
    <div id="invoice-print" style={{marginTop: '20px'}}>  
    {order ? <div className="invoice-box" >
       <div className="watermark" > <img src={settings.logo} style={{position: 'absolute', top: '50%', left: '25%', marginTop: '-50px', marginLeft: '-50px', height: '300px', opacity:'0.1'}} /></div>
        <table cellPadding={0} cellSpacing={0}>
            <tr>
                <td colSpan={6}>
                    <table>
                        <tr>
                            <td className="title">
                                <p style={{textAlign: 'center', fontSize: '24px'}}>Order Form</p>
                            </td>
                        </tr>
                    </table>
                </td>    
            </tr>
            <tr className="top">
                <td colSpan={6}>
                    <table>
                        <tr>
                            <td className="title" style={{maxWidth: '110px'}}>
                                <img src={settings.logo} style={{width: '100%', maxWidth: '150px'}} />
                            </td>
                            <td style={{textAlign: 'left', marginLeft: '-20px'}}>
                                <b>
                               {settings.companyName} <br />
                               {/* {settings.companySubHeading} <br /> */}
                               <LocationOnIcon className="invoice-icon" /> {settings.companyAddress1}  <br />
                               {/* {settings.companyAddress2}  <br /> */}
                               {settings.companyAddress3}  <br />
                               <CallIcon className="invoice-icon" /> {settings.companyNumber}  | <WhatsAppIcon className="invoice-icon" /> {settings.whatsapp}  <br />
                               <EmailIcon className="invoice-icon" /> {settings.companyEmail} <br />
                               <LanguageIcon className="invoice-icon" /> {settings.website}
                               </b>
                            </td> 
                            <td>
                                <b>
                                Order No. #: {order.voucherNumber}<br />
                                Date: {GlobalService.getIndianDateFormatFromDate(order.date ? order.date : '')}<br />
                                Order Type: {order.type ? order.type : 'Regular'} 
                               </b>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr className="information">
                <td colSpan={8}>
                    <table>
                        <tr>
                            <td>
                                 <b>
                                Customer Firm: {order.user?.firmName}<br />
                                Customer Id: {order.user?.userId} <br />   
                                Brand: {order.category?.brand}<br />
                                Category: {order.category?.sub}<br /></b><br />
                            </td> 
                            

                            <td>
                                
                                Delivery Start Date:__________________<br />
                                Last Date of Delivery:_________________<br />
                            </td> 
                        </tr>
                    </table>
                </td>
            </tr>

            <tr className="heading">
                <td>SN.</td>
                <td>Article Number</td>
                <td>Color</td>
                <td>Size</td>
                <td>Quantity</td>
                <td>MRP/WSP</td>
            </tr>
            {order.products && order.products.length > 0 ? order.products.map((item,index) => {
                return (
                    <tr key={item.key+index} className="item">
                        <td>{index+1}</td>
                        <td>{item.code} | {item.name}</td>
                        <td>{item.color?.id}</td>
                        <td>{item.size?.name}</td>
                        <td>{item && item.size && item.size.quantity && item.quantity ? (parseInt(item.size.quantity+'')*item.quantity) : item.quantity} Pcs</td>
                        <td>&#8377; {item.mrp} / &#8377; {item.wsp} </td>
                    </tr>
                )
            }) : ''}
        </table>
        <div className="divFooter">
        {/* <div> */}
            <h3 style={{textAlign: 'right', paddingRight: '60px', fontSize: '12px'}}>
                <b>Total Approx Amount: &#8377; {order.total} + GST Applicable</b><br />
                <b>Total Approx Quantity: {totalQuantity ? totalQuantity : 0} Pcs.</b>
            </h3>
            <br />
            <p style={{fontSize: '10px'}}>No <b>goods return</b> of any <b>order</b> only goods return of mismatch article. <br />Delivery time within <b>4-5 days</b> of <b>urgent order</b> and within 1 week of <b>regular order</b>.<br />Regular order cancelled within <b>12 hours</b> by customer. <b>and not called of urgent order</b>.</p></div>
    </div> 
    : ''}
    </div>
  );
}