import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { confirmAlert } from 'react-confirm-alert';
import CommonErrorHandlar from "./CommonErrorHandlar";
import Config  from "../config/settings";
import firebase from "firebase/app";
import "firebase/auth";
import swal from 'sweetalert';
import { CustomDialog, useDialog } from "react-st-modal";
import ImageView from "../components/common/ImageView";
import { Product } from "../pages/product/Product";
import CanvasDraw from "react-canvas-draw";
import { ImageInterface } from "../interface/ImageInterface";
import { Grid, Typography } from "@material-ui/core";
import { IonBadge } from "@ionic/react";
import settings from "../config/settings";
import { Browser } from '@capacitor/browser';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer';
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { File } from '@awesome-cordova-plugins/file';

export default {

    convert2digitdecimalstring(value:string | number){
        return parseFloat(value+"").toFixed(2);
    },

    convert2digitdecimaldouble(value:string | number){
        return parseFloat(parseFloat(value+"").toFixed(2));
    },
    capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
    },
    capitalizeCamelCase(str: string) { 
        str = str.replace(/ +(?= )/g,'');
        if(str.indexOf(' ') >= 0){
            var arr = str.split(" ");
            var newstr = "";
            for(var s of arr){
                newstr = newstr + s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase() + " ";
            }
            newstr = newstr.slice(0, -1)
            if(newstr){
                return newstr;
            }else{
                return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
            }
        }else{
            return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
        }
    },
    confirmalert(title:string, message:string, label1:string, label2:string, event1:any, event2:any){
        confirmAlert({
            title: title,
            message: message,
            buttons: [
              {
                label: label1,
                onClick: () => event1
              },
              {
                label: label2,
                onClick: () => event2
              }
            ]
          });
    },

    showtoast(id:any,title:string,type:"info" | "warning" | "success" | "error" | "dark" | undefined,duration:number | undefined,position:"top-right" | "top-left" | "top-center" | "bottom-right" | "bottom-left" | "bottom-center" | undefined){
        switch(type){
            case "info": {
                toast.info(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                    style: {
                        zIndex: 100000,
                    }
                });
                break;
            }
            case "success": {
                toast.success(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                    style: {
                        zIndex: 100000,
                    }
                });
                break;
            }
            case "warning": {
                toast.warning(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                });
                break;
            }
            case "error": {
                toast.error(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                    style: {
                        zIndex: 100000,
                    }
                });
                break;
            }
            case "dark": {
                toast.dark(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                    style: {
                        zIndex: 100000,
                    }
                });
                break;
            }
            default: {
                toast(title,{
                    position: position ? position : 'bottom-right', 
                    autoClose: duration ? duration : 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    toastId: id ? id : undefined,
                    style: {
                        zIndex: 100000,
                    }
                });
                break;
            }
        }
    },
    async getLiveDate() {
        var date:any;
        const requestOptions = {
            method: 'GET',
            // headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET'}
        };
        await fetch("https://worldtimeapi.org/api/timezone/Asia/Kolkata", requestOptions)
            .then(async (response) => {
                var obj:any = await response.json();
                date = new Date(obj.datetime);
            })
            .then(data => {
            
            })
         return await date;   
    },

    getCurrentDate(){
        return new Date();
    },
    getCurrentDateString(){
        return new Date().toDateString();
    },
    getCurrentDateLocal(){
        return new Date().toLocaleDateString();
    },
    
    sendPostRequest(path:string,postData:FormData) {
        return new Promise((resolve, reject)=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData)
        };
        fetch(Config.APP_BASE_URL+path, requestOptions)
            .then(response => {
                
            })
            .then(data => {
                
            }).catch((err)=>{
                reject(err);
            })
        }).catch((err)=>{
            CommonErrorHandlar.catcherror(err);
        })
    },

    sendPostRequestWithToken(path:string,postData:FormData, baseUrl?: boolean) {
        return new Promise((resolve, reject)=>{
            firebase.auth().currentUser?.getIdToken(false).then(function(idToken) {
            if(idToken){  
                postData.append('token',idToken);
                const requestOptions = {
                    method: 'POST',
                    headers: {  },
                    body: postData
                };
                fetch((baseUrl ? '' : Config.APP_BASE_URL)+path, requestOptions)
                    .then(response => {
                        response.json().then((res)=>{
                            resolve(res);
                        });
                    }).catch((err) =>{
                        reject(err);
                    });
                }else{
                    reject("Id token not found");
                }
            }).catch((err)=>{
                reject(err);
            })
        }).catch((err)=>{
            CommonErrorHandlar.catcherror(err);
        })
    },

    sendPostRequestWithTokenJson(path:string,postData:any) {
        return new Promise((resolve, reject)=>{
            firebase.auth().currentUser?.getIdToken(false).then(function(idToken) {
            if(idToken){  
                postData.token = idToken;
                fetch(path, {method: 'POST', body: JSON.stringify(postData)})
                    .then(response => {
                        response.json().then((res)=>{
                            resolve(res);
                        });
                    }).catch((err) =>{
                        reject(err);
                    });
                }else{
                    reject("Id token not found");
                }
            }).catch((err)=>{
                reject(err);
            })
        }).catch((err)=>{
            CommonErrorHandlar.catcherror(err);
        })
    },

    share(title:string, text: string | undefined, url: string){
        navigator.share({
            'title': title,
            'text': text,
            'url': url
        }).then(function() {
            
        }).catch(function(error) {
            
        });
    },
    shareFiles(files: File[], title:string, text: string | undefined){
        if(files.length>0){
            let newVariable: any;
            newVariable = window.navigator;
            if (newVariable.canShare && newVariable.canShare({ files: files })) {
                newVariable.share({
                    'files': files,
                    'title': title,
                    'text': text
                }).then(function() {
                    
                }).catch(function(error:any) {
                    
                });
            }
        }
    },
    toDataURL(url:string, callback:any) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
            callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    },
    // urltoFile(url:string, filename:string, mimeType:any){
    //     mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    //     return (fetch(url)
    //         .then(function(res){return res.arrayBuffer();})
    //         .then(function(buf){return new File([buf], filename, {type:mimeType});})
    //     );
    // },
    forceReload() {
        const form = document.createElement('form');
        form.method = "POST";
        form.action = window.location.href;
        document.body.appendChild(form);
        form.submit();
    },
    forceReloadPopup(version: string) {
        const form = document.createElement('form');
        form.method = "POST";
        form.action = window.location.href;
        document.body.appendChild(form);
        swal({
            title: "New update available",
            text: "Click update button to reload with new updates",
            icon: "warning",
            dangerMode: true,
            buttons: ["cancel","update"],
          })
          .then(update => {
            if (update) {
                localStorage.setItem("version", version);
                form.submit();
            }
          });
    },
    async viewImage(url: string, alt: string | undefined, title: string | undefined){
        if(Capacitor.isNative){
            await PhotoViewer.show(url, title, {share: false});
        }else{
            const result = await CustomDialog(<ImageView image={url} alt={alt} />, {
                title: title ? title : 'Image',
                showCloseIcon: true,
            });
        }
    },
    async viewWelcome(url: string, alt: string | undefined, title: string | undefined){
        setTimeout(function () {
            if(document.getElementsByClassName("Modal-module_overlay__21YAk")){
                const elements:any = document.getElementsByClassName("Modal-module_overlay__21YAk");
                while(elements.length > 0){
                    elements[0].parentNode.removeChild(elements[0]);
                }
            }
          }, 3000);
        const result:any = await CustomDialog(<img src={url} style={{minHeight: '30px', width: '100%'}} alt={alt} />, {
            title: title ? title : '',
            showCloseIcon: true,
            className: 'welcome-gif',
        });
        
    },
    async viewBank(){
        const result:any = await CustomDialog(<Grid container key="bb1" lg={12} md={12} sm={12} xs={12} >
        <Grid item key={"bbb1"} lg={12} md={12} sm={12} xs={12} >
            <Typography  style={{padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1" component="div">
                Beneficiary Name: {settings.companyName}
            </Typography>
            <Typography  style={{padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1" component="div">
                Account No: {settings.bankAccountNumber}
            </Typography>
            <Typography  style={{padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1" component="div">
                IFSC: {settings.ifscNumber}
            </Typography>
            <Typography  style={{padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1" component="div">
                Bank: {settings.bankName}
            </Typography>
            <Typography  style={{padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1" component="div">
                Paytm: {settings.paytm}
            </Typography>      
        </Grid>
    </Grid>, {
            title: "Bank Details",
            showCloseIcon: true
        });
        
    },
    async uploadImage(elementId:string){
        var resultPreview:any = document.getElementById(elementId);
        var obj:any = document.querySelector('input[type=file]'); //selects the very first file
        var file = obj.files[0];
        var fr = new FileReader();
        
        fr.addEventListener('load', function(){
            //once the FileReader is loaded,
            resultPreview.src = fr.result;
        }, false);
        
        if (file){ //if there even is a first file
            fr.readAsDataURL(file); //don't want to mess with Data URLs!! this calls the function above
        }
    },
    async viewCanvas(product: Product, width: number | undefined, height: number | undefined){
        if(product.url){
            let img = new Image(); 
            img.src = product.url;
            var imageWidth = width ? width : 400;
            img.onload = async function (event:any) {
                let  loadedImage:any = event.currentTarget;
                if(loadedImage){
                    let width = loadedImage.width;
                    let height = loadedImage.height;
                    var r = height/width;
                    var i:ImageInterface = {url: product.url, width: imageWidth, height: r*imageWidth};
                    i.image = loadedImage;
                    const result = await CustomDialog(
                        <div id="canvas-view">
                            <CanvasDraw
                                imgSrc={product.url}
                                immediateLoading={true}
                                brushRadius={1}  
                                lazyRadius={1}
                                canvasWidth={imageWidth}
                                canvasHeight={r*imageWidth}
                                saveData= {null}
                                hideInterface = {true}
                                brushColor="#808080"
                                disabled={true}
                                loadTimeOffset= {5}
                                // hideGrid = {true}
                                style={{
                                    boxShadow:
                                    "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                                    , marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'relative',
                                    }}
                                />
                        </div>
                        , {
                        title: product.name,
                        onAfterOpen: ()=>{
                            setTimeout(() =>{
                                if(product.color && product.color.poseX){
                                    var canvas:any = document.getElementById("canvas-view");
                                    if(canvas && canvas.firstChild.firstChild.nextElementSibling.nextElementSibling.nextElementSibling && product.color.poseX && product.color.poseY)
                                    {
                                        var c:any = canvas.firstChild.firstChild.nextElementSibling.nextElementSibling;
                                        var ctx = c.getContext('2d');
                                        var ratioX = 1;
                                        var ratioY = 1;
                                        if(imageWidth && product.color.imageWidth && product.color.imageHeight){
                                            ratioX = imageWidth/product.color.imageWidth;
                                            ratioY = r*imageWidth/product.color.imageHeight;
                                            
                                            
                                        }
                                        var centerX = product.color.poseX ? product.color.poseX*ratioX : 0;
                                        var centerY = product.color.poseY ? product.color.poseY*ratioY : 0;
                                        //draw tick
                                        if(imageWidth<400){
                                            ctx.beginPath();
                                            ctx.moveTo(centerX,centerY);
                                            ctx.lineTo(centerX+20,centerY-15);
                                            ctx.moveTo(centerX+1,centerY+2);
                                            ctx.lineTo(centerX-10,centerY-10);
                                            ctx.lineWidth = 5;
                                        }else{
                                            ctx.beginPath();
                                            ctx.moveTo(centerX,centerY);
                                            ctx.lineTo(centerX+40,centerY-30);
                                            ctx.moveTo(centerX+3,centerY+4);
                                            ctx.lineTo(centerX-20,centerY-20);
                                            ctx.lineWidth = 10;
                                        }
                                        if(product.color.code){
                                            const hex = product.color.code.replace('#', '');
                                            const c_r = parseInt(hex.substr(0, 2), 16);
                                            const c_g = parseInt(hex.substr(2, 2), 16);
                                            const c_b = parseInt(hex.substr(4, 2), 16);
                                            const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
                                            ctx.strokeStyle = brightness > 155 ? '#4A6572' : '#F9AA33';
                                        }
                                        ctx.stroke();    
                                    }
                                }
                            },100)
                        },
                        showCloseIcon: true,
                    });
                }
            }    
        }
    },
    shownotification(id:any,title:string,type:"info" | "warning" | "success" | "error" | "dark" | undefined,duration:number | undefined,position:"top-right" | "top-left" | "top-center" | "bottom-right" | "bottom-left" | "bottom-center" | undefined, onClick:any){
        toast.info(title,{
            position: position ? position : 'top-right',
            autoClose: duration ? duration : 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: id ? id : undefined,
            style: {
                zIndex: 100000,
            },
            onClick: onClick
        });
    },
    wc_hex_is_light(color:string) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    },
    async printDiv(divId: string, width: string | undefined, height:string | undefined) {
        if(document.getElementById(divId)){
            
            var divContents = document.getElementById(divId)?.innerHTML;
            var a:any = window.open('', '', 'height='+height ? height : '1280'+', width='+width ? width : '800');
            a.document.write('<html>');
            a.document.write('<head>');
            a.document.write('<style>');
            a.document.write(".invoice-box table{width:100%;line-height:inherit;text-align:left}td{font-size: 12px;}.invoice-box table td{padding:5px;vertical-align:top}.invoice-box table tr td:nth-child(2){text-align:right}.invoice-box table tr.top table td{padding-bottom:20px}.invoice-box table tr.top table td.title{font-size:45px;line-height:45px;color:#333}.invoice-box table tr.information table td{padding-bottom:40px}.invoice-box table tr.heading td{text-align: left;background:#eee;border-bottom:1px solid #ddd;font-weight:700}.invoice-box table tr.details td{text-align: left;padding-bottom:20px}.invoice-box table tr.item td{text-align: left;border-bottom:1px solid #eee}.invoice-box table tr.item.last td{border-bottom:none}.invoice-box table tr.total td:nth-child(2){border-top:2px solid #eee;font-weight:700}@media only screen and (max-width:600px){.invoice-box table tr.top table td{width:100%;display:block;text-align:center}.invoice-box table tr.information table td{width:100%;display:block;text-align:center}}.invoice-box.rtl{direction:rtl;font-family:Tahoma,'Helvetica Neue',Helvetica,Helvetica,Arial,sans-serif}.invoice-box.rtl table{text-align:right}.invoice-box.rtl table tr td:nth-child(2){text-align:left}.invoice-icon{font-size: 14px !important; width: 14px !important;}div.divFooter {position: fixed;bottom: 20; left: 20; width: 100%;}}img.watermark{position: absolute;margin: 0 auto;opacity: .1;}");
            a.document.write('</style>');
            // a.document.write('<link rel="stylesheet" href="/assets/InvoiceStyles.css">');
            a.document.write('</head>');
            a.document.write('<body >');
            a.document.write(divContents);
            a.document.write('</body></html>');
            a.document.close();
            setTimeout(function () {
                a.print();
            }, 500);
        }
    },
    randomString(length:number) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },
    randomDigit(max:number, min:number) {
        const a = Math.floor(Math.random() * (max - min + 1)) + min;
        return a;
    },
    async viewText(element:any, title:string){
        const result = await CustomDialog(element, {
            title: title,
            showCloseIcon: true,
        });
    },
    scrollToDivId(id: string){
        document.getElementById(id)?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    },
    async openlink(link){
        await Browser.open({ url: link });
    },
    async openFile (path, mimeType) {
        Filesystem.getUri({
            directory: Directory.Data,
            path: path
          }).then((getUriResult) => {
            const path = getUriResult.uri;
            FileOpener.open(path, 'application/pdf')
          }, (error) => {
            
          });
    },
    saveAndOpenPdf(data: any, filename: string) {
        const writeDirectory = File.externalDataDirectory;
        File.writeFile(writeDirectory, filename, data, {replace: true})
          .then(() => {
            FileOpener.open(writeDirectory + filename, 'application/pdf')
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
          })
          .catch(() => {
              console.error('Error writing pdf file');
          });
    },
    convertBase64ToBlob(b64Data, contentType): Blob {
        contentType = contentType || '';
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        const byteCharacters = window.atob(b64Data);
        const byteArrays:any = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
             const slice = byteCharacters.slice(offset, offset + sliceSize);
             const byteNumbers = new Array(slice.length);
             for (let i = 0; i < slice.length; i++) {
                 byteNumbers[i] = slice.charCodeAt(i);
             }
             const byteArray:any = new Uint8Array(byteNumbers);
             byteArrays.push(byteArray);
        }
       return new Blob(byteArrays, {type: contentType});
    },
    backNavigation(){
        window.history.back();
    },
    redirect(url){
        window.location.href = url;
    },
    // getCurrentDate(){
    //     return new Date();
    // },
    // getCurrentDateString(){
    //     return new Date().toDateString();
    // },
    // getCurrentDateLocal(){
    //     return new Date().toLocaleDateString();
    // },
    getIndianDateFormatFromDate(timestamp:any){
        var date = new Date(1970, 0, 1);
        date.setSeconds(timestamp.seconds ? timestamp.seconds+19800 : timestamp.getSeconds()+19800);
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+", "+(date.getHours() > 12 ? (date.getHours()-12) : date.getHours())+":"+date.getMinutes()+" "+((date.getHours() > 12) ? 'PM' : 'AM');
    },
    getIndianDateFormatFromDateObject(date:Date){
        return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()+", "+(date.getHours() > 12 ? (date.getHours()-12) : date.getHours())+":"+date.getMinutes()+" "+((date.getHours() > 12) ? 'PM' : 'AM');
    },
    getProductTypeList(){
        return [{label: "Row Material", value: "Row Material"}, {label: "Packing Material", value: "Packing Material"}, {label: "Fabric Roll", value: "Fabric Roll"}, {label: "Semi Finished", value: "Semi Finished"}, {label: "Finished", value: "Finished"}];
    },
    getCreditNoteTypeList(){
        return [{label: "Purchase Order", value: "Purchase Order"}];
    },
    getDebitNoteTypeList(){
        return [{label: "Sales Order", value: "Sales Order"}];
    },
    getCommissionTypeList(){
        return [{label: "Percentage", value: "Percentage"},{label: "Fix", value: "Fix"}];
    },
    getTransportModeList(){
        return [{label: "By Air", value: "By Air"},{label: "By Road", value: "By Road"},{label: "By Sea", value: "By Sea"},{label: "By Train", value: "By Train"}];
    },
    getPurchaseReturnReasonList(){
        return [{label: "Poor Quality", value: "Poor Quality"},{label: "Damage", value: "Damage"},{label: "Duplicate", value: "Duplicate"}];
    },
    getFileFormatList(){
        return ".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    },
} 