import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { UserCart } from './UserCart';

interface UserCarts{
    cart?:UserCart;
}

export function useUserCartInit():UserCarts {
  const [cart, setUserCarts] = useState<UserCarts>({cart: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
      const subscriber = db.collection("carts").doc(uid)
      .onSnapshot((querySnapshot) => {
          var data:UserCart = {};
          var val = querySnapshot.data();
          if(val && val.cart){
            data = val.cart;
          }  
          const obj = { cart: data };
          setUserCarts(obj);
      });

      // Unsubscribe from events when no longer in use
      return subscriber;
  

}, []);
  return cart;
}
