import { IonPage } from '@ionic/react';
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { db } from '../../App';
import CustomButton from '../../components/common/CustomButton';
import SideMenuUser from '../../components/menu/SideMenuUser';
import NativeApi, { openPlayStore } from '../../services/NativeApi';
import GlobalStyle from '../../style/GlobalStyle';
import {
  Capacitor, Plugins
} from "@capacitor/core";
const { NativeMarket } = Plugins;

interface AppVersion{
  id: number,
  type: string,
}

const UpdatePage: React.FC = () => {

  const [applicationVersion, setApplicationVersion ] = useState<AppVersion>();
  const [available, setAvailable] = useState<boolean>(false);

  const classes = GlobalStyle();  

  if(!applicationVersion){
   GetAppVersion();
  }
  
  async function GetAppVersion() { 
      var data:AppVersion = {id: 0, type: 'r'};
      await db.collection("version").doc("version")
      .get().then((querySnapshot) => {
          if(querySnapshot.exists){
              var obj = querySnapshot.data();
              if(obj && obj.id && obj.type){
                  data.id = obj.id;
                  data.type = obj.type;
              }
          }
      });
      setApplicationVersion(data);
      NativeApi.getAppVersion().then((ver)=>{
        if(data.id > parseFloat(ver)){
          setAvailable(true);
        }
      });
      return data;
  }

  const openPlayStore = () => {
    console.log("opining play store");
    NativeMarket.openStoreListing({
      appId: 'com.pktbazaar',
    });
  }

  return (
    <IonPage>
        <SideMenuUser title="Product" backButton={true} /> 
        <div className={classes.topHeader} >
            <Grid container style={{marginTop: '10px'}}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Card>
                        {Capacitor.isNative ? 
                        <CardContent className={classes.centerText}>
                           <p>{available ? 'New updates available':'Application already updated to the latest version'}</p>
                           {available ? <CustomButton title="Update Now" onClick={openPlayStore} /> : ''}
                        </CardContent> 
                        : 'No update available'}
                    </Card> 
                </Grid>
            </Grid>  
        </div>
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
center: {
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px',
  marginTop: '80px',
  textAlign: 'center'
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default UpdatePage;