import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Size } from './Size';

interface Sizes{
    sizes?:Size[];
}

export function useSizeInit():Sizes {
  const [sizes, setSizes] = useState<Sizes>({sizes: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("size").doc("size")
        .onSnapshot((querySnapshot) => {
            var data:Size[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                if(obj && obj.size){
                var brs:Size[] = obj.size;    
                var sorted = brs.slice(0);
                    sorted.sort(function(a,b) {
                        var x = a.name;
                        var y = b.name;
                        if(x && y){
                            return x < y ? -1 : x > y ? 1 : 0;
                        }else{
                            return 0;
                        }
                    });
                data = sorted;
                }else{
                    data = [];
                }
            }
            var us:Sizes = {sizes: data};
            setSizes(us);
        });

        // Unsubscribe from events when no longer in use
        return subscriber;
  }, []);

  return sizes;
}
