import CommonErrorHandlar from "./CommonErrorHandlar";
import { Camera, CameraResultType } from '@capacitor/camera';
import { fireStorage } from "../App";


function GetFirestore(){
    
}

export async function getPicture(path:string) {
    var urlstring = "";
    var storageRef = fireStorage.ref();
    await storageRef.child(path).getDownloadURL()
    .then((url:any) => {
        
        urlstring = url;
    }).catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return urlstring;
}

export async function uploadPicture(url:string, path:string) {
    var res = '';
    var storageRef = fireStorage.ref();
    var imagesRef = storageRef.child(path);
    await imagesRef.putString(url,'data_url').then((snapshot) => {
        res = path;
        
    }).catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return res;
}

export async function uploadFiles(files:File[], basePath:string) {
    var res = false;
    var storageRef = fireStorage.ref();
    for(var i=0; i<files.length; i++){
        var fileRef = storageRef.child(basePath+files[i].name);
        await fileRef.put(files[i]).then((snapshot) => {
            res = true;
            
        }).catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
    }
    return res;
}

export async function uploadFile(file:File, path:string) {
    var res = '';
    var storageRef = fireStorage.ref();
        var fileRef = storageRef.child(path);
        await fileRef.put(file).then((snapshot) => {
            res = path;
            
        }).catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
    
    return res;
}

export async function uploadString(str:string, path:string) {
    var res = '';
    var storageRef = fireStorage.ref();
        var fileRef = storageRef.child(path);
        await fileRef.putString(str).then((snapshot) => {
            res = path;
            
        }).catch((error) => {
            CommonErrorHandlar.catcherror(error);
        });
    
    return res;
}

export async function removePicture(path:string) {
    var res = false;
    var storageRef = fireStorage.ref();
    var imagesRef = storageRef.child(path);
    await imagesRef.delete().then((snapshot) => {
        res = true;
        
    }).catch((error) => {
        CommonErrorHandlar.catcherror(error);
    });
    return res;
}

export default { 
    GetFirestore,
    getPicture,
    uploadPicture,
    removePicture,
    uploadFiles,
    uploadFile,
    uploadString,
}