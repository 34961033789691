import { Button, Card, CardContent, Fab, Grid, makeStyles, Popover } from '@material-ui/core';
import { IonBadge, IonCheckbox, IonContent, IonFab, IonFabButton, IonFabList, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonPage, NavContext } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import { Product } from '../product/Product';
import SideMenuUser from '../../components/menu/SideMenuUser';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import StoreProductCard from './StoreProductCard';
import React from 'react';
import { Filter } from './Filter';
import { useLocation, Switch } from 'react-router-dom'; 
import { useAllProductInit } from '../product/ProductDataForUser';
import { useBrandInit } from '../brand/BrandData';
import { useCategoryInit } from '../category/CategoryData';
import ProductService from '../product/ProductService';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { cart, filterOutline } from 'ionicons/icons';
import CustomButton from '../../components/common/CustomButton';
import CartProductCardMob from './CartProductCardMob';

const StoreListPageDesktop: React.FC = () => {

  const [data, setData] = useState<Product[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  const [files, setFiles] = useState<File[]>();
  const [anchorFl, setAnchorFl] = React.useState<HTMLButtonElement | null>(null);
  const openFilter = Boolean(anchorFl);
  const filterId = openFilter ? 'filter-popover' : undefined;
  const {brands} = useBrandInit();
  const { categories } = useCategoryInit();
  const [subCategories , setSubCategories] = useState<string[]>([]);
  let searchKey = new URLSearchParams(window.location.search).get("search");
  if(searchKey){
    searchKey = decodeURI(searchKey).replace("11nd", "&");
  }
  let mainCategory = new URLSearchParams(window.location.search).get("mainCategory");
  if(mainCategory){
    mainCategory = decodeURI(mainCategory).replace("11nd", "&");
  }
  let subCategory = new URLSearchParams(window.location.search).get("subCategory");
  if(subCategory){
    subCategory = decodeURI(subCategory).replace("11nd", "&");
  }
  let parentCategory = new URLSearchParams(window.location.search).get("parentCategory");
  if(parentCategory){
    parentCategory = decodeURI(parentCategory).replace("11nd", "&");
  }
  let brandSearch = new URLSearchParams(window.location.search).get("brand");
  if(brandSearch){
    brandSearch = decodeURI(brandSearch).replace("11nd", "&");
  }
  const id = auth?.userId;
  const { products } = useAllProductInit();
  const [productList, setProductList] = useState<Product[]>();
  let [filter, setFilterList] = useState<Filter>({
    priceStart: 0,
    priceEnd: 0,
    brand: [],
    color: [],
    size: [],
    pattern: [],
    category1: [],
    category2: [],
    category3: [],
    searchKey: '',
  });
  const { size, orientation, width, height } = UseDeviceScreenSize();
  
  const location = useLocation();
 
  if(categories && categories.length>0 && subCategories.length === 0){
    var list:string[] = [];
    for(var cat of categories){
      if(cat.subCategories){
        for(var sub of cat.subCategories){
          if(sub){
            if(!list.includes(sub.name)){
              list.push(sub.name);
            }
          }
        }
      } 
    }
    var sorted = list.slice(0);
        sorted.sort(function(a,b) {
            var x = a;
            var y = b;
            if(x && y){
                return x < y ? -1 : x > y ? 1 : 0;
            }else{
                return 0;
            }
        });
    setSubCategories(sorted);
  }

  React.useEffect(() => {
    async function fetchMyAPI() {
      filter = {priceStart: 0,
        priceEnd: 0,
        brand: [],
        color: [],
        size: [],
        pattern: [],
        category1: [],
        category2: [],
        category3: [],
        searchKey: '',};
      // var prods = await ProductService.getAllProducts();
      // 
      // products = prods;
      // setProductList(prods);
      searchKey = new URLSearchParams(window.location.search).get("search");
      if(searchKey){
        searchKey = decodeURI(searchKey).replace("11nd", "&");
        filterEvent('searchKey',searchKey,'add');
      }
      mainCategory = new URLSearchParams(window.location.search).get("mainCategory");
      if(mainCategory){
        mainCategory = decodeURI(mainCategory).replace("11nd", "&");
        filterEvent('category3',mainCategory,'add');
      }
      subCategory = new URLSearchParams(window.location.search).get("subCategory");
      if(subCategory){
        subCategory = decodeURI(subCategory).replace("11nd", "&");
        filterEvent('category2',subCategory,'add');
      }
      parentCategory = new URLSearchParams(window.location.search).get("parentCategory");
      if(parentCategory){
        parentCategory = decodeURI(parentCategory).replace("11nd", "&");
        filterEvent('category1',parentCategory,'add');
      }
      brandSearch = new URLSearchParams(window.location.search).get("brand");
      if(brandSearch){
        brandSearch = decodeURI(brandSearch).replace("11nd", "&");
        filterEvent('brand',brandSearch,'add');
      }
    }
    fetchMyAPI();
  }, [location]);

  async function filterEvent(name:string, value: string, e:any) { 
    if(e=='add' || e.target.checked){
      if(name === 'brand'){
        if(!filter.brand.includes(value)){
          filter.brand.push(value);
        }
      }else if(name === 'color'){
        if(!filter.color.includes(value)){
          filter.color.push(value);
        }
      }else if(name === 'size'){
        if(!filter.size.includes(value)){
          filter.size.push(value);
        }
      }else if(name === 'pattern'){
        if(!filter.pattern.includes(value)){
          filter.pattern.push(value);
        }
      }else if(name === 'category3'){
        if(!filter.category3.includes(value)){
          filter.category3.push(value);
        }
      }else if(name === 'category2'){
        if(!filter.category2.includes(value)){
          filter.category2.push(value);
        }
      }else if(name === 'category1'){
        if(!filter.category1.includes(value)){
          filter.category1.push(value);
        }
      }else if(name === 'searchKey'){
        filter.searchKey = value;
      }
    }else{
      if(name === 'brand'){
        var brands = filter.brand.filter(e => e !== value);
        filter.brand = brands;
       }else if(name === 'color'){
        var colors = filter.color.filter(e => e !== value);
        filter.color = colors;
      }else if(name === 'size'){
        var sizes = filter.size.filter(e => e !== value);
        filter.size = sizes;
      }else if(name === 'pattern'){
        var patterns = filter.pattern.filter(e => e !== value);
        filter.pattern = patterns;
      }else if(name === 'category3'){
        var category3s = filter.category3.filter(e => e !== value);
        filter.category3 = category3s;
      }else if(name === 'category2'){
        var category2s = filter.category2.filter(e => e !== value);
        filter.category2 = category2s;
      }else if(name === 'category1'){
        var category1s = filter.category1.filter(e => e !== value);
        filter.category1 = category1s;
      }else if(name === 'searchKey'){
        filter.searchKey = value;
      }
    }
    if(!products || products.length<1){
      var products2 = await ProductService.getAllProducts();
      const results = products2?.filter((product) => {
        return ((product.brand && filter.brand && filter.brand.includes(product.brand)) || filter.brand.length<1) && 
        ((product.colors && product.colors.length>0 && product.colors.some((r) => filter.color?.includes(r.name))) || filter.color.length<1) &&
        ((product.sizes && product.sizes.length>0 && product.sizes.some((r) => filter.size?.includes(r.name))) || filter.size.length<1) &&
        ((product.patterns && product.patterns.length>0 && product.patterns.some((r) => filter.pattern?.includes(r))) || filter.pattern.length<1) &&
        ((product.category3 && filter.category3 && filter.category3.includes(product.category3)) || filter.category3.length<1) && 
        ((product.category2 && filter.category2 && filter.category2.includes(product.category2)) || filter.category2.length<1) &&
        ((product.category1 && filter.category1 && filter.category1.includes(product.category1)) || filter.category1.length<1) &&
        ((filter.searchKey && (product.brand?.toLowerCase().includes(filter.searchKey.toLowerCase()) || product.name?.toLowerCase().includes(filter.searchKey.toLowerCase()) || product.category2?.toLowerCase().includes(filter.searchKey.toLowerCase()) )) || !filter.searchKey);
      });
      setProductList(results);

      setFilterList(filter);
    }else{
      const results = products?.filter((product) => {
        return ((product.brand && filter.brand && filter.brand.includes(product.brand)) || filter.brand.length<1) && 
        ((product.colors && product.colors.length>0 && product.colors.some((r) => filter.color?.includes(r.name))) || filter.color.length<1) &&
        ((product.sizes && product.sizes.length>0 && product.sizes.some((r) => filter.size?.includes(r.name))) || filter.size.length<1) &&
        ((product.patterns && product.patterns.length>0 && product.patterns.some((r) => filter.pattern?.includes(r))) || filter.pattern.length<1) &&
        ((product.category3 && filter.category3 && filter.category3.includes(product.category3)) || filter.category3.length<1) && 
        ((product.category2 && filter.category2 && filter.category2.includes(product.category2)) || filter.category2.length<1) &&
        ((product.category1 && filter.category1 && filter.category1.includes(product.category1)) || filter.category1.length<1) &&
        ((filter.searchKey && (product.brand?.toLowerCase().includes(filter.searchKey.toLowerCase()) || product.name?.toLowerCase().includes(filter.searchKey.toLowerCase()) || product.category2?.toLowerCase().includes(filter.searchKey.toLowerCase()) )) || !filter.searchKey);
      });
      setProductList(results);
      setFilterList(filter);
    }
    
  }

  const resetFilter = () =>{
    const results = products?.filter((product) => {
      return ((product.brand && filter.brand && filter.brand.includes(product.brand)) || filter.brand.length<1) && 
      ((product.colors && product.colors.length>0 && product.colors.some((r) => filter.color?.includes(r.name))) || filter.color.length<1) &&
      ((product.sizes && product.sizes.length>0 && product.sizes.some((r) => filter.size?.includes(r.name))) || filter.size.length<1) &&
      ((product.patterns && product.patterns.length>0 && product.patterns.some((r) => filter.pattern?.includes(r))) || filter.pattern.length<1) &&
      ((product.category3 && filter.category3 && filter.category3.includes(product.category3)) || filter.category3.length<1) &&
      ((product.category2 && filter.category2 && filter.category2.includes(product.category2)) || filter.category2.length<1) &&
      ((product.category1 && filter.category1 && filter.category1.includes(product.category1)) || filter.category1.length<1);
    });
    setProductList(results);
  }
  
  const removeFilter = (name, value)=>{
    switch(name){
      case 'category3' :
        filter.category3 = filter.category3.filter(item => item!==value)
        setFilterList({...filter})
        break;
      case 'category2' :
        filter.category2 = filter.category2.filter(item => item!==value)
        setFilterList({...filter})
        break;
      case 'category1' :
        filter.category1 = filter.category1.filter(item => item!==value)
        setFilterList({...filter}) 
        break;  
      case 'brand' :
        filter.brand = filter.brand.filter(item => item!==value)
        setFilterList({...filter}) 
        break;     
      case 'searchKey' :
        filter.searchKey = '';   
        setFilterList({...filter}) 
        break; 
      // case 'size' :
      //   filter.size = filter.size.filter(item => item!==value)
      //   setFilterList({...filter}) 
      //   break; 
      // case 'color' :
      //   filter.color = filter.color.filter(item => item!==value)
      //   setFilterList({...filter}) 
      //   break; 
      // case 'pattern' :
      //   filter.pattern = filter.pattern.filter(item => item!==value)
      //   setFilterList({...filter}) 
      //   break;  
        
    }
    if(filter.category3.length === 0 && filter.category2.length === 0 && filter.category1.length === 0 && filter.brand.length === 0 && filter.searchKey === ''){
      navigate("/store","back","pop");
      resetFilter();
    }else{  
      resetFilter();
    }
  }

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorFl(null);
  };

  const getProducts = (start:number, end:number) =>{
    if(products && products.length > start){
      if(products.length>end){
        return products.slice(start,end);
      }else{
        return products.slice(start, (products.length));
      }
    }else{
      return [];
    }
  }

  const pushData = (reset:boolean) => {
    const max = data.length + 20;
    const min = max - 20;
    const newData:Product[] = getProducts(min,max);
    setData([
      ...data,
      ...newData
    ]);
  }
  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData(false);
      ev.target.complete();
      if(products && data.length === products?.length){
        setInfiniteDisabled(true);
      }
    }, 500);
  }  

  const reloadData = (ev: any) => {
      setTimeout(() => {
        const max = 20;
        const min = 0;
        const newData:Product[] = getProducts(min,max);
        
        setData([
          ...data,
          ...newData
        ]);
      ev.target.complete();
      if (products && data.length === products?.length) {
        setInfiniteDisabled(true);
      }
    }, 500);
  }  
  
  if(data.length<1 && products && products.length> 0){
    pushData(false);
  }

  return (
    <IonPage>
         <SideMenuUser title="Product" /> 
        {brands && brands.length>0 ? <div style={{marginTop: '60px',  paddingBottom: '20px', height: '100%', width: '-webkit-fill-available'}} >
          <IonContent>
              { size === 'lg' || size =='xl' ? 
              <Grid container>
                <Grid item lg={2} md={3}>
                  <Card style={{boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: '0.3s'}}>
                    <CardContent>
                        <Grid container>
                            <Grid item key="f2" lg={12} sm={12} xs={12}>
                                <IonLabel className={classes.categoryHeading}>Brand Filter</IonLabel>
                                <div style={{maxHeight: '250px', overflowY: 'scroll', border: '2px solid gray', padding: '5px'}}>
                                {
                                  brands && brands.length > 0 ? brands.map((item, index)=>{
                                    return (
                                      <Grid container key={"filter-brand"+index} className={classes.categoryText}>
                                        <Grid item  lg={2} md={2} sm={2} xs={2} >
                                          <IonCheckbox slot="end" color="secondary" checked={filter.brand.some(e => e === item.name)} onClick={(e)=>{filterEvent('brand',item.name,e)}} />
                                        </Grid>
                                        <Grid item  lg={10} md={10} sm={10} xs={10} >
                                          <span className={classes.categoryName}>{item.name}</span>
                                        </Grid>
                                      </Grid>
                                    )
                                  }) : ''
                                }
                                </div>
                            </Grid>
                            <Grid item key="f3" lg={12} sm={12} xs={12}>
                                <IonLabel className={classes.categoryHeading}>Category Filter</IonLabel>
                                <div style={{maxHeight: '250px', overflowY: 'scroll', border: '2px solid gray', padding: '5px'}}>
                                {
                                  subCategories && subCategories.length > 0 ? subCategories.map((category, index)=>{
                                    return (
                                      <Grid container key={"filter-category"+index} className={classes.categoryText}>
                                        <Grid item  lg={2} md={2} sm={2} xs={2} >
                                          <IonCheckbox slot="end" color="secondary" checked={filter.category2.some(e => e === category)} onClick={(e)=>{filterEvent('category2',category,e)}} />
                                        </Grid> 
                                        <Grid item  lg={10} md={10} sm={10} xs={10} >
                                          <span className={classes.categoryName}>{category}</span>
                                        </Grid>
                                      </Grid>
                                    )
                                  }) : ''
                                }
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item lg={10} md={9} >
                <Grid container>
                <Grid item key="categoryList" lg={12} sm={12} xs={12} style={{margin: '10px'}}>
                  {filter.searchKey ? <IonBadge key={'searchvalue'} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('searchKey',filter.searchKey)}} >X | </a> {filter.searchKey}</IonBadge> : ''}
                  {
                    filter.category3.map((item,index)=>{
                      return (
                        <IonBadge key={'cat3'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category3',item)}} >X | </a> {item}</IonBadge>
                      );
                    })
                  }
                  {
                    filter.category2.map((item,index)=>{
                      return (
                        <IonBadge key={'cat2'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category2',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.category1.map((item,index)=>{
                      return (
                        <IonBadge key={'cat1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category1',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.brand.map((item,index)=>{
                      return (
                        <IonBadge key={'brand1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('brand',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.size.map((item,index)=>{
                      return (
                        <IonBadge key={'size1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('size',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                </Grid>
                  {
                    productList ? productList.map((item, index)=>{
                      var exist:boolean = false;
                      if(auth && auth.user && auth.user.role === 'Supplier' && auth.user.categories && auth.user.categories.length > 0){
                        for(var category of auth.user.categories){
                          if(item.category2 === category.sub){
                            exist = true;
                            break;
                          }
                        }
                      }
                      if(!exist){
                        return (
                          <Grid item key={item.key} lg={3} sm={6} xs={12} >
                            <StoreProductCard brands={brands} key={'sp'+item.key} product={item} viewClick={()=>{navigate("/storeProduct?key="+item.key+"&id="+item.user?.id,"forward","push")}} ></StoreProductCard>
                          </Grid>
                        )
                      }
                    }) : data && data.length >0 ? data.map((item, index)=>{
                      var exist:boolean = false;
                      if(auth && auth.user && auth.user.role === 'Supplier' && auth.user.categories && auth.user.categories.length > 0){
                        for(var category of auth.user.categories){
                          if(item.category2 === category.sub){
                            exist = true;
                            break;
                          }
                        }
                      }
                      if(!exist){
                        return (
                          <Grid item key={item.key} lg={3} sm={6} xs={12} >
                            <StoreProductCard brands={brands} product={item} viewClick={()=>{navigate("/storeProduct?key="+item.key+"&id="+item.user?.id,"forward","push")}} ></StoreProductCard>
                          </Grid>
                        )
                      }
                    }) :<IonBadge>No records found</IonBadge>
                  }
                </Grid> 
                </Grid> 
                </Grid> : <Grid container>
                <IonFab vertical="top" horizontal="end" slot="fixed" style={{position: 'fixed', top: '60px',}}>
                   <CustomButton title='' icon={filterOutline} aria-describedby={filterId} onClick={handleClickFilter} />
                  </IonFab>
                  <Popover
                    id={filterId}
                    open={openFilter} 
                    anchorEl={anchorFl}
                    onClose={handleCloseFilter}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    style={{width: '250px !important', position: 'absolute'}}
                  >
                    <div style={{maxHeight: '500px', padding: "0px"}}>
                    <Grid container>  
                    <Grid item lg={12} md={12}>
                      <Card style={{boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', transition: '0.3s'}}>
                        <CardContent style={{padding: '5px'}}>
                            <Grid container>
                                <Grid item key="f2" lg={12} sm={12} xs={12}>
                                    <IonLabel className={classes.categoryHeading}>Brand Filter</IonLabel>
                                    <div style={{maxHeight: '150px', overflowY: 'scroll', border: '2px solid gray', padding: '5px'}}>
                                    {
                                      brands && brands.length > 0 ? brands.map((item, index)=>{
                                        return (
                                          <Grid container key={"filter-brand"+index} className={classes.categoryText}>
                                            <Grid item  lg={3} md={3} sm={3} xs={3} >
                                              <IonCheckbox slot="end" color="secondary" checked={filter.brand.some(e => e === item.name)} onClick={(e)=>{filterEvent('brand',item.name,e)}} />
                                            </Grid>
                                            <Grid item  lg={9} md={9} sm={9} xs={9} >
                                              <span className={classes.categoryName}>{item.name}</span>
                                            </Grid>
                                          </Grid>
                                        )
                                      }) : ''
                                    }
                                    </div>
                                </Grid>
                                <Grid item key="f3" lg={12} sm={12} xs={12}>
                                    <IonLabel className={classes.categoryHeading}>Category Filter</IonLabel>
                                    <div style={{maxHeight: '150px', overflowY: 'scroll', border: '2px solid gray', padding: '5px'}}>
                                    {
                                      subCategories && subCategories.length > 0 ? subCategories.map((category, index)=>{
                                        return (
                                          <Grid container key={"filter-category"+index} className={classes.categoryText}>
                                            <Grid item  lg={3} md={3} sm={3} xs={3} >
                                              <IonCheckbox slot="end" color="secondary" checked={filter.category2.some(e => e === category)} onClick={(e)=>{filterEvent('category2',category,e)}} />
                                            </Grid> 
                                            <Grid item  lg={9} md={9} sm={9} xs={9} >
                                              <span className={classes.categoryName}>{category}</span>
                                            </Grid>
                                          </Grid>
                                        )
                                      }) : ''
                                    }
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    </Grid>  
                    </Grid>        
                    </div>
                  </Popover>  
                <Grid item key="categoryList" lg={12} sm={12} xs={12} style={{margin: '10px'}}>
                  
                  {filter.searchKey ? <IonBadge key={'searchvalue'} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('searchKey',filter.searchKey)}} >X | </a> {filter.searchKey}</IonBadge> : ''}
                  {
                    filter.category3.map((item,index)=>{
                      return (
                        <IonBadge key={'cat3'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category3',item)}} >X | </a> {item}</IonBadge>
                      );
                    })
                  }
                  {
                    filter.category2.map((item,index)=>{
                      return (
                        <IonBadge key={'cat2'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category2',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.category1.map((item,index)=>{
                      return (
                        <IonBadge key={'cat1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('category1',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.brand.map((item,index)=>{
                      return (
                        <IonBadge key={'brand1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('brand',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                  {
                    filter.size.map((item,index)=>{
                      return (
                        <IonBadge key={'size1'+item} color="secondary" style={{margin: '5px'}}><a onClick={()=>{removeFilter('size',item)}} >X | </a> {item}</IonBadge>
                      )
                    })
                  }
                </Grid>
                  {
                    productList ? productList.map((item, index)=>{
                      var exist:boolean = false;
                      if(auth && auth.user && auth.user.role === 'Supplier' && auth.user.categories && auth.user.categories.length > 0){
                        for(var category of auth.user.categories){
                          if(item.category2 === category.sub){
                            exist = true;
                            break;
                          }
                        }
                      }
                      if(!exist){
                        return (
                          <Grid item key={item.key} lg={3} sm={6} xs={6} >
                            <CartProductCardMob type="store" brands={brands} key={item.key} product={item} viewClick={()=>{navigate("/storeProduct?key="+item.key+"&id="+item.user?.id,"forward","push")}} ></CartProductCardMob>
                          </Grid>
                        )
                      }
                    }) : data && data.length >0 ? data.map((item, index)=>{
                      var exist:boolean = false;
                      if(auth && auth.user && auth.user.role === 'Supplier' && auth.user.categories && auth.user.categories.length > 0){
                        for(var category of auth.user.categories){
                          if(item.category2 === category.sub){
                            exist = true;
                            break;
                          }
                        }
                      }
                      if(!exist){
                        return (
                          <Grid item key={item.key} lg={3} sm={6} xs={6} >
                            <CartProductCardMob type="store" brands={brands} key={item.key} product={item} viewClick={()=>{navigate("/storeProduct?key="+item.key+"&id="+item.user?.id,"forward","push")}} ></CartProductCardMob>
                          </Grid>
                        )
                      }
                    }) :<IonBadge>No records found</IonBadge>
                  }
                </Grid>
                }
              <IonInfiniteScroll
                  onIonInfinite={loadData}
                  threshold="100px"
                  disabled={isInfiniteDisabled}
                >
                  <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Loading more data..."
                  ></IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
          </div>
          : ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  categoryName: {
    color: 'var(--ion-color-primary)',
    fontSize: '14px',
  },
  categoryHeading:{
    color: 'var(--ion-color-secondary)',
    fontSize: '18px',
  },
  categoryText:{
    
  },
}));

export default StoreListPageDesktop;