import { IonPage } from '@ionic/react';
import { Container, makeStyles } from '@material-ui/core';
import SideMenuUser from '../../components/menu/SideMenuUser';
import ProfileForm from './ProfileForm';

const ProfilePage: React.FC = () => {
  return (
    <IonPage>
         <SideMenuUser title="Profile" backButton={true} />
          <div style={{marginTop: '60px',  paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
          <Container maxWidth={false}>
            <ProfileForm />
            </Container>
          </div>
          
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ProfilePage;