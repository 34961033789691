import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import 'firebase/auth';
import React, { useContext, useState } from 'react';
import CustomLoading from '../../components/loading/CustomLoading';
import SideMenuUser from '../../components/menu/SideMenuUser';
import settings from '../../config/settings';
import { UseAuthInit } from '../../hooks/firebase/Auth';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import GlobalService from '../../services/GlobalService';
import { useBrandInit } from '../brand/BrandData';
import CartProductCard from '../store/CartProductCard';
import CartProductCardMob from '../store/CartProductCardMob';
import { useOrderKeyInit } from './OrderDataById';

const OrderPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  const { auth } = UseAuthInit();
  let key = new URLSearchParams(window.location.search).get("key");
  const { size, orientation, width, height } = UseDeviceScreenSize();
  const { order } = useOrderKeyInit(key ? key : '');  
  const {brands} = useBrandInit();
  const [anchorB1, setAnchorBl] = React.useState<HTMLButtonElement | null>(null);
  const openBank = Boolean(anchorB1);
  const bankId = openBank ? 'bank-popover' : undefined;
  
  const[loading, setLoading] = useState<boolean>(false);

  const handleClickBank = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorBl(event.currentTarget);
  };

  const handleCloseBank = () => {
    setAnchorBl(null);
  };

  return (
    <IonPage>
      {order ? '' : <CustomLoading />}
         <SideMenuUser title="Product" backButton={true} /> 
         {brands && brands?.length > 0 ? <div style={{marginTop: '60px',  paddingBottom: '20px' , height: '100%', overflow: 'scroll', width: '-webkit-fill-available'}} >
            {order && order.products && order.products.length>0 ? <Grid container>
                <Grid item lg={12} sm={12} xs={12}>
                      <Card sx={{ width: '100%' }}>
                        { orientation=='portrait' ? <CardContent sx={{ p: 1, pb: 5+'px !important' }}>
                            <IonBadge style={{margin: '3px', fontSize: '11px'}} color={order.status === 'pending' ? 'light' : order.status === 'approved' ? 'secondary' : order.status === 'delivered' || order.status === 'shipped' ? 'success' : 'danger'}>Status: {order.status}</IonBadge>
                            <IonBadge style={{margin: '3px', fontSize: '11px'}} color="warning">Total Approx Amount: &#8377; {order.total} +GST application</IonBadge>
                            <IonBadge style={{margin: '3px', fontSize: '11px'}} color="danger">Items: {order.products.length}</IonBadge>
                            {order.type ? <IonBadge style={{margin: '3px', fontSize: '11px'}} color="primary">Type: {order.type}</IonBadge> : ''}
                            {auth?.user?.role === 'Supplier' ? <IonBadge style={{margin: '3px', fontSize: '11px'}} color="primary">Customer Id: {order.user?.userId}</IonBadge>
                          : ''}
                          <IonBadge style={{margin: '3px', fontSize: '11px'}} color="secondary" onClick={()=>{GlobalService.viewBank()}} title="Bank">View Bank Details</IonBadge>
                          
                        </CardContent> : 
                        <CardContent>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color={order.status === 'pending' ? 'light' : order.status === 'approved' ? 'secondary' : order.status === 'delivered' || order.status === 'shipped' ? 'success' : 'danger'}>Status: {order.status}</IonBadge>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="warning">Total Approx Amount: &#8377; {order.total} +GST application</IonBadge>
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="danger">Items: {order.products.length}</IonBadge>
                            {order.type ? <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Type: {order.type}</IonBadge> : ''}
                            {auth?.user?.role === 'Supplier' ? <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Customer Id: {order.user?.userId}</IonBadge>
                          : <span><br /><IonBadge style={{margin: '10px', fontSize: '16px'}} color="secondary">Bank Details</IonBadge>  <br />
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Beneficiary Name: {settings.companyName}</IonBadge> 
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Account No: {settings.bankAccountNumber}</IonBadge> 
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">IFSC: {settings.ifscNumber}</IonBadge> 
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Bank: {settings.bankName}</IonBadge> 
                            <IonBadge style={{margin: '10px', fontSize: '16px'}} color="primary">Paytm: {settings.paytm}</IonBadge> 
                          </span> 
                          }
                        </CardContent>
                      }
                    </Card> 
                </Grid>  
                <Grid container>
                   { order.products.map((item, index)=>{
                        return (
                            <Grid item key={item.key+''+index} lg={12} md={12} sm={6} xs={6} >
                                {orientation=='portrait' ? <CartProductCardMob brands={brands} key={item.key+'a'+index} product={item}></CartProductCardMob> : 
                                <CartProductCard brands={brands} key={item.key+'a'+index} product={item}></CartProductCard>  
                                }
                            </Grid>
                        )
                    }) 
                    }
                </Grid>
            </Grid> : 
              <div className={classes.center}>
                <Button onClick={()=>{
                  navigate("/store","forward","push");
                }} style={{color: 'var(--ion-color-primary-white)', backgroundColor: 'var(--ion-color-primary)', marginTop: '10px',}} >Shop Now</Button>
              </div>}
          </div> : ''}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
center: {
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px',
  marginTop: '80px',
  textAlign: 'center'
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default OrderPage;