import React, { useState, useEffect } from 'react';
import { db } from '../../App';
import firebase from 'firebase/app';import 'firebase/auth';
import { Brand } from './Brand';

interface Brands{
    brands?:Brand[];
}

export function useBrandInit():Brands {
  const [brands, setBrands] = useState<Brands>({brands: undefined}); // Initial empty array of tests 
  useEffect(() => {
    var uid:any = "";
    if(firebase.auth().currentUser){
        uid = firebase.auth().currentUser?.uid;
    }  
        const subscriber = db.collection("brand").doc("brand")
        .get().then((querySnapshot) => {
            var data:Brand[] = [];
            if(querySnapshot.exists){
                var obj = querySnapshot.data();
                if(obj && obj.brand){
                var brs:Brand[] = obj.brand;    
                var sorted = brs.slice(0);
                    sorted.sort(function(a,b) {
                        var x = a.name;
                        var y = b.name;
                        if(x && y){
                            return x < y ? -1 : x > y ? 1 : 0;
                        }else{
                            return 0;
                        }
                    });
                data = sorted;
                }else{
                    data = [];
                }
            }
            var us:Brands = {brands: data};
            setBrands({brands: []});
            setTimeout(function () {
                setBrands(us);
            }, 200);
        });

        // Unsubscribe from events when no longer in use
        return;
  }, []);

  return brands;
}
