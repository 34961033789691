import { IonRouterLink, IonSlide, IonSlides, NavContext } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import { GetCategorySlideOptions, GetScreen } from '../../services/SlideOptions';


const CategorySlideComponent = () => {
    const categoryData = useSelector((state:any) => 
        state.firestore.data.categories && state.firestore.data.categories.category && state.firestore.data.categories.category.category ? state.firestore.data.categories.category.category : []
    );
    const { size, orientation, width, height } = UseDeviceScreenSize();
    useFirestoreConnect(
        [
          {
              collection: 'category',
              storeAs: "categories",
          },
        ]
      );
    return (
        <div>
            {
                categoryData && Object.values(categoryData).length>0 ? 
                <IonSlides pager={true} options={GetCategorySlideOptions()}>
                {
                Object.values(categoryData).map((item:any) =>{
                        return (
                            <IonSlide key={"category"+item.name} style={{height: size === 'xl' ? '150px' : size === 'lg' ? '130px' : size === 'md' ? '110px' : size === 'sm' ? '90px' : '90px'}}>
                                {item.icon ? 
                                <IonRouterLink routerLink={"/store?parentCategory="+item.name}>
                                <img style={{borderRadius: '50%', height: size === 'xl' ? '120px' : size === 'lg' ? '100px' : size === 'md' ? '80px' : size === 'sm' ? '60px' : '60px',width: size === 'xl' ? '120px' : size === 'lg' ? '100px' : size === 'md' ? '80px' : size === 'sm' ? '60px' : '60px', objectFit: 'cover',boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}} src={item.icon}>
                                </img> 
                                <p style={{color: 'var(--ion-color-secondary)',  margin: '0px'}}>{item.name}</p>
                                </IonRouterLink>
                                : ''}
                            </IonSlide>
                        )
                    
                })
                }
            </IonSlides> : ''
            }
        </div>
    );
};

export default CategorySlideComponent;