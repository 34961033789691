import { IonContent, IonPage, NavContext } from '@ionic/react';
import { Grid, ListItemText, makeStyles, styled, Typography } from '@material-ui/core';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItemButton } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import CustomLoading from '../../components/loading/CustomLoading';
import SideMenuUser from '../../components/menu/SideMenuUser';
import { useCategoryInit } from './CategoryData';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const CategoryListPage: React.FC = () => {
  const classes = useStyles();
  const categoryData = useSelector((state:any) => 
      state.firestore.data.categories && state.firestore.data.categories.category && state.firestore.data.categories.category.category ? state.firestore.data.categories.category.category : []
  );
  useFirestoreConnect(
      [
        {
            collection: 'category',
            storeAs: "categories",
        },
      ]
    );

  const [expanded, setExpanded] = React.useState<string | false>(false);

  function handleChange(panel: string){
      setExpanded(panel && panel!=expanded ? panel : false);
    };

  const [subExpanded, setSubExpanded] = React.useState<string | false>(false);

  function subHandleChange(panel: string){
      setSubExpanded(panel && panel!=subExpanded ? panel : false);
    };  
     
  // const { navigate } = useContext(NavContext);
  return (
    <IonPage style={{display: 'block'}}>
         <SideMenuUser title="Categories" /> 
         {categoryData && Object.values(categoryData).length > 0 ? <IonContent>
            <Grid container justify="space-evenly" style={{overflow: 'auto', marginTop: '60px'}}>
              {Object.values(categoryData).map((item:any)=>{
                  return (
                  <Grid item lg={12} md={12} sm={12} xs={12} key={"top"+item.name}>   
                    <Accordion style={{ color: 'var(--ion-color-primary-text'}} expanded={expanded === 'panel'+(item.name).replaceAll(/\s/g,'')} onChange={()=>{handleChange('panel'+(item.name).replaceAll(/\s/g,''))}}>
                      <AccordionSummary style={{width: '100%' , height: '120px', backgroundSize: 'cover', backgroundImage: 'url('+item.image+')',}} aria-controls={'panel'+(item.name).replaceAll(/\s/g,'')+'d-content'} id={'panel'+(item.name).replaceAll(/\s/g,'')+'d-header'} expandIcon={<ExpandMoreIcon />}>
                        <Typography style={{fontSize: '22px', margin: '10px'}}>{item.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      {expanded ? <Grid container justify="space-evenly">
                        {item.subCategories && item.subCategories.length>0 ? 
                          item.subCategories.map((sub:any)=>{
                            if(sub.sub2Categories && sub.sub2Categories.length>0){
                              return (
                              <Grid item lg={12} md={12} sm={12} xs={12} key={"mid"+(item.name).replaceAll(/\s/g,'')+(sub.name).replaceAll(/\s/g,'')}>     
                                <Accordion style={{ width: '100%', backgroundColor: 'var(--ion-color-primary-background)', color: 'var(--ion-color-primary-text', boxShadow: 'none !important'}} key={"subCat"+(item.name).replaceAll(/\s/g,'')+(sub.name).replaceAll(/\s/g,'')} expanded={subExpanded === 'panelSub'+(sub.name).replaceAll(/\s/g,'')} onChange={()=>{subHandleChange('panelSub'+(sub.name).replaceAll(/\s/g,''))}}>
                                  <AccordionSummary  aria-controls={'panelSub'+(sub.name).replaceAll(/\s/g,'')+'d-content'} id={'panelSub'+(sub.name).replaceAll(/\s/g,'')+'d-header'}  expandIcon={<ExpandMoreIcon />}>
                                    <Typography style={{fontSize: '20px'}}>{sub.name}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                  <Grid container justify="space-evenly">  
                                    {sub.sub2Categories && sub.sub2Categories.length>0 ? 
                                      sub.sub2Categories.map((main:any)=>{
                                        return (
                                          <Grid item lg={12} md={12} sm={12} xs={12} key={"mainCat"+(item.name).replaceAll(/\s/g,'')+(sub.name).replaceAll(/\s/g,'')+(main.name).replaceAll(/\s/g,'')}>     
                                          <ListItemButton>
                                          {/* <ListItemText primary={main.name} /> */}
                                          <Link className={classes.mainCategory} to={"/store?mainCategory="+encodeURI(main.name).replace("&", "11nd")}>{main.name}</Link>
                                        </ListItemButton>
                                        </Grid>
                                        )
                                      })
                                    : ''}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                                </Grid>
                              )
                            }else{
                              return (
                                  <Grid item lg={12} md={12} sm={12} xs={12} key={"other"+(item.name).replaceAll(/\s/g,'')+(sub.name).replaceAll(/\s/g,'')}>       
                                <ListItemButton>
                                  {/* <ListItemText routerLink={"/store?mainCategory="+encodeURI(sub.name).replace("&", "11nd")} primary={sub.name} classes={{primary:classes.subcategory}} /> */}
                                  <Link className={classes.subcategory} to={"/store?mainCategory="+encodeURI(sub.name).replace("&", "11nd")}>{sub.name}</Link>
                                </ListItemButton>
                                </Grid>
                              )
                            }
                          })
                          : ''
                        }
                        </Grid> : ''}
                      </AccordionDetails>
                    </Accordion>
                    </Grid>
                  )
                }) }
            </Grid>  
            </IonContent> : <CustomLoading />}
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subcategory: {
    fontSize: '20px',
    color: 'black',
    textDecoration: 'blink',
  },
  mainCategory: {
    fontSize: '16px',
    color: 'black',
    textDecoration: 'blink',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default CategoryListPage;