import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';
import settings from '../../config/settings';
import FirestoreService from '../../services/FirestoreService';
import ShareIcon from '@mui/icons-material/Share';
import GlobalService from '../../services/GlobalService';
import { CustomDialog, useDialog } from 'react-st-modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IonBadge, IonCard } from '@ionic/react';
import { Color } from '../color/Color';
import { Size } from '../size/Size';
import BrandComponent from '../../components/common/BrandComponent';
import { UseAuthInit } from '../../hooks/firebase/Auth';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%',
    },
    ionCard:{
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      margin: '5px',
      width: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: red[500],
    },
    button:{
      backgroundColor: 'var(--ion-color-primary)',
    },
    large: {
      width: 'auto',
      height: '200px',
      maxHeight: '250px',
      maxWidth: '300px',
      objectFit: 'scale-down',
      padding: '10px',
    },
    box: {
      
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    share: {
       position: 'absolute', 
       bottom: '20px', 
       right: '20px',
    }
  }),
);

export default function StoreProductCard({product, viewClick, brands}:any) {
  const classes = useStyles();
  const { auth } = UseAuthInit();
  var brand:any = brands.find((e) => e.name === product.brand)
  return (
    <div className={classes.root}>
      <IonCard routerLink={"/storeProduct?key="+product.key+"&id="+product.user?.id}>
        <CardMedia
          component="img"
          height="140"
          image={product.thumbnail ? product.thumbnail : product.url ? product.url : settings.imageUploadPlaceholder} 
          alt={product.name}
        />
        <CardContent>
        <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: "50px" }} variant="h5">
            {brand && brand.name ? <BrandComponent titleSize="21px" subTitleSize="16px" logoSize="50px" height="50px" show={true} size1={4} size2={8} key={'brand'+product.key} brand={brand} /> : 
            <b style={{color: 'var(--ion-color-secondary)'}}>{product.brand}</b>}
          </Typography>
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} gutterBottom variant="h6" component="div">
              {product.name} {product.code ? ' | '+product.code : ''}
          </Typography>
          <Typography  style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--ion-color-secondary)' }} variant="subtitle1">
             <b> {product.category1} {product.category2 ? ' -> '+product.category2 : ''} {product.category3 ? ' -> '+product.category3 : ''} </b>
          </Typography>
          {auth?.user?.role === 'Customer' ?<Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} variant="subtitle1" color="danger">
            MRP: &#8377; {product.mrp} | WSP: &#8377; {product.wsp}
          </Typography> : '' }
          {auth?.user?.role === 'Customer' ?<Typography variant="subtitle1" color="text.secondary" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}  >
            &#8205;{product.description ? product.description : ''}
          </Typography> : ''}
          <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginTop: '10px', minHeight: '20px', }}  variant="body2" color="text.secondary">
            {product.sizes && product.sizes.length > 0 ? product.sizes.map((item:Size,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item.name}>{item.name} | {item.quantity} Pcs.</IonBadge>
              )
            }): ''}
            
            {product.patterns && product.patterns.length > 0 ? product.patterns.map((item:string,index:number)=>{
              return (
                <IonBadge color="secondary" style={{marginRight: '5px'}} key={item}>{item}</IonBadge>
              )
            }): ''}
          </Typography>
        </CardContent>
        <CardActions>
        </CardActions>
      </IonCard>
    </div>
  );
}