import { Container, Grid, makeStyles } from '@material-ui/core';
import { IonBadge, IonPage, NavContext } from '@ionic/react';
import { useContext } from 'react';
import ProductForm from './ProductForm';
import SideMenuUser from '../../components/menu/SideMenuUser';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';

const ProductPage: React.FC = () => {
  const classes = useStyles();
  const { navigate } = useContext(NavContext);
  let key = new URLSearchParams(window.location.search).get("key");
  const { size, orientation, width, height } = UseDeviceScreenSize();
  return (
    <IonPage>
         <SideMenuUser title={key? "Update Product": "Add Product"} backButton={true} /> 
         <ProductForm />
    </IonPage>
) ;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: '60px', 
    marginTop: '60px', 
    padding: '10px', 
    position: 'relative',
    height: '-webkit-fill-available', 
    overflow: 'auto', 
    width: '-webkit-fill-available',
},
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
}));

export default ProductPage;