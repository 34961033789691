import { IonRouterLink, IonSlide, IonSlides, NavContext } from '@ionic/react';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import UseDeviceScreenSize from '../../hooks/useDeviceScreenSize';
import GlobalService from '../../services/GlobalService';
import { GetBannerSlideOptions } from '../../services/SlideOptions';


const BannerSlideComponent = () => {
    const bannerData = useSelector((state:any) => 
        state.firestore.data.banners && state.firestore.data.banners.banner && state.firestore.data.banners.banner.banner ? state.firestore.data.banners.banner.banner : []
    );
    const { size, orientation, width, height } = UseDeviceScreenSize();
    useFirestoreConnect(
        [
          {
              collection: 'banner',
              storeAs: "banners",
          },
        ]
      );
    return (
        <div>
            {
                bannerData && Object.values(bannerData).length>0 ? 
                <IonSlides pager={true} options={GetBannerSlideOptions()}>
                    {
                    Object.values(bannerData).map((item:any) =>{
                        if(item){
                            return (
                                <IonSlide key={"banner"+item.id}> 
                                <IonRouterLink routerLink={item.brand ? "/store?brand="+item.name : item.productKey ? "/storeProduct?key="+item.productKey+"&id="+item.userKey : item.navigate ? item.navigate : item.href ? GlobalService.openlink(item.href) : '#'}>
                                    <img style={{height: size === 'xl' ? '400px' : size === 'lg' ? '300px' : size === 'md' ? '250px' : size === 'sm' ? '200px' : '120', width: '100%', objectFit: 'cover'}} src={"https://firebasestorage.googleapis.com/v0/b/eco-app-3ee66.appspot.com/o/banner%2F"+item.fileName+item.format+"?alt=media"}></img>
                                </IonRouterLink>
                                </IonSlide>
                            
                            )
                        }
                    })
                    }
                </IonSlides> : ''
            }
        </div>
    );
};

export default BannerSlideComponent;